import { getTypes, getType, OBJECT_INVALID } from './crudActions'

export function getInitialState (model) {
  const INVALID = getType(OBJECT_INVALID, model)
  return {
    readyState: INVALID,
    list: {
      objects: [],
      pageSize: 50,
      currentPage: 1,
      count: 0
    },
    object: {},
    error: false
  }
}

export function getActionHandler (model) {
  const TYPES = getTypes(model)
  return {
    [TYPES.OBJECT_INVALID]: (state, action) => {
      return { ...state, ...getInitialState(model) }
    },
    [TYPES.OBJECT_LIST_PENDING]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_LIST_PENDING }
    },
    [TYPES.OBJECT_LIST_REJECTED] : (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_LIST_REJECTED, error: action.error, status: action.payload.response.status, message: action.payload.response.data }
    },
    [TYPES.OBJECT_LIST_FULFILLED] : (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_LIST_FULFILLED, error: false, list: action.payload.data }
    },

    [TYPES.OBJECT_SAVE_PENDING]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_SAVE_PENDING }
    },
    [TYPES.OBJECT_SAVE_REJECTED]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_SAVE_REJECTED, error: action.error, status: action.payload.response.status, message: action.payload.response.data }
    },
    [TYPES.OBJECT_SAVE_FULFILLED]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_SAVE_FULFILLED, error: false, object: action.payload.data }
    },

    [TYPES.OBJECT_FETCH_PENDING]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_FETCH_PENDING }
    },
    [TYPES.OBJECT_FETCH_REJECTED]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_FETCH_REJECTED, error: action.error, status: action.payload.response.status, message: action.payload.response.data }
    },
    [TYPES.OBJECT_FETCH_FULFILLED]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_FETCH_FULFILLED, error: false, object: action.payload.data }
    },

    [TYPES.OBJECT_TOGGLE_STATUS_PENDING]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_TOGGLE_STATUS_PENDING }
    },
    [TYPES.OBJECT_TOGGLE_STATUS_REJECTED]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_TOGGLE_STATUS_REJECTED, error: action.error, status: action.payload.response.status, message: action.payload.response.data }
    },
    [TYPES.OBJECT_TOGGLE_STATUS_FULFILLED]: (state, action) => {
      const list = { ...state.list }
      const object = action.payload.data
      if (action.meta.updateList) {
        const index = list.objects.findIndex(o => o.id === object.id)
        list.objects[index] = object
      }
      return { ...state, readyState: TYPES.OBJECT_TOGGLE_STATUS_FULFILLED, error: false, list, object }
    },

    [TYPES.OBJECT_DELETE_PENDING]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_DELETE_PENDING }
    },
    [TYPES.OBJECT_DELETE_REJECTED]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_DELETE_REJECTED, error: action.error, status: action.payload.response.status, message: action.payload.response.data }
    },
    [TYPES.OBJECT_DELETE_FULFILLED]: (state, action) => {
      return { ...state, readyState: TYPES.OBJECT_DELETE_FULFILLED, error: false, object: action.payload.data }
    }
  }
}
