import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import {
  OBJECT_EXCLUDE_ACCOUNT_PENDING,
  OBJECT_EXCLUDE_ACCOUNT_REJECTED,
  OBJECT_EXCLUDE_ACCOUNT_FULFILLED,
  OBJECT_REPLACE_ACCOUNT_PENDING,
  OBJECT_REPLACE_ACCOUNT_REJECTED,
  OBJECT_REPLACE_ACCOUNT_FULFILLED
} from '../actions/proposalVersions'

const initialState = getInitialState('PROPOSAL_VERSIONS')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('PROPOSAL_VERSIONS'),
  [OBJECT_EXCLUDE_ACCOUNT_PENDING]: (state, action) => {
    return { ...state, readyState: OBJECT_EXCLUDE_ACCOUNT_PENDING }
  },
  [OBJECT_EXCLUDE_ACCOUNT_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: OBJECT_EXCLUDE_ACCOUNT_REJECTED, error: action.error, message }
  },
  [OBJECT_EXCLUDE_ACCOUNT_FULFILLED]: (state, action) => {
    return { ...state, readyState: OBJECT_EXCLUDE_ACCOUNT_FULFILLED, object: action.payload.data }
  },
  [OBJECT_REPLACE_ACCOUNT_PENDING]: (state, action) => {
    return { ...state, readyState: OBJECT_REPLACE_ACCOUNT_PENDING }
  },
  [OBJECT_REPLACE_ACCOUNT_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: OBJECT_REPLACE_ACCOUNT_REJECTED, error: action.error, message }
  },
  [OBJECT_REPLACE_ACCOUNT_FULFILLED]: (state, action) => {
    return { ...state, readyState: OBJECT_REPLACE_ACCOUNT_FULFILLED, object: action.payload.data }
  }
}

export default function proposalVersions (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
