import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import { SECURITIES_AFTER_SAVE, SECURITIES_REMOVE_AFTER_SAVE } from '../actions/securityRestrictions'

const initialState = getInitialState('SECURITY_RESTRICTIONS')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('SECURITY_RESTRICTIONS'),
  [SECURITIES_AFTER_SAVE]: (state, action) => {
    return { ...state, afterSave: action.callback }
  },
  [SECURITIES_REMOVE_AFTER_SAVE]: (state, action) => {
    return { ...state, afterSave: null }
  }
}

export default function securityRestrictions (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
