import { DISPLAY_ALERT, HIDE_ALERT } from '../actions/layout'

const initialState = {
  alert: {}
}

const ACTION_HANDLERS = {
  [DISPLAY_ALERT]: (state, action) => {
    return { ...state, alert: { ...action.payload } }
  },
  [HIDE_ALERT]: (state, action) => {
    return { ...state, alert: {} }
  }
}

export default function layout (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
