import React from 'react'
import PropTypes from 'prop-types'

const Popover = ({ content, icon, placement, width, trigger }) => (
  <a href="# " data-target="webuiPopover" data-content={content}
    data-placement={placement} data-width={width} data-trigger={trigger}>
    <i className={`fa fa-${icon}`} aria-hidden="true" /> </a>
)

Popover.propTypes = {
  content: PropTypes.string.isRequired,
  icon: PropTypes.string,
  placement: PropTypes.string,
  width: PropTypes.string,
  trigger: PropTypes.string
}

Popover.defaultProps = {
  icon: 'info-circle',
  placement: 'auto',
  width: 'auto',
  trigger: 'click'
}

export default Popover
