import config from '../config'
import HttpClient, { CancelToken, isCancel } from '../helpers/httpclient'
import browserHistory from '../helpers/browserHistory'
import { cancelFetchRequests, getBody, getParameterByName } from '../helpers/util'

export const CLIENTS_INVALID = 'CLIENTS_INVALID'
export const CLIENTS_FETCHING = 'CLIENTS_FETCHING'
export const CLIENTS_FETCHED = 'CLIENTS_FETCHED'
export const CLIENTS_FETCH_FAILED = 'CLIENTS_FETCH_FAILED'

export const CLIENT_SAVING = 'CLIENT_SAVING'
export const SAVE_CLIENT_SUCCESS = 'SAVE_CLIENT_SUCCESS'
export const SAVE_CLIENT_FAILED = 'SAVE_CLIENT_FAILED'

export const CLIENT_INVALID = 'CLIENT_INVALID'
export const CLIENT_FETCHING = 'CLIENT_FETCHING'
export const CLIENT_FETCHED = 'CLIENT_FETCHED'
export const CLIENT_FETCH_FAILED = 'CLIENT_FETCH_FAILED'

export const CLIENT_TOGGLING_STATUS = 'CLIENT_TOGGLING_STATUS'
export const CLIENT_TOGGLE_STATUS_SUCCESS = 'CLIENT_TOGGLE_STATUS_SUCCESS'
export const CLIENT_TOGGLE_STATUS_FAILED = 'CLIENT_TOGGLE_STATUS_FAILED'

const CLIENT_DELETE = 'CLIENT_DELETE'
export const CLIENT_DELETE_PENDING = 'CLIENT_DELETE_PENDING'
export const CLIENT_DELETE_REJECTED = 'CLIENT_DELETE_REJECTED'
export const CLIENT_DELETE_FULFILLED = 'CLIENT_DELETE_FULFILLED'

const ADD_ACCOUNT_HOLDER = 'ADD_ACCOUNT_HOLDER'
export const ADD_ACCOUNT_HOLDER_PENDING = 'ADD_ACCOUNT_HOLDER_PENDING'
export const ADD_ACCOUNT_HOLDER_REJECTED = 'ADD_ACCOUNT_HOLDER_REJECTED'
export const ADD_ACCOUNT_HOLDER_FULFILLED = 'ADD_ACCOUNT_HOLDER_FULFILLED'

export const MERGE_CLIENT = 'MERGE_CLIENT'
export const MERGE_CLIENT_PENDING = 'MERGE_CLIENT_PENDING'
export const MERGE_CLIENT_REJECTED = 'MERGE_CLIENT_REJECTED'
export const MERGE_CLIENT_FULFILLED = 'MERGE_CLIENT_FULFILLED'

export const CREATE_PORTAL = 'CREATE_PORTAL'
export const CREATE_PORTAL_PENDING = 'CREATE_PORTAL_PENDING'
export const CREATE_PORTAL_REJECTED = 'CREATE_PORTAL_REJECTED'
export const CREATE_PORTAL_FULFILLED = 'CREATE_PORTAL_FULFILLED'

export const DEACTIVATE_PORTAL = 'DEACTIVATE_PORTAL'
export const DEACTIVATE_PORTAL_PENDING = 'DEACTIVATE_PORTAL_PENDING'
export const DEACTIVATE_PORTAL_REJECTED = 'DEACTIVATE_PORTAL_REJECTED'
export const DEACTIVATE_PORTAL_FULFILLED = 'DEACTIVATE_PORTAL_FULFILLED'

export const ACTIVATE_PORTAL = 'ACTIVATE_PORTAL'
export const ACTIVATE_PORTAL_PENDING = 'ACTIVATE_PORTAL_PENDING'
export const ACTIVATE_PORTAL_REJECTED = 'ACTIVATE_PORTAL_REJECTED'
export const ACTIVATE_PORTAL_FULFILLED = 'ACTIVATE_PORTAL_FULFILLED'

export const RESET_PORTAL_PASSWORD = 'RESET_PORTAL_PASSWORD'
export const RESET_PORTAL_PASSWORD_PENDING = 'CRESET_PORTAL_PASSWORD_PENDING'
export const RESET_PORTAL_PASSWORD_REJECTED = 'RESET_PORTAL_PASSWORD_REJECTED'
export const RESET_PORTAL_PASSWORD_FULFILLED = 'RESET_PORTAL_PASSWORD_FULFILLED'

export const UPDATE_PORTAL = 'UPDATE_PORTAL'
export const UPDATE_PORTAL_PENDING = 'UPDATE_PORTAL_PENDING'
export const UPDATE_PORTAL_REJECTED = 'UPDATE_PORTAL_REJECTED'
export const UPDATE_PORTAL_FULFILLED = 'UPDATE_PORTAL_FULFILLED'

export function saveClient (client) {
  return (dispatch) => {
    dispatch({ type: CLIENT_SAVING, client: client })

    return HttpClient.post(`${config.api}/client`, client)
    .then((response) => {
      return response.data
    })
    .then(
      result => {
        dispatch({ type: SAVE_CLIENT_SUCCESS, result })
        browserHistory.push('/clients')
      },
      error => dispatch({ type: SAVE_CLIENT_FAILED, error })
    )
  }
}

export function createClient (client, redirect = true) {
  return (dispatch) => {
    dispatch({ type: CLIENT_SAVING, client: client })

    return HttpClient.post(`${config.api}/create-client`, client)
    .then((response) => {
      if (getParameterByName('return') === 'new-tamarac-account') {
        browserHistory.push(`/account/${getParameterByName('object')}/new-account?client=${response.data.id}`)
      } else if (redirect) {
        browserHistory.push(`/client/${response.data.id}/edit`)
      }
      return response.data
    })
    .then(
      result => dispatch({ type: SAVE_CLIENT_SUCCESS, result }),
      error => dispatch({ type: SAVE_CLIENT_FAILED, error })
    )
  }
}

export function addAccountHolder (client) {
  return {
    type: ADD_ACCOUNT_HOLDER,
    payload: HttpClient.post(`${config.api}/create-client`, client)
  }
}

export function updateClient (id, client, redirect = true) {
  return (dispatch) => {
    dispatch({ type: CLIENT_SAVING, client: client })

    return HttpClient.put(`${config.api}/client/${id}`, getBody(client, [ 'financial_company_approval_letter' ]))
    .then((response) => {
      return response.data
    })
    .then(
      result => {
        dispatch({ type: SAVE_CLIENT_SUCCESS, result })
        redirect && browserHistory.push('/clients')
      },
      error => dispatch({ type: SAVE_CLIENT_FAILED, error })
    )
  }
}

export function asyncUpdate (values) {
  return HttpClient.put(`${config.api}/client/${values.id}`, values).then(r => r.data)
}

export function deleteClient (id) {
  return {
    type: CLIENT_DELETE,
    payload: HttpClient.delete(`${config.api}/client/${id}`)
  }
}

export function toggleClientActive (id) {
  return (dispatch) => {
    dispatch({ type: CLIENT_TOGGLING_STATUS })
    return HttpClient.put(`${config.api}/client/${id}/toggle-active`)
    .then((response) => {
      return response.data
    })
    .then(
      result => dispatch({ type: CLIENT_TOGGLE_STATUS_SUCCESS, result }),
      error => dispatch({ type: CLIENT_TOGGLE_STATUS_FAILED, error })
    )
  }
}

export function fetchClient (id) {
  return (dispatch) => {
    dispatch({ type: CLIENT_FETCHING })

    return HttpClient.get(`${config.api}/client/${id}`)
      .then((response) => {
        return response.data
      })
      .then(
        result => dispatch({ type: CLIENT_FETCHED, result }),
        error => dispatch({ type: CLIENT_FETCH_FAILED, error })
      )
  }
}

let cancel = []

export function fetchClients (page = 1, term = undefined, sort = undefined, status = 'active', household = null, serviceTeam = null, clientPortalStatus = null) {
  return (dispatch) => {
    if (cancel.length !== 0) {
      cancelFetchRequests(cancel)
    }
    dispatch({ type: CLIENTS_FETCHING })

    const params = {
      cancelToken: new CancelToken(function executor(c) {
        cancel.push(c)
      }),
      params: {
        page,
        household,
        status,
        serviceTeam,
        clientPortalStatus
      }
    }

    if (term) {
      params.params.q = term
    }

    if (sort) {
      params.params.order = sort.order
      params.params.direction = sort.direction
    }

    return HttpClient.get(`${config.api}/clients`, params)
      .then((response) => {
        return response.data
      })
      .then(
        result => {
          cancel = []
          dispatch({ type: CLIENTS_FETCHED, result })
        },
        error => {
          cancel = cancel.filter(c => c !== null)
          if (!isCancel(error)) {
            dispatch({ type: CLIENTS_FETCH_FAILED, error })
          }
        }
      )
  }
}

export function fetchClientOptions (household = null, excludeId = null, includeId = null) {
  return input => {
    const params = { params: { q: input, exclude: excludeId, include: includeId, household } }
    return HttpClient.get(`${config.api}/clients/all`, params).then(r => r.data)
  }
}

export function fetchOptions (household, exclude) {
  const getOption = c => ({ value: c.id, label: `${c.first_name} ${c.last_name}` })
  const getOptions = cs => ({ options: cs.clients.map(getOption) })
  return input => {
    const params = { params: { household, q: input } }
    if (exclude) {
      params.params.exclude = exclude
    }
    return HttpClient.get(`${config.api}/clients/options`, params).then(r => r.data).then(getOptions)
  }
}

export function mergeClients (client, newClient) {
  return {
    type: MERGE_CLIENT,
    payload: HttpClient.post(`${config.api}/client/${client.id}/merge`, { newClient })
  }
}

export function createPortal (clientId) {
  return {
    type: CREATE_PORTAL,
    payload: HttpClient.post(`${config.api}/client/${clientId}/create-portal`, { clientId })
  }
}

export function deactivatePortal (clientId) {
  return {
    type: DEACTIVATE_PORTAL,
    payload: HttpClient.post(`${config.api}/client/${clientId}/deactivate-portal`, { clientId })
  }
}

export function activatePortal (clientId) {
  return {
    type: ACTIVATE_PORTAL,
    payload: HttpClient.post(`${config.api}/client/${clientId}/activate-portal`, { clientId })
  }
}

export function resetPortalPassword (clientId) {
  return {
    type: RESET_PORTAL_PASSWORD,
    payload: HttpClient.post(`${config.api}/client/${clientId}/reset-portal-password`, { clientId })
  }
}

export function clientPortalUpdate (clientId, clientEmail) {
  return {
    type: UPDATE_PORTAL,
    payload: HttpClient.post(`${config.api}/client/${clientId}/update-portal`, { clientId, clientEmail })
  }
}