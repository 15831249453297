import {
  GRID_INVALID,
  GRID_PAGE,
  GRID_SEARCH,
  GRID_FILTER,
  GRID_SORT,
  GRID_STATUS,
  GRID_INITITAL
} from '../actions/grid'

const CRUD_ACTION_HANDLERS = {
  [GRID_PAGE]: (state, action) => {
    const grid = state[action.grid] || {}
    return { ...state, [action.grid]: { ...grid, readyState: GRID_PAGE, page: action.page } }
  },
  [GRID_SEARCH]: (state, action) => {
    const grid = state[action.grid] || {}
    return { ...state, [action.grid]: { ...grid, readyState: GRID_SEARCH, search: action.search, page: action.page } }
  },
  [GRID_FILTER]: (state, action) => {
    const grid = state[action.grid] || {}
    return { ...state, [action.grid]: { ...grid, readyState: GRID_SEARCH, filter: action.filter, page: action.page } }
  },
  [GRID_SORT] : (state, action) => {
    const grid = state[action.grid] || {}
    return { ...state, [action.grid]: { ...grid, readyState: GRID_SORT, sort: action.sort } }
  },
  [GRID_STATUS] : (state, action) => {
    const grid = state[action.grid] || {}
    return { ...state, [action.grid]: { ...grid, readyState: GRID_STATUS, status: action.status, page: 1 } }
  },
  [GRID_INVALID] : (state, action) => {
    return { ...state, [action.grid]: { readyState: GRID_INVALID, page: 1, search: null, sort: {}, status: 'active' } }
  },
  [GRID_INITITAL] : (state, action) => {
    return {
      ...state,
      [action.grid]: {
        readyState: GRID_INITITAL,
        page: action.page,
        search: action.search,
        filter: action.filter,
        sort: action.sort,
        status: action.status
      }
    }
  }
}

export default function grid (state = {}, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
