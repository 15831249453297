import { lazy } from 'react'
import Authorize from '../components/auth/Authorize'

const NoMatch = lazy(() => import('../containers/NoMatch'))
const Dashboard = lazy(() => import('../containers/dashboard/Dashboard'))

const Roles = lazy(() => import('../containers/role/Roles'))
const Role = lazy(() => import('../containers/role/Role'))
const NewRole = lazy(() => import('../containers/role/NewRole'))
const EditRole = lazy(() => import('../containers/role/EditRole'))

const Users = lazy(() => import('../containers/user/Users'))
const Profile = lazy(() => import('../containers/user/Profile'))
const NewUser = lazy(() => import('../containers/user/NewUser'))
const EditUser = lazy(() => import('../containers/user/EditUser'))
const Activation = lazy(() => import('../containers/user/Activation'))
const Impersonating = lazy(() => import('../containers/user/Impersonating'))

const Monitors = lazy(() => import('../containers/monitor/Monitors'))

const Companies = lazy(() => import('../containers/company/Companies'))
const NewCompany = lazy(() => import('../containers/company/NewCompany'))
const Company = lazy(() => import('../containers/company/Company'))
const EditCompany = lazy(() => import('../containers/company/EditCompany'))

const Households = lazy(() => import('../containers/household/Households'))
const NewHousehold = lazy(() => import('../containers/household/NewHousehold'))
const EditHousehold = lazy(() => import('../containers/household/EditHousehold'))

const ImplementationSettings = lazy(() => import('../containers/implementation/ImplementationSettings'))
const ImplementationSettingsExternal = lazy(() => import('../containers/implementation/ImplementationSettingsExternal'))

const Accounts = lazy(() => import('../containers/account/Accounts'))
const NewAccount = lazy(() => import('../containers/account/NewAccount'))
const NewManualAccount = lazy(() => import('../containers/account/NewManualAccount'))
const EditAccount = lazy(() => import('../containers/account/EditAccount'))
const NewAccounts = lazy(() => import('../containers/account/NewAccounts'))
const NewTamaracAccount = lazy(() => import('../containers/account/NewTamaracAccount'))

const SignIn = lazy(() => import('../containers/auth/SignIn'))
const SignOut = lazy(() => import('../containers/auth/SignOut'))
const NotAuthorized = lazy(() => import('../containers/auth/NotAuthorized'))
const ChangePassword = lazy(() => import('../containers/auth/ChangePassword'))
const ResetPassword = lazy(() => import('../containers/auth/ResetPassword'))

const Implement = lazy(() => import('../containers/implement/Implement'))

const CriticalPathProposal = lazy(() => import('../containers/proposal/CriticalPathProposal'))
const Proposal = lazy(() => import('../containers/proposal/Proposal'))
const Proposals = lazy(() => import('../containers/proposal/Proposals'))
const ProposalRequest = lazy(() => import('../containers/proposal/ProposalRequest'))
const EditProposalRequest = lazy(() => import('../containers/proposal/EditProposalRequest'))

const CriticalPaths = lazy(() => import('../containers/criticalpath/CriticalPaths'))
const NewCriticalPath = lazy(() => import('../containers/criticalpath/NewCriticalPath'))
const EditCriticalPath = lazy(() => import('../containers/criticalpath/EditCriticalPath'))
const MonitoringCriticalPath = lazy(() => import('../containers/criticalpath/MonitoringCriticalPath'))

const Models = lazy(() => import('../containers/model/Models'))
const NewModel = lazy(() => import('../containers/model/NewModel'))
const EditModel = lazy(() => import('../containers/model/EditModel'))
const Model = lazy(() => import('../containers/model/Model'))

const OnBoardImplement = lazy(() => import('../containers/onboard-implement/OnBoardImplement'))
const Agreement = lazy(() => import('../containers/agreement/Agreement'))
const AgreementForm = lazy(() => import('../containers/agreement/AgreementForm'))
const CustodialForm = lazy(() => import('../containers/custodial-form/CustodialForm'))

const GeneralRequest = lazy(() => import('../containers/support/GeneralRequest'))
const ContactRequest = lazy(() => import('../containers/support/ContactRequest'))

const Monitor = lazy(() => import('../containers/monitor/Monitor'))

const ServiceTeams = lazy(() => import('../containers/service-team/ServiceTeams'))
const NewServiceTeam = lazy(() => import('../containers/service-team/NewServiceTeam'))
const EditServiceTeam = lazy(() => import('../containers/service-team/EditServiceTeam'))

const TermsConditions = lazy(() => import('../containers/page/TermsConditions'))
const AdvisorConsultingAgreement = lazy(() => import('../containers/page/AdvisorConsultingAgreement'))

const SecurityRestrictions = lazy(() => import('../containers/security-restriction/SecurityRestrictions'))

const BillingDefinitions = lazy(() => import('../containers/billing-definition/BillingDefinitions'))
const BillingGroups = lazy(() => import('../containers/billing-group/BillingGroups'))

const AutomatedInvesting = lazy(() => import('../containers/account-ar/AutomatedInvesting'))

const CashReserves = lazy(() => import('../containers/cash-reserve/CashReserves'))

const ZendeskAuth = lazy(() => import('../containers/zendesk/ZendeskAuth'))

const Clients = lazy(() => import('../containers/client/Clients'))
const NewClient = lazy(() => import('../containers/client/NewClient'))
const EditClient = lazy(() => import('../containers/client/EditClient'))

const CustodialRequests = lazy(() => import('../containers/custodial-request/CustodialRequests'))
const CustodialRequestNew = lazy(() => import('../containers/custodial-request/CustodialRequestNew'))
const CustodialRequest = lazy(() => import('../containers/custodial-request/CustodialRequest'))

const TradeRequests = lazy(() => import('../containers/trade-request/TradeRequests'))
const TradeRequest = lazy(() => import('../containers/trade-request/TradeRequest'))

const ProposalInventory = lazy(() => import('../containers/proposal-inventory/ProposalInventory'))

const CustodialForms = lazy(() => import('../containers/custodial-form/CustodialForms'))
const NewCustodialForm = lazy(() => import('../containers/custodial-form/NewCustodialForm'))
const EditCustodialForm = lazy(() => import('../containers/custodial-form/EditCustodialForm'))

const TamaracTransactions = lazy(() => import('../containers/tamarac-transaction/TamaracTransactions'))

const Watchers = lazy(() => import('../containers/watcher/Watchers'))
const NewWatcher = lazy(() => import('../containers/watcher/NewWatcher'))
const EditWatcher = lazy(() => import('../containers/watcher/EditWatcher'))

const NewWorker = lazy(() => import('../containers/worker/NewWorker'))

const Leads = lazy(() => import('../containers/lead/Leads'))

const IncomeBuilders = lazy(() => import('../containers/income-builder/IncomeBuilders'))
const EditIncomeBuilder = lazy(() => import('../containers/income-builder/EditIncomeBuilder'))

const Rebalancing = lazy(() => import('../containers/rebalancing/Rebalancing'))

const Reports = lazy(() => import('../containers/report/Reports'))
const NewReport = lazy(() => import('../containers/report/NewReport'))
const EditReport = lazy(() => import('../containers/report/EditReport'))
const UnifiedReports = lazy(() => import('../containers/report/UnifiedReports'))
const NewUnifiedReport = lazy(() => import('../containers/report/NewUnifiedReport'))
const EditUnifiedReport = lazy(() => import('../containers/report/EditUnifiedReport'))

const Banners = lazy(() => import('../containers/banner/Banners'))
const NewBanner = lazy(() => import('../containers/banner/NewBanner'))
const EditBanner = lazy(() => import('../containers/banner/EditBanner'))

const EducationalMaterials = lazy(() => import('../containers/educational-material/EducationalMaterials'))
const NewEducationMaterial = lazy(() => import('../containers/educational-material/NewEducationMaterial'))
const EditEducationMaterial = lazy(() => import('../containers/educational-material/EditEducationMaterial'))

const AccountTypes = lazy(() => import('../containers/account-type/AccountTypes'))

const ADVReports = lazy(() => import('../containers/adv-report/ADVReports'))
const BillingFeeFiles = lazy(() => import('../containers/billing-fee-file/BillingFeeFiles'))

export const unauthenticatedRoutes = [
  { exact: true, path: "/signin", component: SignIn },
  { exact: true, path: "/change-password/:id", component: ChangePassword },
  { exact: true, path: "/reset-password", component: ResetPassword },
  { exact: true, path: "/not-authorized", component: NotAuthorized },
  { exact: true, path: "/activate/:id", component: Activation },
  { exact: true, path: "/terms-and-conditions", component: TermsConditions },
  { exact: true, path: "/advisor-consulting-agreement", component: AdvisorConsultingAgreement },
  { exact: true, path: "/proposal/:id/view", component: Proposal },
  { exact: true, path: "/model/:id/view", component: Model },
  { exact: true, path: "/critical-path-monitoring/:id/view", component: MonitoringCriticalPath },
]

export const authenticatedRoutes = [
  { exact: true, path: "/", component: Dashboard },
  { exact: true, path: "/profile", component: Profile },
  { exact: true, path: "/user/:id/edit", component: EditUser },
  { exact: true, path: "/signout", component: SignOut },
  { exact: true, path: "/agreement", component: Agreement },
  { exact: true, path: "/agreement-form", component: AgreementForm },
  { exact: true, path: "/custodial-forms", component: CustodialForm },
  { exact: true, path: "/onboard-implement", component: OnBoardImplement },
  { exact: true, path: "/general-request", component: GeneralRequest },
  { exact: true, path: "/custodial-request", component: CustodialRequest },
  { exact: true, path: "/contact-us", component: ContactRequest },
  { exact: true, path: "/monitoring", component: Monitors },
  { exact: true, path: "/monitoring/:id/", component: Monitor },
  { exact: true, path: "/accounts", component: Authorize(Accounts, 'accounts', 'list') },
  { exact: true, path: "/companies", component: Authorize(Companies, 'companies', 'list') },
  { exact: true, path: "/households", component: Authorize(Households, 'households', 'list') },
  { exact: true, path: "/implement", component: Authorize(Implement, 'implement', 'list') },
  { exact: true, path: "/models", component: Authorize(Models, 'models', 'list') },
  { exact: true, path: "/proposals", component: Authorize(Proposals, 'proposals', 'list') },
  { exact: true, path: "/roles", component: Authorize(Roles, 'roles', 'list') },
  { exact: true, path: "/users", component: Authorize(Users, 'users', 'list') },
  { exact: true, path: "/service-teams", component: Authorize(ServiceTeams, 'service teams', 'list') },
  { exact: true, path: "/critical-paths", component: Authorize(CriticalPaths, 'critical-paths', 'list') },
  { exact: true, path: "/critical-path-proposal", component: Authorize(CriticalPathProposal, 'critical-paths', 'list') },
  { exact: true, path: "/security-restrictions", component: Authorize(SecurityRestrictions, 'households', 'list') },
  { exact: true, path: "/billing-definitions", component: Authorize(BillingDefinitions, 'billing-definitions', 'list') },
  { exact: true, path: "/billing-groups", component: Authorize(BillingGroups, 'billing-groups', 'list') },
  { exact: true, path: "/automated-investing", component: Authorize(AutomatedInvesting, 'automated-investing', 'list') },
  { exact: true, path: "/cash-reserves", component: Authorize(CashReserves, 'cash-reserves', 'list') },
  { exact: true, path: "/proposal/new", component: Authorize(ProposalRequest, 'proposals', 'new') },
  { exact: true, path: "/account/new-accounts", component: Authorize(NewAccounts, 'accounts', 'new tamarac accounts') },
  { exact: true, path: "/account/new/manual", component: Authorize(NewManualAccount, 'accounts', 'new') },
  { exact: true, path: "/account/new", component: Authorize(NewAccount, 'accounts', 'new') },
  { exact: true, path: "/company/new", component: Authorize(NewCompany, 'companies', 'new') },
  { exact: true, path: "/household/new", component: Authorize(NewHousehold, 'households', 'new') },
  { exact: true, path: "/model/new", component: Authorize(NewModel, 'models', 'new') },
  { exact: true, path: "/role/new", component: Authorize(NewRole, 'roles', 'new') },
  { exact: true, path: "/user/new", component: Authorize(NewUser, 'users', 'new') },
  { exact: true, path: "/service-teams/new", component: Authorize(NewServiceTeam, 'service teams', 'new') },
  { exact: true, path: "/critical-path/new", component: Authorize(NewCriticalPath, 'critical-paths', 'new') },
  { exact: true, path: "/account/:id/new-account", component: NewTamaracAccount },
  { exact: true, path: "/account/:id/edit", component: Authorize(EditAccount, 'accounts', 'edit') },
  { exact: true, path: "/company/:id/edit", component: Authorize(EditCompany, 'companies', 'edit') },
  { exact: true, path: "/household/:id/edit", component: Authorize(EditHousehold, 'households', 'edit') },
  { exact: true, path: "/proposal/:id/account-settings", component: ImplementationSettings },
  { exact: true, path: "/proposal/:id/account-settings-external", component: ImplementationSettingsExternal },
  { exact: true, path: "/model/:id/edit", component: Authorize(EditModel, 'models', 'edit') },
  { exact: true, path: "/role/:id/edit", component: Authorize(EditRole, 'roles', 'edit') },
  { exact: true, path: "/user/:id/edit", component: Authorize(EditUser, 'users', 'edit') },
  { exact: true, path: "/service-teams/:id/edit", component: Authorize(EditServiceTeam, 'service teams', 'edit') },
  { exact: true, path: "/proposal/:id/edit", component: Authorize(EditProposalRequest, 'proposals', 'edit') },
  { exact: true, path: "/critical-path/:id/edit", component: Authorize(EditCriticalPath, 'critical-paths', 'edit') },
  { exact: true, path: "/critical-path/:id/monitoring", component: Authorize(MonitoringCriticalPath, 'critical-paths', 'monitoring') },
  { exact: true, path: "/company/:id", component: Authorize(Company, 'companies', 'show') },
  { exact: true, path: "/role/:id", component: Authorize(Role, 'roles', 'show') },
  { exact: true, path: "/proposal/:id", component: Proposal },
  { exact: true, path: "/model/:id", component: Model },
  { exact: true, path: "/zendesk/auth", component: ZendeskAuth },
  { exact: true, path: "/clients", component: Clients },
  { exact: true, path: "/client/new", component: NewClient },
  { exact: true, path: "/client/:id/edit", component: EditClient },
  { exact: true, path: "/custodial-requests", component: Authorize(CustodialRequests, 'custodial requests', 'list') },
  { exact: true, path: "/custodial-request/new", component: Authorize(CustodialRequestNew, 'custodial requests', 'new') },
  { exact: true, path: "/custodial-request/:id/edit", component: Authorize(CustodialRequest, 'custodial requests', 'edit') },
  { exact: true, path: "/trading-requests", component: Authorize(TradeRequests, 'trading requests', 'list') },
  { exact: true, path: "/trade-request/:id", component: Authorize(TradeRequest, 'trading requests', 'view') },
  { exact: true, path: "/proposal-inventory", component: Authorize(ProposalInventory, 'proposal inventory', 'list') },
  { exact: true, path: "/form-manager", component: Authorize(CustodialForms, 'custodial forms', 'list') },
  { exact: true, path: "/form-manager/new", component: Authorize(NewCustodialForm, 'custodial forms', 'new') },
  { exact: true, path: "/form-manager/:id/edit", component: Authorize(EditCustodialForm, 'custodial forms', 'edit') },
  { exact: true, path: "/tamarac-uploads", component: Authorize(TamaracTransactions, 'tamarac uploads', 'list') },
  { exact: true, path: "/watchers", component: Authorize(Watchers, 'watchers', 'list') },
  { exact: true, path: "/watcher/new", component: Authorize(NewWatcher, 'watchers', 'new') },
  { exact: true, path: "/watcher/:id/edit", component: Authorize(EditWatcher, 'watchers', 'edit') },
  { exact: true, path: "/worker/new", component: Authorize(NewWorker, 'workers', 'new') },
  { exact: true, path: "/rebalancing", component: Rebalancing },
  { exact: true, path: "/impersonating", component: Impersonating },
  { exact: true, path: "/leads", component: Leads },
  { exact: true, path: "/income-builders", component: Authorize(IncomeBuilders, 'income builders', 'list') },
  { exact: true, path: "/income-builder/:id/edit", component: Authorize(EditIncomeBuilder, 'income builders', 'edit') },
  { exact: true, path: "/tamarac-imports/new", component: Authorize(NewReport, 'tamarac imports', 'new') },
  { exact: true, path: "/tamarac-imports/:id/edit", component: Authorize(EditReport, 'tamarac imports', 'edit') },
  { exact: true, path: "/tamarac-imports", component: Authorize(Reports, 'tamarac imports', 'list') },
  { exact: true, path: "/unified-reports/new", component: Authorize(NewUnifiedReport, 'tamarac imports', 'unified reports') },
  { exact: true, path: "/unified-reports/:id/edit", component: Authorize(EditUnifiedReport, 'tamarac imports', 'unified reports') },
  { exact: true, path: "/unified-reports", component: Authorize(UnifiedReports, 'tamarac imports', 'unified reports') },
  { exact: true, path: "/banners/new", component: Authorize(NewBanner, 'banners', 'new') },
  { exact: true, path: "/banners/:id/edit", component: Authorize(EditBanner, 'banners', 'edit') },
  { exact: true, path: "/banners", component: Authorize(Banners, 'banners', 'list') },
  { exact: true, path: "/educational-materials/new", component: Authorize(NewEducationMaterial, 'educational materials', 'new') },
  { exact: true, path: "/educational-materials/:id/edit", component: Authorize(EditEducationMaterial, 'educational materials', 'edit') },
  { exact: true, path: "/educational-materials", component: Authorize(EducationalMaterials, 'educational materials', 'list') },
  { exact: true, path: "/account-types", component: AccountTypes },
  { exact: true, path: "/adv-reports", component: ADVReports },
  { exact: true, path: "/billing-calculations", component: BillingFeeFiles },
  { component: NoMatch }
]
