import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

const Alert = ({ layout: { alert } }) => {
  return (
    <div id="Alert" data-backdrop="static" className="modal fade modal-fade-in-scale-up" aria-hidden="true" role="dialog">
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <h4 className="modal-title">{alert.title}</h4>
          </div>
          <div className="modal-body">
            {alert.body}
            <div className="form-group form-material margin-bottom-0">
              <button type="submit" onClick={close(alert.callback)}
                className="btn btn-round btn-info waves-effect waves-light">{alert.confirm || 'Ok'}</button>
              {' '}
              <button type="submit" onClick={close()}
                className="btn btn-round waves-effect waves-light">{alert.cancel || 'Cancel'}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Alert.propTypes = {
  layout: PropTypes.object
}

function mapStateToProps (state) {
  return {
    layout: state.layout
  }
}

export default connect(mapStateToProps)(Alert)

function close (callback) {
  return e => {
    callback && callback()
    window.$('#Alert').modal('hide')
  }
}
