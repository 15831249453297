import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import {
  ACCOUNTS_SAVE_PENDING,
  ACCOUNTS_SAVE_REJECTED,
  ACCOUNTS_SAVE_FULFILLED,
  ACCOUNTS_DELETE_PENDING,
  ACCOUNTS_DELETE_REJECTED,
  ACCOUNTS_DELETE_FULFILLED,
  TRANSFERS_SAVE_PENDING,
  TRANSFERS_SAVE_REJECTED,
  TRANSFERS_SAVE_FULFILLED,
  TRANSFERS_DELETE_PENDING,
  TRANSFERS_DELETE_REJECTED,
  TRANSFERS_DELETE_FULFILLED,
  CLIENTS_SAVE_PENDING,
  CLIENTS_SAVE_REJECTED,
  CLIENTS_SAVE_FULFILLED,
  BENEFICIARIES_SAVE_PENDING,
  BENEFICIARIES_SAVE_REJECTED,
  BENEFICIARIES_SAVE_FULFILLED,
  BENEFICIARIES_DELETE_PENDING,
  BENEFICIARIES_DELETE_REJECTED,
  BENEFICIARIES_DELETE_FULFILLED,
  STANDING_INSTRUCTIONS_SAVE_PENDING,
  STANDING_INSTRUCTIONS_SAVE_REJECTED,
  STANDING_INSTRUCTIONS_SAVE_FULFILLED,
  STANDING_INSTRUCTIONS_DELETE_PENDING,
  STANDING_INSTRUCTIONS_DELETE_REJECTED,
  STANDING_INSTRUCTIONS_DELETE_FULFILLED,
  UPDATE_CUSTODIAN_PENDING,
  UPDATE_CUSTODIAN_REJECTED,
  UPDATE_CUSTODIAN_FULFILLED,
  SUBMIT_TICKET_PENDING,
  SUBMIT_TICKET_REJECTED,
  SUBMIT_TICKET_FULFILLED
} from '../actions/custodialRequests'

const initialState = getInitialState('CUSTODIAL_REQUESTS')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('CUSTODIAL_REQUESTS'),
  [ACCOUNTS_SAVE_PENDING]: (state, action) => {
    return { ...state, readyState: ACCOUNTS_SAVE_PENDING }
  },
  [ACCOUNTS_SAVE_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: ACCOUNTS_SAVE_REJECTED, error: action.error, message }
  },
  [ACCOUNTS_SAVE_FULFILLED]: (state, action) => {
    const custodialRequest = action.payload.data
    return { ...state, readyState: ACCOUNTS_SAVE_FULFILLED, object: custodialRequest }
  },
  [ACCOUNTS_DELETE_PENDING]: (state, action) => {
    return { ...state, readyState: ACCOUNTS_DELETE_PENDING }
  },
  [ACCOUNTS_DELETE_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: ACCOUNTS_DELETE_REJECTED, error: action.error, message }
  },
  [ACCOUNTS_DELETE_FULFILLED]: (state, action) => {
    return { ...state, readyState: ACCOUNTS_DELETE_FULFILLED, object: action.payload.data }
  },
  [TRANSFERS_SAVE_PENDING]: (state, action) => {
    return { ...state, readyState: TRANSFERS_SAVE_PENDING }
  },
  [TRANSFERS_SAVE_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: TRANSFERS_SAVE_REJECTED, error: action.error, message }
  },
  [TRANSFERS_SAVE_FULFILLED]: (state, action) => {
    const custodialRequest = action.payload.data
    return { ...state, readyState: TRANSFERS_SAVE_FULFILLED, object: custodialRequest }
  },
  [TRANSFERS_DELETE_PENDING]: (state, action) => {
    return { ...state, readyState: TRANSFERS_DELETE_PENDING }
  },
  [TRANSFERS_DELETE_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: TRANSFERS_DELETE_REJECTED, error: action.error, message }
  },
  [TRANSFERS_DELETE_FULFILLED]: (state, action) => {
    return { ...state, readyState: TRANSFERS_DELETE_FULFILLED, object: action.payload.data }
  },
  [CLIENTS_SAVE_PENDING]: (state, action) => {
    return { ...state, readyState: CLIENTS_SAVE_PENDING }
  },
  [CLIENTS_SAVE_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: CLIENTS_SAVE_REJECTED, error: action.error, message }
  },
  [CLIENTS_SAVE_FULFILLED]: (state, action) => {
    return { ...state, readyState: CLIENTS_SAVE_FULFILLED, object: action.payload.data }
  },
  [BENEFICIARIES_SAVE_PENDING]: (state, action) => {
    return { ...state, readyState: BENEFICIARIES_SAVE_PENDING }
  },
  [BENEFICIARIES_SAVE_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: BENEFICIARIES_SAVE_REJECTED, error: action.error, message }
  },
  [BENEFICIARIES_SAVE_FULFILLED]: (state, action) => {
    return { ...state, readyState: BENEFICIARIES_SAVE_FULFILLED, object: action.payload.data }
  },
  [BENEFICIARIES_DELETE_PENDING]: (state, action) => {
    return { ...state, readyState: BENEFICIARIES_DELETE_PENDING }
  },
  [BENEFICIARIES_DELETE_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: BENEFICIARIES_DELETE_REJECTED, error: action.error, message }
  },
  [BENEFICIARIES_DELETE_FULFILLED]: (state, action) => {
    return { ...state, readyState: BENEFICIARIES_DELETE_FULFILLED, object: action.payload.data }
  },
  [STANDING_INSTRUCTIONS_SAVE_PENDING]: (state, action) => {
    return { ...state, readyState: STANDING_INSTRUCTIONS_SAVE_PENDING }
  },
  [STANDING_INSTRUCTIONS_SAVE_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: STANDING_INSTRUCTIONS_SAVE_REJECTED, error: action.error, message }
  },
  [STANDING_INSTRUCTIONS_SAVE_FULFILLED]: (state, action) => {
    return { ...state, readyState: STANDING_INSTRUCTIONS_SAVE_FULFILLED, object: action.payload.data }
  },
  [STANDING_INSTRUCTIONS_DELETE_PENDING]: (state, action) => {
    return { ...state, readyState: STANDING_INSTRUCTIONS_DELETE_PENDING }
  },
  [STANDING_INSTRUCTIONS_DELETE_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: STANDING_INSTRUCTIONS_DELETE_REJECTED, error: action.error, message }
  },
  [STANDING_INSTRUCTIONS_DELETE_FULFILLED]: (state, action) => {
    return { ...state, readyState: STANDING_INSTRUCTIONS_DELETE_FULFILLED, object: action.payload.data }
  },
  [UPDATE_CUSTODIAN_PENDING]: (state, action) => {
    return { ...state, readyState: UPDATE_CUSTODIAN_PENDING }
  },
  [UPDATE_CUSTODIAN_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: UPDATE_CUSTODIAN_REJECTED, error: action.error, message }
  },
  [UPDATE_CUSTODIAN_FULFILLED]: (state, action) => {
    return { ...state, readyState: UPDATE_CUSTODIAN_FULFILLED, object: action.payload.data }
  },
  [SUBMIT_TICKET_PENDING]: (state, action) => {
    return { ...state, readyState: SUBMIT_TICKET_PENDING }
  },
  [SUBMIT_TICKET_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: SUBMIT_TICKET_REJECTED, error: action.error, message }
  },
  [SUBMIT_TICKET_FULFILLED]: (state, action) => {
    return { ...state, readyState: SUBMIT_TICKET_FULFILLED, object: action.payload.data }
  }
}

export default function custodialRequests (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
