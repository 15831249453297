import config from '../config'
import HttpClient, { CancelToken, isCancel } from './httpclient'
import browserHistory from '../helpers/browserHistory'
import { cancelFetchRequests, getBody } from '../helpers/util'

export const OBJECT_INVALID = 'OBJECT_INVALID'

export const OBJECT_SAVE = 'OBJECT_SAVE'
export const OBJECT_SAVE_PENDING = 'OBJECT_SAVE_PENDING'
export const OBJECT_SAVE_REJECTED = 'OBJECT_SAVE_REJECTED'
export const OBJECT_SAVE_FULFILLED = 'OBJECT_SAVE_FULFILLED'

export const OBJECT_TOGGLE_STATUS = 'OBJECT_TOGGLE_STATUS'
export const OBJECT_TOGGLE_STATUS_PENDING = 'OBJECT_TOGGLE_STATUS_PENDING'
export const OBJECT_TOGGLE_STATUS_REJECTED = 'OBJECT_TOGGLE_STATUS_REJECTED'
export const OBJECT_TOGGLE_STATUS_FULFILLED = 'OBJECT_TOGGLE_STATUS_FULFILLED'

export const OBJECT_FETCH = 'OBJECT_FETCH'
export const OBJECT_FETCH_PENDING = 'OBJECT_FETCH_PENDING'
export const OBJECT_FETCH_REJECTED = 'OBJECT_FETCH_REJECTED'
export const OBJECT_FETCH_FULFILLED = 'OBJECT_FETCH_FULFILLED'

export const OBJECT_LIST = 'OBJECT_LIST'
export const OBJECT_LIST_PENDING = 'OBJECT_LIST_PENDING'
export const OBJECT_LIST_REJECTED = 'OBJECT_LIST_REJECTED'
export const OBJECT_LIST_FULFILLED = 'OBJECT_LIST_FULFILLED'

export const OBJECT_DELETE = 'OBJECT_DELETE'
export const OBJECT_DELETE_PENDING = 'OBJECT_DELETE_PENDING'
export const OBJECT_DELETE_REJECTED = 'OBJECT_DELETE_REJECTED'
export const OBJECT_DELETE_FULFILLED = 'OBJECT_DELETE_FULFILLED'

export function getType (type, model) {
  return `${model}_${type}`
}

export function getTypes (model) {
  return {
    OBJECT_INVALID: `${model}_${OBJECT_INVALID}`,
    OBJECT_SAVE_PENDING: `${model}_${OBJECT_SAVE_PENDING}`,
    OBJECT_SAVE_REJECTED: `${model}_${OBJECT_SAVE_REJECTED}`,
    OBJECT_SAVE_FULFILLED: `${model}_${OBJECT_SAVE_FULFILLED}`,
    OBJECT_TOGGLE_STATUS_PENDING: `${model}_${OBJECT_TOGGLE_STATUS_PENDING}`,
    OBJECT_TOGGLE_STATUS_REJECTED: `${model}_${OBJECT_TOGGLE_STATUS_REJECTED}`,
    OBJECT_TOGGLE_STATUS_FULFILLED: `${model}_${OBJECT_TOGGLE_STATUS_FULFILLED}`,
    OBJECT_FETCH_PENDING: `${model}_${OBJECT_FETCH_PENDING}`,
    OBJECT_FETCH_REJECTED: `${model}_${OBJECT_FETCH_REJECTED}`,
    OBJECT_FETCH_FULFILLED: `${model}_${OBJECT_FETCH_FULFILLED}`,
    OBJECT_LIST_PENDING: `${model}_${OBJECT_LIST_PENDING}`,
    OBJECT_LIST_REJECTED: `${model}_${OBJECT_LIST_REJECTED}`,
    OBJECT_LIST_FULFILLED: `${model}_${OBJECT_LIST_FULFILLED}`,
    OBJECT_DELETE_PENDING: `${model}_${OBJECT_DELETE_PENDING}`,
    OBJECT_DELETE_REJECTED: `${model}_${OBJECT_DELETE_REJECTED}`,
    OBJECT_DELETE_FULFILLED: `${model}_${OBJECT_DELETE_FULFILLED}`
  }
}

export function getActions (model, path) {
  let cancel = []
  return {
    _onSuccess: dispatch => response => {
      cancel = []
      dispatch({ type: `${model}_${OBJECT_LIST_FULFILLED}`, payload: response })
    },
    _onFail: dispatch => error => {
      cancel = cancel.filter(c => c !== null)
      if (!isCancel(error)) {
        dispatch({ type: `${model}_${OBJECT_LIST_REJECTED}`, payload: error })
      }
    },
    redirect (response) {
      browserHistory.push(`/${path}`)
      return response
    },
    shouldRedirect (redirect) {
      return response => {
        if (redirect) {
          return this.redirect(response)
        }
        return response
      }
    },
    save (object, redirect = true) {
      const method = object.id ? 'put' : 'post'
      const url = `${config.api}/${path}/${object.id || ''}`
      return {
        type: getType(OBJECT_SAVE, model),
        payload: HttpClient[method](url, object).then(this.shouldRedirect(redirect))
      }
    },
    saveWithUpload (object, uploadFields = [], redirect = true) {
      const method = object.id ? 'put' : 'post'
      const body = getBody(object, uploadFields)
      const url = `${config.api}/${path}/${object.id || ''}`
      return {
        type: getType(OBJECT_SAVE, model),
        payload: HttpClient[method](url, body).then(this.shouldRedirect(redirect))
      }
    },
    toggleActive (id, updateList = false) {
      return {
        type: getType(OBJECT_TOGGLE_STATUS, model),
        payload: HttpClient.put(`${config.api}/${path}/${id}/toggle-active`),
        meta: { updateList }
      }
    },
    delete (id) {
      return {
        type: getType(OBJECT_DELETE, model),
        payload: HttpClient.delete(`${config.api}/${path}/${id}`)
      }
    },
    fetch (id) {
      return {
        type: getType(OBJECT_FETCH, model),
        payload: HttpClient.get(`${config.api}/${path}/${id}`)
      }
    },
    fetchPublic (id) {
      return {
        type: getType(OBJECT_FETCH, model),
        payload: HttpClient.get(`${config.api}/${path}/${id}/public`)
      }
    },
    list (page = 1, status = 'active', term = null, sort = null, filter = null) {
      if (cancel.length !== 0) {
        cancelFetchRequests(cancel)
      }
      const params = {
        cancelToken: new CancelToken(function executor (c) {
          cancel.push(c)
        }),
        params: {
          page,
          status,
          filter
        }
      }

      if (term) {
        params.params.q = term
      }

      if (sort) {
        params.params.order = sort.order
        params.params.direction = sort.direction
      }

      return dispatch => {
        dispatch({ type: `${model}_${OBJECT_LIST_PENDING}` })
        HttpClient.get(`${config.api}/${path}`, params).then(this._onSuccess(dispatch)).catch(this._onFail(dispatch))
      }
    },
    listWith (page, status, term, sort, options) {
      if (cancel.length !== 0) {
        cancelFetchRequests(cancel)
      }
      const params = {
        cancelToken: new CancelToken(function executor (c) {
          cancel.push(c)
        }),
        params: {
          page,
          status,
          ...options
        }
      }

      if (term) {
        params.params.q = term
      }

      if (sort) {
        params.params.order = sort.order
        params.params.direction = sort.direction
      }

      return dispatch => {
        dispatch({ type: `${model}_${OBJECT_LIST_PENDING}` })
        HttpClient.get(`${config.api}/${path}`, params).then(this._onSuccess(dispatch)).catch(this._onFail(dispatch))
      }
    },
    listWithServiceTeam (page = 1, status = 'active', term = null, sort = null, serviceTeam = null) {
      return this.listWith(page, status, term, sort, { serviceTeam })
    },
    listWithHousehold (page = 1, status = 'active', term = null, sort = null, household = null, options = {}) {
      return this.listWith(page, status, term, sort, { household, ...options })
    },
    listWithHouseholdAndAdvisor (page = 1, status = 'active', term = null, sort = null, household = null, serviceTeam = null, options = {}) {
      return this.listWith(page, status, term, sort, { household, serviceTeam, ...options })
    },
    listWithHouseholdAndAdvisorAndAccount (page = 1, status = 'active', term = null, sort = null, household = null, serviceTeam = null, account = null, options = {}) {
      return this.listWith(page, status, term, sort, { household, serviceTeam, account, ...options})
    },
    invalidate () {
      return {
        type: getType(OBJECT_INVALID, model)
      }
    }
  }
}
