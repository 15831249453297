import config from '../config'
import HttpClient from '../helpers/httpclient'
import browserHistory from '../helpers/browserHistory'
import { getParameterByName } from '../helpers/util'

export const SAVE_HOUSEHOLD_SAVING = 'SAVE_HOUSEHOLD_SAVING'
export const SAVE_HOUSEHOLD_SUCCESS = 'SAVE_HOUSEHOLD_SUCCESS'
export const SAVE_HOUSEHOLD_FAILED = 'SAVE_HOUSEHOLD_FAILED'

export const HOUSEHOLD_INVALID = 'HOUSEHOLD_INVALID'
export const HOUSEHOLD_FETCHING = 'HOUSEHOLD_FETCHING'
export const HOUSEHOLD_FETCHED = 'HOUSEHOLD_FETCHED'
export const HOUSEHOLD_FETCH_FAILED = 'HOUSEHOLD_FETCH_FAILED'

export const HOUSEHOLD_REMOVE_CLIENT = 'HOUSEHOLD_REMOVE_CLIENT'
export const HOUSEHOLD_REMOVE_CLIENT_PENDING = 'HOUSEHOLD_REMOVE_CLIENT_PENDING'
export const HOUSEHOLD_REMOVE_CLIENT_REJECTED = 'HOUSEHOLD_REMOVE_CLIENT_REJECTED'
export const HOUSEHOLD_REMOVE_CLIENT_FULFILLED = 'HOUSEHOLD_REMOVE_CLIENT_FULFILLED'

export const HOUSEHOLD_REMOVE_ACCOUNT = 'HOUSEHOLD_REMOVE_ACCOUNT'
export const HOUSEHOLD_REMOVE_ACCOUNT_PENDING = 'HOUSEHOLD_REMOVE_ACCOUNT_PENDING'
export const HOUSEHOLD_REMOVE_ACCOUNT_REJECTED = 'HOUSEHOLD_REMOVE_ACCOUNT_REJECTED'
export const HOUSEHOLD_REMOVE_ACCOUNT_FULFILLED = 'HOUSEHOLD_REMOVE_ACCOUNT_FULFILLED'

export const HOUSEHOLD_SAVE_CLIENT = 'HOUSEHOLD_SAVE_CLIENT'
export const HOUSEHOLD_SAVE_CLIENT_PENDING = 'HOUSEHOLD_SAVE_CLIENT_PENDING'
export const HOUSEHOLD_SAVE_CLIENT_REJECTED = 'HOUSEHOLD_SAVE_CLIENT_REJECTED'
export const HOUSEHOLD_SAVE_CLIENT_FULFILLED = 'HOUSEHOLD_SAVE_CLIENT_FULFILLED'

export const HOUSEHOLD_SAVE_ACCOUNT = 'HOUSEHOLD_SAVE_ACCOUNT'
export const HOUSEHOLD_SAVE_ACCOUNT_PENDING = 'HOUSEHOLD_SAVE_ACCOUNT_PENDING'
export const HOUSEHOLD_SAVE_ACCOUNT_REJECTED = 'HOUSEHOLD_SAVE_ACCOUNT_REJECTED'
export const HOUSEHOLD_SAVE_ACCOUNT_FULFILLED = 'HOUSEHOLD_SAVE_ACCOUNT_FULFILLED'

export const HOUSEHOLD_ADD_CLIENT = 'HOUSEHOLD_ADD_CLIENT'
export const HOUSEHOLD_ADD_ACCOUNT = 'HOUSEHOLD_ADD_ACCOUNT'

export const HOUSEHOLD_HIDE_ADDRESS_NOTIFICATION = 'HOUSEHOLD_HIDE_ADDRESS_NOTIFICATION'

export const HOUSEHOLD_TOGGLING_STATUS = 'HOUSEHOLD_TOGGLING_STATUS'
export const HOUSEHOLD_TOGGLE_STATUS_SUCCESS = 'HOUSEHOLD_TOGGLE_STATUS_SUCCESS'
export const HOUSEHOLD_TOGGLE_STATUS_FAILED = 'HOUSEHOLD_TOGGLE_STATUS_FAILED'

export const HOUSEHOLD_CLOSE = 'HOUSEHOLD_CLOSE'
export const HOUSEHOLD_CLOSE_PENDING = 'HOUSEHOLD_CLOSE_PENDING'
export const HOUSEHOLD_CLOSE_REJECTED = 'HOUSEHOLD_CLOSE_REJECTED'
export const HOUSEHOLD_CLOSE_FULFILLED = 'HOUSEHOLD_CLOSE_FULFILLED'

export const HOUSEHOLD_REOPEN = 'HOUSEHOLD_REOPEN'
export const HOUSEHOLD_REOPEN_PENDING = 'HOUSEHOLD_REOPEN_PENDING'
export const HOUSEHOLD_REOPEN_REJECTED = 'HOUSEHOLD_REOPEN_REJECTED'
export const HOUSEHOLD_REOPEN_FULFILLED = 'HOUSEHOLD_REOPEN_FULFILLED'

function getAgreement (household, field) {
  if (household[field]) {
    return typeof household[field] === 'string' ? household[field] : household[field][0]
  }
  return household[field]
}

function getHouseholdBody (household) {
  const body = new FormData()
  Object.keys(household).forEach(key => {
    const val = key === 'agreement_ad' || key === 'agreement_advisor' ? getAgreement(household, key) : household[key]
    if (key === 'ad_billing_definition_id') {
      body.append(key, val)
      return
    }
    val !== null && val !== undefined && body.append(key, val)
  })

  return body
}

export function saveHousehold (household) {
  return (dispatch) => {
    dispatch({ type: SAVE_HOUSEHOLD_SAVING, household: household })

    return HttpClient.post(`${config.api}/household`, household)
    .then((response) => {
      return response.data
    })
    .then(
      result => {
        dispatch({ type: SAVE_HOUSEHOLD_SUCCESS, result })
        if (getParameterByName('return') === 'accounts') {
          browserHistory.push('/accounts')
        } else if (getParameterByName('return') === 'proposal') {
          browserHistory.push(`/proposal/new?household=${result.id}`)
        } else if (getParameterByName('return') === 'critical-path') {
          browserHistory.push(`/critical-path/new?household=${result.id}`)
        } else if (getParameterByName('return') === 'agreement') {
          browserHistory.push(`/agreement?household=${result.id}`)
        } else if (getParameterByName('return') === 'client') {
          browserHistory.push(`/client/new?household=${result.id}`)
        } else if (getParameterByName('return') === 'new-tamarac-account') {
          browserHistory.push(`/account/${getParameterByName('object')}/new-account?household=${result.id}`)
        } else if (getParameterByName('go') === 'custodial-request') {
          browserHistory.push(`/custodial-request/${result._custodial_request}/edit`)
        } else {
          browserHistory.push(`/household/${result.id}/edit`)
        }
      },
      error => dispatch({ type: SAVE_HOUSEHOLD_FAILED, error })
    )
  }
}

export function updateHousehold (id, household) {
  return (dispatch) => {
    dispatch({ type: SAVE_HOUSEHOLD_SAVING, household: household })

    return HttpClient.put(`${config.api}/household/${id}`, getHouseholdBody(household))
    .then((response) => {
      return response.data
    })
    .then(
      result => {
        dispatch({ type: SAVE_HOUSEHOLD_SUCCESS, result })
      },
      error => dispatch({ type: SAVE_HOUSEHOLD_FAILED, error })
    )
  }
}

export function uploadAgreement (household) {
  return (dispatch) => {
    dispatch({ type: SAVE_HOUSEHOLD_SAVING, household: household })

    return HttpClient.put(`${config.api}/household/${household.id}/upload`, getHouseholdBody(household))
    .then(response => response.data)
    .then(
      result => dispatch({ type: SAVE_HOUSEHOLD_SUCCESS, result }),
      error => dispatch({ type: SAVE_HOUSEHOLD_FAILED, error })
    )
  }
}

export function removeClient (household, id) {
  return {
    type: HOUSEHOLD_REMOVE_CLIENT,
    payload: HttpClient.delete(`${config.api}/households/${household}/clients/${id}`)
  }
}

export function removeNewClient (id) {
  return {
    type: HOUSEHOLD_REMOVE_CLIENT_FULFILLED,
    payload: { data: { _id: id } }
  }
}

export function saveClient (household, values) {
  const method = values.id ? 'put' : 'post'
  return {
    type: HOUSEHOLD_SAVE_CLIENT,
    payload: HttpClient[method](`${config.api}/households/${household}/clients/${values.id || ''}`, values)
  }
}

export function addClient (household) {
  return {
    type: HOUSEHOLD_ADD_CLIENT,
    payload: household
  }
}

export function removeAccount (household, id) {
  return {
    type: HOUSEHOLD_REMOVE_ACCOUNT,
    payload: HttpClient.delete(`${config.api}/households/${household}/accounts/${id}`)
  }
}

export function removeNewAccount () {
  return {
    type: HOUSEHOLD_REMOVE_ACCOUNT_FULFILLED,
    payload: { data: {} }
  }
}

export function saveAccount (household, values) {
  const method = values.id ? 'put' : 'post'
  return {
    type: HOUSEHOLD_SAVE_ACCOUNT,
    payload: HttpClient[method](`${config.api}/households/${household}/accounts/${values.id || ''}`, values)
  }
}

export function addAccount (household) {
  return {
    type: HOUSEHOLD_ADD_ACCOUNT,
    payload: household
  }
}

export function hideAddressNotification (id) {
  return {
    type: HOUSEHOLD_HIDE_ADDRESS_NOTIFICATION,
    payload: HttpClient.put(`${config.api}/household/${id}/hide-address-notification`)
  }
}

export function fetchHousehold (id) {
  return (dispatch) => {
    dispatch({ type: HOUSEHOLD_FETCHING })

    return HttpClient.get(`${config.api}/household/${id}`)
      .then((response) => {
        return response.data
      })
      .then(
        result => dispatch({ type: HOUSEHOLD_FETCHED, result }),
        error => dispatch({ type: HOUSEHOLD_FETCH_FAILED, error })
      )
  }
}

export function showHousehold (id) {
  return HttpClient.get(`${config.api}/household/${id}`).then(r => r.data)
}

export function editHousehold (body) {
  return HttpClient.put(`${config.api}/household/${body.id}`, body).then(r => r.data)
}

export function toggleHouseholdActive (id) {
  return dispatch => {
    dispatch({ type: HOUSEHOLD_TOGGLING_STATUS })
    return HttpClient.put(`${config.api}/household/${id}/toggle-active`)
      .then(response => response.data)
      .then(
        result => dispatch({ type: HOUSEHOLD_TOGGLE_STATUS_SUCCESS, result }),
        error => dispatch({ type: HOUSEHOLD_TOGGLE_STATUS_FAILED, error })
      )
  }
}

export function descriptiveName (id) {
  return HttpClient.get(`${config.api}/household/${id}/name`).then(r => r.data)
}

export function closeHousehold (id, closeDate) {
  const body = {}
  if (closeDate) {
    body.close_date = closeDate
  }
  return {
    type: HOUSEHOLD_CLOSE,
    payload: HttpClient.post(`${config.api}/household/${id}/close`, body)
  }
}

export function reopenHousehold (id, accounts) {
  return {
    type: HOUSEHOLD_REOPEN,
    payload: HttpClient.post(`${config.api}/household/${id}/reopen`, { accounts })
  }
}

export function sendUniqueBillingSetup (id, account, note) {
  const url = `${config.api}/household/${id}/unique-billing-setup/${account}`
  return HttpClient.post(url, { note }).then(r => r.data)
}
