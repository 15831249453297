import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import CompanyActions from '../actions/companies'
import { getUser, saveUser } from '../actions/auth'

const initialState = getInitialState('COMPANIES')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('COMPANIES'),
  [CompanyActions.OBJECT_SAVE_FULFILLED]: (state, action) => {
    const company = action.payload.data
    const user = getUser()
    if (company.id === user.Company.id) {
      user.Company = company
      saveUser(user)
    }
    return { ...state, readyState: CompanyActions.OBJECT_SAVE_FULFILLED, object: company }
  }
}

export default function roles (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
