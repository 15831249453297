import config from '../config'
import HttpClient, { CancelToken, isCancel } from '../helpers/httpclient'
import { cancelFetchRequests } from '../helpers/util'

export const ALERTS_INVALID = 'ALERTS_INVALID'
export const ALERTS_FETCHING = 'ALERTS_FETCHING'
export const ALERTS_FETCHED = 'ALERTS_FETCHED'
export const ALERTS_FETCH_FAILED = 'ALERTS_FETCH_FAILED'

let cancel = []

export function fetchAlerts (page = 1, term = undefined, sort = undefined, status = 'all', serviceTeam = null) {
  return (dispatch) => {
    if (cancel.length !== 0) {
      cancelFetchRequests(cancel)
    }
    dispatch({ type: ALERTS_FETCHING })

    const params = {
      cancelToken: new CancelToken(function executor(c) {
        cancel.push(c)
      }),
      params: {
        page,
        serviceTeam,
        status
      }
    }

    if (term) {
      params.params.q = term
    }

    if (sort) {
      params.params.order = sort.order
      params.params.direction = sort.direction
    }

    return HttpClient.get(`${config.api}/alerts`, params)
      .then((response) => {
        return response.data
      })
      .then(
        result => {
          cancel = []
          dispatch({ type: ALERTS_FETCHED, result })
        },
        error => {
          cancel = cancel.filter(c => c !== null)
          if (!isCancel(error)) {
            dispatch({ type: ALERTS_FETCH_FAILED, error })
          }
        }
      )
  }
}
