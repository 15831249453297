import React, { Fragment, useState } from 'react'
import { SketchPicker } from 'react-color'

export default function ColorPicker ({ value, onChange }) {
  const [ displayColorPicker, setDisplayColorPicker ] = useState(false)
  const [ color, setColor ] = useState(value || {})
  const handleClick = () => setDisplayColorPicker(!displayColorPicker)
  const handleClose = () => setDisplayColorPicker(false)
  const handleChange = value => setColor(value.hex)
  const handleChangeComplete = value => {
    setColor(value.hex)
    onChange(value.hex)
  }
  return (
    <Fragment>
      <div className="color-picker-swatch" onClick={handleClick}>
        <div className="color-picker-color" style={{ background: color }} />
      </div>
      {displayColorPicker && (
        <div className="color-picker-popover">
          <div className="color-picker-cover" onClick={handleClose} />
          <SketchPicker color={color} onChange={handleChange} onChangeComplete={handleChangeComplete} />
        </div>
      )}
    </Fragment>
  )
}
