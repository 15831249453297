import config from "../config";
import HttpClient, { CancelToken, isCancel } from "../helpers/httpclient";
import browserHistory from "../helpers/browserHistory";
import { cancelFetchRequests, getBody } from "../helpers/util";

export const ACCOUNTS_INVALID = "ACCOUNTS_INVALID";
export const ACCOUNTS_FETCHING = "ACCOUNTS_FETCHING";
export const ACCOUNTS_FETCHED = "ACCOUNTS_FETCHED";
export const ACCOUNTS_FETCH_FAILED = "ACCOUNTS_FETCH_FAILED";

export const ACCOUNT_SAVING = "ACCOUNT_SAVING";
export const SAVE_ACCOUNT_SUCCESS = "SAVE_ACCOUNT_SUCCESS";
export const SAVE_ACCOUNT_FAILED = "SAVE_ACCOUNT_FAILED";

export const ACCOUNT_INVALID = "ACCOUNT_INVALID";
export const ACCOUNT_FETCHING = "ACCOUNT_FETCHING";
export const ACCOUNT_FETCHED = "ACCOUNT_FETCHED";
export const ACCOUNT_FETCH_FAILED = "ACCOUNT_FETCH_FAILED";

export const ACCOUNT_TOGGLING_STATUS = "ACCOUNT_TOGGLING_STATUS";
export const ACCOUNT_TOGGLE_STATUS_SUCCESS = "ACCOUNT_TOGGLE_STATUS_SUCCESS";
export const ACCOUNT_TOGGLE_STATUS_FAILED = "ACCOUNT_TOGGLE_STATUS_FAILED";

export const MERGE_ACCOUNT = "MERGE_ACCOUNT";
export const MERGE_ACCOUNT_PENDING = "MERGE_ACCOUNT_PENDING";
export const MERGE_ACCOUNT_REJECTED = "MERGE_ACCOUNT_REJECTED";
export const MERGE_ACCOUNT_FULFILLED = "MERGE_ACCOUNT_FULFILLED";

export const CLOSE_ACCOUNT = "CLOSE_ACCOUNT";
export const CLOSE_ACCOUNT_PENDING = "CLOSE_ACCOUNT_PENDING";
export const CLOSE_ACCOUNT_REJECTED = "CLOSE_ACCOUNT_REJECTED";
export const CLOSE_ACCOUNT_FULFILLED = "CLOSE_ACCOUNT_FULFILLED";

export const REOPEN_ACCOUNT = "REOPEN_ACCOUNT";
export const REOPEN_ACCOUNT_PENDING = "REOPEN_ACCOUNT_PENDING";
export const REOPEN_ACCOUNT_REJECTED = "REOPEN_ACCOUNT_REJECTED";
export const REOPEN_ACCOUNT_FULFILLED = "REOPEN_ACCOUNT_FULFILLED";

export const CONVERT_TO_UC_ACCOUNT = "CONVERT_TO_UC_ACCOUNT";
export const CONVERT_TO_UC_ACCOUNT_PENDING = "CONVERT_TO_UC_ACCOUNT_PENDING";
export const CONVERT_TO_UC_ACCOUNT_REJECTED = "CONVERT_TO_UC_ACCOUNT_REJECTED";
export const CONVERT_TO_UC_ACCOUNT_FULFILLED =
  "CONVERT_TO_UC_ACCOUNT_FULFILLED";

export const CONVERT_MANAGED_TO_ADMIN = "CONVERT_MANAGED_TO_ADMIN";
export const CONVERT_MANAGED_TO_ADMIN_PENDING =
  "CONVERT_MANAGED_TO_ADMIN_PENDING";
export const CONVERT_MANAGED_TO_ADMIN_REJECTED =
  "CONVERT_MANAGED_TO_ADMIN_REJECTED";
export const CONVERT_MANAGED_TO_ADMIN_FULFILLED =
  "CONVERT_MANAGED_TO_ADMIN_FULFILLED";

export const AD_WILL_NOT_MANAGE = "AD_WILL_NOT_MANAGE";
export const AD_WILL_NOT_MANAGE_PENDING = "AD_WILL_NOT_MANAGE_PENDING";
export const AD_WILL_NOT_MANAGE_REJECTED = "AD_WILL_NOT_MANAGE_REJECTED";
export const AD_WILL_NOT_MANAGE_FULFILLED = "AD_WILL_NOT_MANAGE_FULFILLED";

export function saveAccount(account) {
  return (dispatch) => {
    dispatch({ type: ACCOUNT_SAVING, account: account });

    return HttpClient.post(`${config.api}/account`, account)
      .then((response) => {
        return response.data;
      })
      .then(
        (result) => {
          dispatch({ type: SAVE_ACCOUNT_SUCCESS, result });
          browserHistory.push("/accounts");
        },
        (error) => dispatch({ type: SAVE_ACCOUNT_FAILED, error })
      );
  };
}

export function createAccount(account) {
  return (dispatch) => {
    dispatch({ type: ACCOUNT_SAVING, account: account });

    return HttpClient.post(`${config.api}/create-account`, account)
      .then((response) => {
        return response.data;
      })
      .then(
        (result) => dispatch({ type: SAVE_ACCOUNT_SUCCESS, result }),
        (error) => dispatch({ type: SAVE_ACCOUNT_FAILED, error })
      );
  };
}

export function updateAccount(id, account, redirect = true) {
  return (dispatch) => {
    dispatch({ type: ACCOUNT_SAVING, account: account });
    const body = getBody(account, ["manual_account_attachment"]);
    return HttpClient.put(`${config.api}/account/${id}`, body)
      .then((response) => response.data)
      .then(
        (result) => {
          dispatch({ type: SAVE_ACCOUNT_SUCCESS, result });
          redirect && browserHistory.push("/accounts");
        },
        (error) => dispatch({ type: SAVE_ACCOUNT_FAILED, error })
      );
  };
}

export async function updateAccountField(id, body) {
  const r = await HttpClient.put(`${config.api}/account/${id}`, body);
  return r.data;
}

export function closeAccount(id, closeDate) {
  const params = { close_date: closeDate };
  return {
    type: CLOSE_ACCOUNT,
    payload: HttpClient.put(`${config.api}/account/${id}/close`, params),
  };
}

export function reopenAccount(id) {
  return {
    type: REOPEN_ACCOUNT,
    payload: HttpClient.put(`${config.api}/account/${id}/reopen`),
  };
}

export function toggleAccountActive(id) {
  return (dispatch) => {
    dispatch({ type: ACCOUNT_TOGGLING_STATUS });
    return HttpClient.put(`${config.api}/account/${id}/toggle-active`)
      .then((response) => {
        return response.data;
      })
      .then(
        (result) => dispatch({ type: ACCOUNT_TOGGLE_STATUS_SUCCESS, result }),
        (error) => dispatch({ type: ACCOUNT_TOGGLE_STATUS_FAILED, error })
      );
  };
}

export function fetchAccount(id) {
  return (dispatch) => {
    dispatch({ type: ACCOUNT_FETCHING });

    return HttpClient.get(`${config.api}/account/${id}`)
      .then((response) => {
        return response.data;
      })
      .then(
        (result) => dispatch({ type: ACCOUNT_FETCHED, result }),
        (error) => dispatch({ type: ACCOUNT_FETCH_FAILED, error })
      );
  };
}

let cancel = [];

export function fetchAccounts(
  page = 1,
  term = undefined,
  sort = undefined,
  status = "active",
  household = null,
  serviceTeam = null,
  management = null
) {
  return (dispatch) => {
    if (cancel.length !== 0) {
      cancelFetchRequests(cancel);
    }
    dispatch({ type: ACCOUNTS_FETCHING });

    const params = {
      cancelToken: new CancelToken(function executor(c) {
        cancel.push(c);
      }),
      params: {
        page,
        household,
        serviceTeam,
        management,
        status,
      },
    };

    if (term) {
      params.params.q = term;
    }

    if (sort) {
      params.params.order = sort.order;
      params.params.direction = sort.direction;
    }

    return HttpClient.get(`${config.api}/accounts`, params)
      .then((response) => {
        return response.data;
      })
      .then(
        (result) => {
          cancel = [];
          dispatch({ type: ACCOUNTS_FETCHED, result });
        },
        (error) => {
          cancel = cancel.filter((c) => c !== null);
          if (!isCancel(error)) {
            dispatch({ type: ACCOUNTS_FETCH_FAILED, error });
          }
        }
      );
  };
}

export function mergeAccounts(account, newAccount) {
  const params = {
    newAccount: newAccount,
  };
  return {
    type: MERGE_ACCOUNT,
    payload: HttpClient.post(
      `${config.api}/account/${account.id}/merge`,
      params
    ),
  };
}

export function saveManualAccount(account) {
  return (dispatch) => {
    dispatch({ type: ACCOUNT_SAVING, account: account });
    const values = { ...account };
    values.manual_holdings = JSON.stringify(values.manual_holdings);
    const body = getBody(values, ["manual_account_attachment"]);
    return HttpClient.post(`${config.api}/accounts/manual-account`, body).then(
      (result) => {
        dispatch({ type: SAVE_ACCOUNT_SUCCESS, result: result.data });
        browserHistory.push(`/account/${result.data.id}/edit?manual=true`);
      },
      (error) => dispatch({ type: SAVE_ACCOUNT_FAILED, error })
    );
  };
}

export function convertManualToUCAccount(id) {
  return {
    type: CONVERT_TO_UC_ACCOUNT,
    payload: HttpClient.put(`${config.api}/account/${id}/convert-manual-to-uc`),
  };
}

export function convertManagedtoAdmin(id) {
  return {
    type: CONVERT_MANAGED_TO_ADMIN,
    payload: HttpClient.put(
      `${config.api}/account/${id}/convert-managed-to-admin`
    ),
  };
}

export function adWillNotManage(id) {
  return {
    type: AD_WILL_NOT_MANAGE,
    payload: HttpClient.put(`${config.api}/account/${id}/ad-will-not-manage`),
  };
}

export function getAccounts(
  household,
  administration = false,
  tamarac = false
) {
  const params = { params: { household } };
  if (administration) {
    params.params.administration = true;
  }
  if (tamarac) {
    params.params.tamarac = true;
  }
  return HttpClient.get(`${config.api}/accounts/all`, params);
}

export async function fetchAccountsOptions(
  household,
  administration = false,
  tamarac = false
) {
  const getOption = (m) => ({
    value: m,
    label: `${m.account_name} (${m.account_number})`,
  });
  const getOptions = (ms) => ({ options: ms.accounts.map(getOption) });
  const r = await getAccounts(household, administration, tamarac);
  const ms = r.data;
  return getOptions(ms);
}

export async function fetchAccountsOptionsPaginated(input) {
  const params = { params: { q: input } };
  const getOptions = (r) => ({
    options: r.data.objects.map((a) => ({
      value: a.id,
      label: `${a.account_name} (${a.account_number})`,
    })),
  });
  const r = await HttpClient.get(`${config.api}/accounts/paginated`, params);
  return getOptions(r);
}

export function fetchAccountsOptionsByServiceTeam(
  serviceTeams,
  input,
  active = true
) {
  const params = { params: { q: input } };
  if (serviceTeams) params.params.serviceTeams = serviceTeams;
  if (active) params.params.active = true;

  const promise = HttpClient.get(
    `${config.api}/service-teams/accounts`,
    params
  );

  return promise
    .then((r) => r.data)
    .then((res) => ({
      options: res.map((acc) => ({
        value: acc.id,
        label: `${acc.account_name} (${acc.account_number})`,
        object: acc,
      })),
    }));
}

export function fetchAccountOptionsForPage(
  page,
  serviceTeam,
  household,
  input
) {
  const params = { params: {} };
  if (input) params.params.q = input;
  if (serviceTeam) params.params.serviceTeam = serviceTeam;
  if (household) params.params.household = household;

  const promise = HttpClient.get(
    `${config.api}/account-options/${page}`,
    params
  );

  return promise
    .then((r) => r.data)
    .then((res) => ({
      options: res.map((acc) => ({
        value: acc.id || acc.account_id,
        label: `${acc.account_name} (${acc.account_number})`,
        object: acc,
      })),
    }));
}

export function getTamaracAccounts(q, household, exclude, include) {
  const params = { params: { q } };
  if (household) {
    params.params.household = household;
  }
  if (exclude) {
    params.params.exclude = exclude;
  }
  if (include) {
    params.params.include = include;
  }
  return HttpClient.get(`${config.api}/accounts/tamarac`, params);
}

export function fetchTamaracAccountsOptions(household, exclude, include) {
  const getOption = (m) => ({
    value: m.id,
    label: `${m.account_name} (${m.account_number})`,
  });
  const getOptions = (ms) => ({ options: ms.accounts.map(getOption) });
  return async (input) => {
    const r = await getTamaracAccounts(input, household, exclude, include);
    const ms = r.data;
    return getOptions(ms);
  };
}

export function getPayableAccounts(household) {
  const params = { params: { household } };
  return HttpClient.get(`${config.api}/accounts/payable`, params);
}

export async function fetchPayableAccountsOptions(household) {
  const getOption = (m) => ({
    value: m,
    label: `${m.account_name} (${m.account_number})`,
  });
  const getOptions = (ms) => ({ options: ms.accounts.map(getOption) });
  if (!household) {
    return Promise.resolve({ options: [] });
  }
  const r = await getPayableAccounts(household);
  const r_1 = r.data;
  const opts = getOptions(r_1);
  opts.options.unshift({ value: { id: -1 }, label: "<Invoice client>" });
  return opts;
}

export async function fetchTamaracAccountOptionsByHousehold(household) {
  const getOption = (m) => ({
    value: m.id,
    label: `${m.account_name} (${m.account_number})`,
  });
  const getOptions = (ms) => ({ options: ms.accounts.map(getOption) });
  const r = await getAccounts(household, false, true);
  const ms = r.data;
  return getOptions(ms);
}

export async function externalAccounts(proposalVersion) {
  const params = {
    params: {
      proposal: proposalVersion.id,
      household: proposalVersion.household_id,
    },
  };
  const r = await HttpClient.get(
    `${config.api}/accounts/external-accounts`,
    params
  );
  return r.data;
}

export async function fetchAccountsInfo(accounts) {
  const params = { params: { accounts } };
  const r = await HttpClient.get(`${config.api}/accounts/info`, params);
  return r.data;
}

export async function fetchShowImportCashFlows(account) {
  const r = await HttpClient.get(
    `${config.api}/accounts/${account}/show-import-cash-flows`
  );
  return r.data;
}

export async function fetchSecurities(account) {
  const r = await HttpClient.get(
    `${config.api}/accounts/${account}/securities`
  );
  return r.data;
}

export async function fetchExpectedGainLoss(account, security, amount) {
  const params = { params: { amount } };
  const r = await HttpClient.get(
    `${config.api}/accounts/${account}/expected-gain-loss/${security}`,
    params
  );
  return r.data;
}

export async function fetchHoldings(account) {
  const r = await HttpClient.get(`${config.api}/accounts/${account}/holdings`);
  return r.data;
}

export async function fetchHoldingProposals(accounts) {
  const params = { params: { accounts } };
  const r = await HttpClient.get(
    `${config.api}/accounts/holding-proposals`,
    params
  );
  return r.data;
}

export async function fetchAllocation(id) {
  const r = await HttpClient.get(`${config.api}/accounts/${id}/allocation`);
  return r.data;
}

export async function fetchHasImplementedAccount(id) {
  const r = await HttpClient.get(
    `${config.api}/accounts/${id}/has-implemented`
  );
  return r.data;
}

export function updateInfo(values) {
  return HttpClient.put(`${config.api}/account/${values.id}`, values);
}

export async function fetchLastUpdated(id) {
  const r = await HttpClient.get(`${config.api}/accounts/${id}/last-updated`);
  return r.data;
}

export async function saveManual(id, data) {
  const r = await HttpClient.post(`${config.api}/accounts/${id}/manual`, data);
  return r.data;
}

export async function createReportingGroup(data) {
  const r = await HttpClient.post(
    `${config.api}/accounts/reporting-group`,
    data
  );
  return r.data;
}

export function fetchReportingGroups(household) {
  return (q) =>
    HttpClient.get(`${config.api}/accounts/reporting-groups`, {
      params: { household, q },
    }).then((r) => ({ options: r.data }));
}

export async function fetchMoneyMarketFunds(accounts) {
  const params = { params: { accounts } };
  const r = await HttpClient.get(
    `${config.api}/accounts/money-market-funds`,
    params
  );
  return r.data;
}

export async function fetchMoneyMarketFundsByAccount(id) {
  const r = await HttpClient.get(
    `${config.api}/accounts/${id}/money-market-funds`
  );
  return r.data;
}

export async function fetchMoneyMarketHoldings(id) {
  const r = await HttpClient.get(
    `${config.api}/accounts/${id}/money-market-holdings`
  );
  return r.data;
}

export async function fetchSecurityType(id) {
  const r = await HttpClient.get(`${config.api}/accounts/${id}/security-type`);
  return r.data;
}

export async function fetchMunicipalBonds(id) {
  const r = await HttpClient.get(
    `${config.api}/accounts/${id}/municipal-bonds`
  );
  return r.data;
}

export async function fetchHarvestableLossInfo(accounts) {
  const params = { params: { accounts } };
  const url = `${config.api}/accounts/harvestable-loss-info`;
  const r = await HttpClient.get(url, params);
  return r.data;
}

export async function fetchOrderedAccounts(accounts) {
  const params = { params: { accounts } };
  const url = `${config.api}/accounts/ordered-accounts`;
  const r = await HttpClient.get(url, params);
  return r.data;
}

export function listNewAccounts(
  page = 1,
  status = "all",
  term = null,
  sort = null,
  filter = null
) {
  const params = {
    params: { page, status },
  };
  if (filter) {
    const object = {};
    const keys = Object.keys(filter);
    for (const key of keys) {
      if (
        filter[key].value !== undefined &&
        filter[key].value !== null &&
        filter[key].value !== ""
      ) {
        object[key] = filter[key].value;
      }
    }
    params.params.filter = object;
  }
  if (term) {
    params.params.q = term;
  }
  if (sort) {
    params.params.order = sort.order;
    params.params.direction = sort.direction;
  }
  const url = `${config.api}/accounts/new-accounts`;
  return HttpClient.get(url, params);
}

export function fetchNewTamaracAccount(id) {
  const url = `${config.api}/accounts/${id}/new-account`;
  return HttpClient.get(url);
}

export function setupNewTamaracAccounts() {
  const url = `${config.api}/accounts/setup-new-accounts`;
  return HttpClient.post(url);
}

export async function updateHousehold(id, household) {
  const r = await HttpClient.put(
    `${config.api}/account/${id}/household/${household}`
  );
  return r.data;
}

export async function updateServiceTeam(id, setup) {
  const body = { service_team: id, service_team_setup: setup };
  const r = await HttpClient.put(`${config.api}/service-teams/${id}`, body);
  return r.data;
}

export async function addOwner(id, client) {
  const r = await HttpClient.put(`${config.api}/account/${id}/owner/${client}`);
  return r.data;
}

export async function removeOwner(id, owner) {
  const r = await HttpClient.delete(
    `${config.api}/account/${id}/owner/${owner}`
  );
  return r.data;
}

export async function fetchIncomeSecuritiesHoldings(id) {
  const url = `${config.api}/accounts/${id}/income-securities-holdings`;
  const r = await HttpClient.get(url);
  return r.data;
}

export async function fetchGrowthSecuritiesHoldings(id) {
  const url = `${config.api}/accounts/${id}/growth-securities-holdings`;
  const r = await HttpClient.get(url);
  return r.data;
}

export async function fetchCurrentSecuritiesHoldings(id) {
  const url = `${config.api}/accounts/${id}/current-securities-holdings`;
  const r = await HttpClient.get(url);
  return r.data;
}

export async function fetchHistoricalValues(id, sort = null) {
  const params = { params: {} };
  if (sort) {
    params.params.order = sort.order;
    params.params.direction = sort.direction;
  }
  const r = await HttpClient.get(
    `${config.api}/accounts/${id}/historical-values`,
    params
  );
  return r.data;
}

export async function fetchTransactionsCurrentQuarterManagementFees(id) {
  const r = await HttpClient.get(
    `${config.api}/accounts/${id}/transactions-current-quarter-management-fees`
  );
  return r.data;
}

export async function fetchBillingHistoryPreviousQuarter(id, account_number) {
  const params = { params: { account_number: account_number } };
  const r = await HttpClient.get(
    `${config.api}/accounts/${id}/billing-history-previous-quarter`,
    params
  );
  return r.data;
}

export async function fetchIsLegacySecurities(account, securities) {
  if (!securities || securities.length === 0) {
    return {};
  }
  const r = await HttpClient.get(`${config.api}/accounts/${account}/legacy`, {
    params: { securities },
  });
  return r.data;
}

export async function fetchIsUnassignedSecurities(account, securities) {
  if (!securities || securities.length === 0) {
    return {};
  }
  const r = await HttpClient.get(
    `${config.api}/accounts/${account}/unassigned`,
    { params: { securities } }
  );
  return r.data;
}

export async function hasUnmanagedSecurities(accounts) {
  const r = await HttpClient.get(
    `${config.api}/accounts/has-unmanaged-restrictions`,
    { params: { accounts } }
  );
  return r.data;
}
