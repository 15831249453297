import React from 'react'
import PropTypes from 'prop-types'

const Loading = ({ label, className }) => {
  return <div className={className}><i className="fa fa-spin fa-spinner" /> {label}</div>
}

Loading.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string
}

Loading.defaultProps = {
  label: 'Loading...',
  className: 'text-center'
}

export default Loading
