import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import { INITIALIZED_FIELDS, INITIALIZE_FIELDS } from '../actions/incomeBuilderInventories'

const initialState = {
  ...getInitialState('INCOME_BUILDER_INVENTORIES'),
  formState: INITIALIZED_FIELDS,
  formValues: {}
}

const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('INCOME_BUILDER_INVENTORIES'),
  [INITIALIZED_FIELDS]: (state, action) => {
    return { ...state, formState: INITIALIZED_FIELDS }
  },
  [INITIALIZE_FIELDS]: (state, action) => {
    return { ...state, formState: INITIALIZE_FIELDS, formValues: action.values }
  },
}

export default function incomeBuilderInventories (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
