import config from '../config'
import { getActions, getTypes, getType } from '../helpers/crudActions'
import browserHistory from '../helpers/browserHistory'
import HttpClient from '../helpers/httpclient'
import { getBody } from '../helpers/util'

const model = 'CUSTODIAL_REQUESTS'

export const ACCOUNTS_SAVE = getType('ACCOUNTS_SAVE', model)
export const ACCOUNTS_SAVE_PENDING = getType('ACCOUNTS_SAVE_PENDING', model)
export const ACCOUNTS_SAVE_REJECTED = getType('ACCOUNTS_SAVE_REJECTED', model)
export const ACCOUNTS_SAVE_FULFILLED = getType('ACCOUNTS_SAVE_FULFILLED', model)

export const ACCOUNTS_DELETE = getType('ACCOUNTS_DELETE', model)
export const ACCOUNTS_DELETE_PENDING = getType('ACCOUNTS_DELETE_PENDING', model)
export const ACCOUNTS_DELETE_REJECTED = getType('ACCOUNTS_DELETE_REJECTED', model)
export const ACCOUNTS_DELETE_FULFILLED = getType('ACCOUNTS_DELETE_FULFILLED', model)

export const TRANSFERS_SAVE = getType('TRANSFERS_SAVE', model)
export const TRANSFERS_SAVE_PENDING = getType('TRANSFERS_SAVE_PENDING', model)
export const TRANSFERS_SAVE_REJECTED = getType('TRANSFERS_SAVE_REJECTED', model)
export const TRANSFERS_SAVE_FULFILLED = getType('TRANSFERS_SAVE_FULFILLED', model)

export const TRANSFERS_DELETE = getType('TRANSFERS_DELETE', model)
export const TRANSFERS_DELETE_PENDING = getType('TRANSFERS_DELETE_PENDING', model)
export const TRANSFERS_DELETE_REJECTED = getType('TRANSFERS_DELETE_REJECTED', model)
export const TRANSFERS_DELETE_FULFILLED = getType('TRANSFERS_DELETE_FULFILLED', model)

export const CLIENTS_SAVE = getType('CLIENTS_SAVE', model)
export const CLIENTS_SAVE_PENDING = getType('CLIENTS_SAVE_PENDING', model)
export const CLIENTS_SAVE_REJECTED = getType('CLIENTS_SAVE_REJECTED', model)
export const CLIENTS_SAVE_FULFILLED = getType('CLIENTS_SAVE_FULFILLED', model)

export const BENEFICIARIES_SAVE = getType('BENEFICIARIES_SAVE', model)
export const BENEFICIARIES_SAVE_PENDING = getType('BENEFICIARIES_SAVE_PENDING', model)
export const BENEFICIARIES_SAVE_REJECTED = getType('BENEFICIARIES_SAVE_REJECTED', model)
export const BENEFICIARIES_SAVE_FULFILLED = getType('BENEFICIARIES_SAVE_FULFILLED', model)

export const BENEFICIARIES_DELETE = getType('BENEFICIARIES_DELETE', model)
export const BENEFICIARIES_DELETE_PENDING = getType('BENEFICIARIES_DELETE_PENDING', model)
export const BENEFICIARIES_DELETE_REJECTED = getType('BENEFICIARIES_DELETE_REJECTED', model)
export const BENEFICIARIES_DELETE_FULFILLED = getType('BENEFICIARIES_DELETE_FULFILLED', model)

export const STANDING_INSTRUCTIONS_SAVE = getType('STANDING_INSTRUCTIONS_SAVE', model)
export const STANDING_INSTRUCTIONS_SAVE_PENDING = getType('STANDING_INSTRUCTIONS_SAVE_PENDING', model)
export const STANDING_INSTRUCTIONS_SAVE_REJECTED = getType('STANDING_INSTRUCTIONS_SAVE_REJECTED', model)
export const STANDING_INSTRUCTIONS_SAVE_FULFILLED = getType('STANDING_INSTRUCTIONS_SAVE_FULFILLED', model)

export const STANDING_INSTRUCTIONS_DELETE = getType('STANDING_INSTRUCTIONS_DELETE', model)
export const STANDING_INSTRUCTIONS_DELETE_PENDING = getType('STANDING_INSTRUCTIONS_DELETE_PENDING', model)
export const STANDING_INSTRUCTIONS_DELETE_REJECTED = getType('STANDING_INSTRUCTIONS_DELETE_REJECTED', model)
export const STANDING_INSTRUCTIONS_DELETE_FULFILLED = getType('STANDING_INSTRUCTIONS_DELETE_FULFILLED', model)

export const UPDATE_CUSTODIAN = getType('UPDATE_CUSTODIAN', model)
export const UPDATE_CUSTODIAN_PENDING = getType('UPDATE_CUSTODIAN_PENDING', model)
export const UPDATE_CUSTODIAN_REJECTED = getType('UPDATE_CUSTODIAN_REJECTED', model)
export const UPDATE_CUSTODIAN_FULFILLED = getType('UPDATE_CUSTODIAN_FULFILLED', model)

export const SUBMIT_TICKET = getType('SUBMIT_TICKET', model)
export const SUBMIT_TICKET_PENDING = getType('SUBMIT_TICKET_PENDING', model)
export const SUBMIT_TICKET_REJECTED = getType('SUBMIT_TICKET_REJECTED', model)
export const SUBMIT_TICKET_FULFILLED = getType('SUBMIT_TICKET_FULFILLED', model)

export default {
  ...getActions(model, 'custodial-requests'),
  ...getTypes(model),
  redirect (response) {
    browserHistory.push(`/custodial-request/${response.data.id}/edit`)
    return response
  },
  saveObject (object, type, path) {
    const method = object.id ? 'put' : 'post'
    return {
      type,
      payload: HttpClient[method](`${config.api}/${path}/${object.id || ''}`, object)
    }
  },
  saveObjectWithFile (object, body, type, path) {
    const method = object.id ? 'put' : 'post'
    return {
      type,
      payload: HttpClient[method](`${config.api}/${path}/${object.id || ''}`, body)
    }
  },
  deleteObject (id, crId, type, path) {
    return {
      type,
      payload: HttpClient.delete(`${config.api}/${path}/${id}`, { params: { _custodial_request_id: crId } })
    }
  },
  saveAccount (object) {
    return this.saveObject(object, ACCOUNTS_SAVE, 'custodial-request-accounts')
  },
  deleteAccount (id, crId) {
    return this.deleteObject(id, crId, ACCOUNTS_DELETE, 'custodial-request-accounts')
  },
  saveTransfer (object) {
    const body = getBody(object, [ 'statement', 'name_certification_document' ])
    return this.saveObjectWithFile(object, body, TRANSFERS_SAVE, 'transfers')
  },
  deleteTransfer (id, crId) {
    return this.deleteObject(id, crId, TRANSFERS_DELETE, 'transfers')
  },
  saveClient (object) {
    return this.saveObject(object, CLIENTS_SAVE, 'beneficiaries/client')
  },
  saveBeneficiary (object) {
    return this.saveObject(object, BENEFICIARIES_SAVE, 'beneficiaries')
  },
  deleteBeneficiary (id, crId) {
    return this.deleteObject(id, crId, BENEFICIARIES_DELETE, 'beneficiaries')
  },
  saveStandingInstruction (object) {
    const body = getBody(object, [ 'statement' ])
    return this.saveObjectWithFile(object, body, STANDING_INSTRUCTIONS_SAVE, 'standing-instructions')
  },
  deleteStandingInstruction (id, crId) {
    return this.deleteObject(id, crId, STANDING_INSTRUCTIONS_DELETE, 'standing-instructions')
  },
  updateCustodian (object, custodian) {
    return {
      type: UPDATE_CUSTODIAN,
      payload: HttpClient.put(`${config.api}/custodial-requests/${object.id}/custodian`, { custodian })
    }
  },
  submitTicket (id) {
    return {
      type: SUBMIT_TICKET,
      payload: HttpClient.post(`${config.api}/custodial-requests/${id}/zendesk`)
    }
  },
  ACCOUNTS_SAVE,
  ACCOUNTS_SAVE_PENDING,
  ACCOUNTS_SAVE_REJECTED,
  ACCOUNTS_SAVE_FULFILLED,
  ACCOUNTS_DELETE,
  ACCOUNTS_DELETE_PENDING,
  ACCOUNTS_DELETE_REJECTED,
  ACCOUNTS_DELETE_FULFILLED,
  TRANSFERS_SAVE,
  TRANSFERS_SAVE_PENDING,
  TRANSFERS_SAVE_REJECTED,
  TRANSFERS_SAVE_FULFILLED,
  TRANSFERS_DELETE,
  TRANSFERS_DELETE_PENDING,
  TRANSFERS_DELETE_REJECTED,
  TRANSFERS_DELETE_FULFILLED,
  CLIENTS_SAVE,
  CLIENTS_SAVE_PENDING,
  CLIENTS_SAVE_REJECTED,
  CLIENTS_SAVE_FULFILLED,
  BENEFICIARIES_SAVE,
  BENEFICIARIES_SAVE_PENDING,
  BENEFICIARIES_SAVE_REJECTED,
  BENEFICIARIES_SAVE_FULFILLED,
  BENEFICIARIES_DELETE,
  BENEFICIARIES_DELETE_PENDING,
  BENEFICIARIES_DELETE_REJECTED,
  BENEFICIARIES_DELETE_FULFILLED,
  STANDING_INSTRUCTIONS_SAVE_PENDING,
  STANDING_INSTRUCTIONS_SAVE_REJECTED,
  STANDING_INSTRUCTIONS_SAVE_FULFILLED,
  STANDING_INSTRUCTIONS_DELETE_PENDING,
  STANDING_INSTRUCTIONS_DELETE_REJECTED,
  STANDING_INSTRUCTIONS_DELETE_FULFILLED,
  SUBMIT_TICKET_PENDING,
  SUBMIT_TICKET_REJECTED,
  SUBMIT_TICKET_FULFILLED
}

export function getAccounts (id) {
  const params = { params: { id } }
  return HttpClient.get(`${config.api}/custodial-requests/accounts`, params)
}

const getOption = acc => ({ value: acc.id, label: `${acc.account_name} (${acc.account_number})` })
const getOptions = accs => ({ options: accs.map(getOption) })

export function fetchAccounts (id) {
  return input => getAccounts(id).then(r => r.data).then(getOptions)
}

export function getCustodialRequests (input) {
  const params = { params: { q: input } }
  return HttpClient.get(`${config.api}/custodial-requests/options`, params)
}

export function fetchCustodialRequests (input) {
  return getCustodialRequests(input).then(r => r.data)
}

export function getCustodialRequestAccounts (input, id) {
  const params = { params: { q: input, custodial_request: id } }
  return HttpClient.get(`${config.api}/custodial-request-accounts/options`, params)
}

export function fetchCustodialRequestAccounts (id) {
  return input => getCustodialRequestAccounts(input, id).then(r => r.data)
}

export function fetchForms (id, cra) {
  const params = { params: { cra: cra } }
  return HttpClient.get(`${config.api}/custodial-requests/${id}/forms`, params).then(r => r.data)
}
