import React, { Component, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import Select, { Creatable } from 'react-select'
import Toggle from 'react-toggle'
import AceEditor from 'react-ace'
import MultiSelect from './multi-select'
import CurrencyInput from './currency'
import NumericInput from 'react-numeric-input'
import Dropzone from "react-dropzone";
import config from "../../config";
import Loading from "../app/Loading";
import Popover from "../app/Popover";
import DataTableInput from "./data-table-input";
import DatePickerInput from "./datepicker-input";
import ColorPicker from "./color-picker";
import { number, arrayIsEqual } from "../../helpers/util";
import { getToken } from "../../actions/auth";

import "brace/mode/javascript";
import "brace/theme/github";
import "rc-time-picker/assets/index.css";

function onChange(field) {
  return (e) => {
    if (field.maxLength && e.target.value.length > parseInt(field.maxLength)) {
      return;
    }
    field.input.onChange(e.target.value);
    field.onInputChange && field.onInputChange(e.target.value);
  };
}

function onCheckboxChange(field) {
  return (e) => {
    const checked = e.target.checked;
    field.input.onChange(checked);
    field.onInputChange && setImmediate(() => field.onInputChange(checked));
  };
}

function onSelectChange(field) {
  return (option) => {
    const val = option ? option.value : null;
    field.input.onChange(val);
    field.onInputChange && field.onInputChange(option);
  };
}

function onMultiSelectChange(field) {
  return (options) => {
    const values = options
      ? options.map((o) => (typeof o === "object" && o !== null ? o.value : o))
      : [];
    field.input.onChange(values);
    field.onInputChange && field.onInputChange(options);
  };
}

function onTagsChange(field) {
  return (options) => {
    const values = options || [];
    field.input.onChange(values);
    field.onInputChange && field.onInputChange(options);
  };
}

function onCurrencyChange(field) {
  return (val) => {
    field.input.onChange(val);
    field.onInputChange && field.onInputChange(val);
  };
}

function onDateChange(field) {
  return (val) => {
    const date = moment.isMoment(val) ? val.format("MM/DD/YYYY") : val;
    field.input.onChange(date);
    field.onInputChange && setImmediate(() => field.onInputChange(date));
  };
}

function onPercentageChange(field, precision) {
  return (valueAsNumber, valueAsString) => {
    if (valueAsNumber === null) {
      return;
    }
    const str = valueAsNumber.toString().split(".");
    const number =
      str.length > 1 ? `${str[0]}.${str[1].slice(0, precision)}` : str[0];
    const rounded = Number(number);
    field.input.onChange(rounded);
    field.onInputChange && field.onInputChange(rounded);
  };
}

function onColorPickerChange(field) {
  return (value) => {
    field.input.onChange(value);
    field.onInputChange && field.onInputChange(value);
  };
}

export function InputField(field) {
  const popover = field.popoverContent && (
    <Popover content={field.popoverContent} />
  );
  const attrs = ["maxLength", "pattern", "inputMode"];
  attrs.forEach((attr) => {
    if (field[attr]) {
      field.input[attr] = field[attr];
    }
  });
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      {!field.hideLabel && (
        <label className="control-label">
          {field.placeholder} {popover}
        </label>
      )}
      <input
        {...field.input}
        className={field.className}
        placeholder={field.inputPlaceholder || field.placeholder}
        type={field.type}
        onChange={onChange(field)}
        disabled={field.disabled}
        ref={field.refName}
      />
      {field.helpBlock && <p className="help-block">{field.helpBlock}</p>}
      {field.meta.touched && field.meta.error && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

export function PasswordField(field) {
  const [hidden, setHidden] = useState(true);
  const toggleShow = (e) => {
    setHidden(!hidden);
  };
  const popover = field.popoverContent && (
    <Popover content={field.popoverContent} />
  );
  const attrs = ["maxLength", "pattern", "inputMode"];
  attrs.forEach((attr) => {
    if (field[attr]) {
      field.input[attr] = field[attr];
    }
  });
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      {!field.hideLabel && (
        <label className="control-label">
          {field.placeholder} {popover}
        </label>
      )}
      <div class="input-group">
        <div class="form-wrap">
          <input
            {...field.input}
            className={field.className}
            placeholder={field.inputPlaceholder || field.placeholder}
            type={hidden ? "password" : field.type}
            onChange={onChange(field)}
            disabled={field.disabled}
            ref={field.refName}
          />
        </div>
        <span class="input-group-btn">
          <button
            onClick={toggleShow}
            class="btn waves-effect waves-light"
            type="button"
          >
            <i class={`fa fa-${hidden ? "eye" : "eye-slash"}`} />
          </button>
        </span>
      </div>
      {field.helpBlock && <p className="help-block">{field.helpBlock}</p>}
      {field.meta.touched && field.meta.error && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

export function ToggleField(field) {
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      <label className="toggle-label">
        <Toggle
          defaultChecked={!!field.input.value}
          onChange={onCheckboxChange(field)}
          disabled={field.disabled}
        />
        <span>{field.placeholder}</span>
      </label>
      {field.meta.touched && field.meta.error && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

export function Hidden(field) {
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      <input {...field.input} type="hidden" onChange={onChange(field)} />
      {field.meta.touched && field.meta.error && !field.hideErrorMessage && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

export function Alert(field) {
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      <input {...field.input} type="hidden" onChange={onChange(field)} />
      {field.meta.touched && field.meta.error && (
        <div className="alert alert-danger">{field.meta.error}</div>
      )}
    </div>
  );
}

export class CurrencyField extends Component {
  render() {
    const field = this.props;
    const popover = field.popoverContent && (
      <Popover content={field.popoverContent} />
    );
    if (field.input.value === 0) {
      field.input.value = "0.00";
    }
    return (
      <div
        className={
          field.meta.touched && field.meta.error ? "has-error has-danger" : null
        }
      >
        {!field.hideLabel && (
          <label className="control-label">
            {field.placeholder} {popover}
          </label>
        )}
        <CurrencyInput
          {...field.input}
          value={field.input.value}
          className={field.className}
          placeholder={field.inputPlaceholder || field.placeholder}
          allowBlank={field.allowBlank}
          type={field.type}
          onChange={onCurrencyChange(field)}
          disabled={field.disabled}
        />
        {field.meta.touched && field.meta.error && (
          <div className="help-block with-errors">{field.meta.error}</div>
        )}
      </div>
    );
  }
}

export function DataTableField(field) {
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      {!field.hideLabel && (
        <label className="control-label">{field.placeholder}</label>
      )}
      <DataTableInput
        {...field.input}
        className={field.className}
        disabled={field.disabled}
        mutualFunds={field.mutualFunds}
        hideShares={field.hideShares}
        onChange={(val) => {
          field.input.onChange(val);
          field.onInputChange && field.onInputChange(val);
        }}
      />
      {field.meta.touched && field.meta.error && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

function formatPercentage(num) {
  return num + "%";
}

function checkIfDecimal(e) {
  if (e.key === "." && e.target.value === "" && e.target.selectionStart === 0) {
    e.target.value = "0.00";
    e.target.setSelectionRange(1, 1);
  }
}

export function PercentageField(field) {
  if (field.inputValue) {
    field.input.value = field.inputValue;
  }
  const precision = field.precision || 2;
  const popover = field.popoverContent && (
    <Popover content={field.popoverContent} placement={field.placement} />
  );
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      {!field.hideLabel && (
        <label className="control-label">
          {field.placeholder} {popover}
        </label>
      )}
      <NumericInput
        {...field.input}
        className={field.className}
        placeholder={field.inputPlaceholder || field.placeholder}
        type={field.type}
        onChange={onPercentageChange(field, precision)}
        min={field.min || 0}
        max={field.max || 100}
        precision={precision}
        format={formatPercentage}
        onKeyPress={checkIfDecimal}
        disabled={field.disabled}
      />
      {field.meta.touched && field.meta.error && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

export function TextField(field) {
  const popover = field.popoverContent && (
    <Popover content={field.popoverContent} placement={field.placement} />
  );
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      {!field.hideLabel && (
        <label className="control-label">
          {field.placeholder} {popover}
        </label>
      )}
      <textarea
        {...field.input}
        className={field.className}
        placeholder={field.inputPlaceholder || field.placeholder}
        rows={field.rows}
        onChange={onChange(field)}
        style={field.style}
        readOnly={field.readOnly}
        onPaste={field.onPaste}
        onKeyPress={field.onKeyPress}
        disabled={field.disabled}
      />
      {field.meta.touched && field.meta.error && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

export function CodeField(field) {
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      {!field.hideLabel && (
        <label className="control-label">{field.placeholder}</label>
      )}
      <AceEditor
        {...field.input}
        readOnly={field.readOnly}
        width="100%"
        mode={field.mode || "javascript"}
        theme="github"
        onBlur={() => field.input.onBlur(field.input.value)}
      />
      {field.meta.touched && field.meta.error && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

export class Checkbox extends Component {
  render() {
    const field = this.props;
    if (
      (field.inputValue && field.input.value !== false) ||
      field.input.value
    ) {
      field.input.checked = true;
    } else {
      field.input.checked = false;
    }
    return (
      <div
        className={
          field.meta.touched && field.meta.error ? "has-error has-danger" : null
        }
      >
        <label className="control control-checkbox">
          {!field.hideLabel ? field.placeholder : null}
          <input
            type="checkbox"
            {...field.input}
            disabled={field.disabled}
            onChange={onCheckboxChange(field)}
            onBlur={() => field.input.onBlur(field.input.value)}
            value={field.input.value || false}
          />
          <span className="control_indicator" />
        </label>
        {field.meta.touched && field.meta.error && (
          <div className="help-block with-errors">{field.meta.error}</div>
        )}
      </div>
    );
  }
}

export class MultiCheckbox extends Component {
  onBlur = () => {
    const input = this.props.input;
    input.onBlur(input.value);
  };

  onCheckboxChange = (opt, index) => (e) => {
    const input = this.props.input;
    const checked = e.target.checked;
    const value = { ...input.value };
    value[opt.value.id] = checked;
    input.onChange(value);
  };

  render() {
    const field = this.props;
    return (
      <div
        className={
          field.meta.touched && field.meta.error ? "has-error has-danger" : null
        }
      >
        {!field.hideLabel && (
          <label className="control-label">{field.placeholder}</label>
        )}
        <div className="row">
          {field.options.map((opt, i) => (
            <div className="col-sm-4" key={i}>
              <div className="form-group form-material">
                <label className="control control-checkbox">
                  {opt.label}
                  <input
                    type="checkbox"
                    disabled={field.disabled}
                    onChange={this.onCheckboxChange(opt, i)}
                    onBlur={this.onBlur}
                    checked={!!field.input.value[opt.value.id]}
                  />
                  <span className="control_indicator" />
                </label>
              </div>
            </div>
          ))}
        </div>
        {field.meta.touched && field.meta.error && (
          <div className="help-block with-errors">{field.meta.error}</div>
        )}
      </div>
    );
  }
}

export function DatePickerField(field) {
  const {
    minDate,
    maxDate,
    input: { value },
  } = field;
  if (minDate) {
    field.input.minDate = minDate;
  }
  if (maxDate) {
    field.input.maxDate = maxDate;
  }
  if (value && (moment.isMoment(value) || typeof value === "string")) {
    field.input.selected = moment.utc(value);
    delete field.input.value;
  }
  if (field.openToDate) {
    field.input.openToDate = field.openToDate;
  }
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      {!field.hideLabel && (
        <label className="control-label">{field.placeholder}</label>
      )}
      <div>
        <DatePicker
          {...field.input}
          className={`${field.className} datepicker`}
          onChange={onDateChange(field)}
          disabled={field.disabled}
          placeholder={field.placeholder}
          dateFormat="MM/DD/YYYY"
          peekNextMonth
          showMonthDropdown
          showYearDropdown
          dropdownMode="select"
          customInput={<DatePickerInput minDate={minDate} maxDate={maxDate} />}
        />
      </div>
      {field.helpBlock && <p className="help-block">{field.helpBlock}</p>}
      {field.meta.touched && field.meta.error && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

const fmt = (v) => `0${v}`.slice(-2);
const getOpts = (l) =>
  Array(l)
    .fill(0)
    .map((_, i) => {
      const value = fmt(i);
      return { label: value, value };
    });

const hourOpts = getOpts(24);
const minuteOpts = getOpts(60);

export function TimePickerField(field) {
  const [value, setValue] = useState(field.input.value);
  const [h, m] = value ? value.split(":") : [0, 0];
  const [hour, setHour] = useState(
    h ? hourOpts.find((o) => o.value === h) : null
  );
  const [minute, setMinute] = useState(
    m ? minuteOpts.find((o) => o.value === m) : null
  );
  const handleChange = (h, m) => {
    setHour(h);
    setMinute(m);
    const v = h && m ? `${h.value}:${m.value}` : null;
    setValue(v);
    field.input.onChange(v);
  };
  const handleHour = (o) => handleChange(o, minute);
  const handleMinute = (o) => handleChange(hour, o);
  useEffect(() => {
    if (!field.input.value) {
      setValue(null);
      setHour(null);
      setMinute(null);
    }
  }, [field.input.value, field.disabled]);
  return (
    <div
      className={
        field.meta.touched && field.meta.error ? "has-error has-danger" : null
      }
    >
      {!field.hideLabel && (
        <label className="control-label">{field.placeholder}</label>
      )}
      <div className="row">
        <div className="col-sm-1">
          <Select
            placeholder="Hour"
            options={hourOpts}
            value={hour}
            onChange={handleHour}
            disabled={field.disabled}
          />
        </div>
        <div className="col-sm-1">
          <Select
            placeholder="Minute"
            options={minuteOpts}
            value={minute}
            onChange={handleMinute}
            disabled={field.disabled}
          />
        </div>
      </div>
      {field.helpBlock && <p className="help-block">{field.helpBlock}</p>}
      {field.meta.touched && field.meta.error && (
        <div className="help-block with-errors">{field.meta.error}</div>
      )}
    </div>
  );
}

export class SelectField extends Component {
  render () {
    const field = this.props
    const labelKey = field.labelKey || 'name'
    const valueKey = field.valueKey || 'id'
    const popover = field.popoverContent && <Popover content={field.popoverContent} width={field.popoverWidth} />
    return (
      <div className={field.meta.touched && field.meta.error ? 'has-error has-danger' : null}>
        {!field.hideLabel && <label className="control-label">{field.placeholder} {popover}</label>}
        <Select {...field.input} placeholder={field.prompt === false ? null : field.placeholder} className={field.className}
          onChange={onSelectChange(field)} searchable={field.searchable !== undefined ? field.searchable : true}
          options={field.options.map(o => ({ value: o[valueKey], label: o[labelKey], disabled: o.disabled }))}
          onBlur={() => {
            if (field.disableBlur) {
              return
            }
            field.input.onBlur(field.input.value)
          }}
          filterOption={field.input.filterOption} disabled={field.disabled}
          ref={field.refName} clearable={field.clearable || false} />
        {field.meta.touched && field.meta.error && <div className="help-block with-errors">{field.meta.error}</div>}
      </div>
    )
  }
}

export function TagsFields (field) {
  return (
    <div className={field.meta.touched && field.meta.error ? 'has-error has-danger' : null}>
      {!field.hideLabel && <label className="control-label">{field.placeholder}</label>}
      <Creatable {...field.input} multi={field.multi || true}
        value={field.input.value}
        placeholder={field.prompt === false ? null : field.placeholder}
        className={field.className} onChange={onTagsChange(field)}
        searchable={field.searchable !== undefined ? field.searchable : true}
        onBlur={() => field.input.onBlur(field.input.value)}
        ref={field.refName} clearable={field.clearable || false} disabled={field.disabled} />
      {field.meta.touched && field.meta.error && <div className="help-block with-errors">{field.meta.error}</div>}
    </div>
  )
}

export function CreatableSelect (field) {
  return (
    <div className={field.meta.touched && field.meta.error ? 'has-error has-danger' : null}>
      {!field.hideLabel && <label className="control-label">{field.placeholder}</label>}
      <Creatable {...field.input} multi={false}
        value={field.input.value} options={field.options}
        placeholder={field.prompt === false ? null : field.placeholder}
        className={field.className} onChange={onSelectChange(field)}
        searchable={field.searchable !== undefined ? field.searchable : true}
        onBlur={() => field.input.onBlur(field.input.value)}
        ref={field.refName} clearable={field.clearable || false} disabled={field.disabled} />
      {field.meta.touched && field.meta.error && <div className="help-block with-errors">{field.meta.error}</div>}
    </div>
  )
}

export function AsyncSelect (field) {
  const popover = field.popoverContent && <Popover content={field.popoverContent} width={field.popoverWidth} />
  return (
    <div className={field.meta.touched && field.meta.error ? 'has-error has-danger' : null}>
      {!field.hideLabel && <label className="control-label">{field.placeholder} {popover}</label>}
      <Select.Async {...field.input} placeholder={field.prompt === false ? null : field.placeholder} className={field.className}
        onChange={onSelectChange(field)} disabled={field.disabled}
        loadOptions={field.loadOptions} cache={false} ref={field.refName}
        onBlur={() => field.input.onBlur(field.input.value)} />
      {field.meta.touched && field.meta.error && <div className="help-block with-errors">{field.meta.error}</div>}
    </div>
  )
}

export function AsyncCreatableSelect (field) {
  return (
    <div className={field.meta.touched && field.meta.error ? 'has-error has-danger' : null}>
      {!field.hideLabel && <label className="control-label">{field.placeholder}</label>}
      <Select.AsyncCreatable {...field.input} placeholder={field.prompt === false ? null : field.placeholder} className={field.className}
        onChange={onSelectChange(field)} disabled={field.disabled}
        loadOptions={field.loadOptions} cache={false} ref={field.refName}
        onBlur={() => field.input.onBlur(field.input.value)}
        onInputChange={input => field.maxLength ? (input || '').slice(0, field.maxLength) : input} />
      {field.meta.touched && field.meta.error && <div className="help-block with-errors">{field.meta.error}</div>}
    </div>
  )
}

export class AsyncSelectField extends Component {
  constructor (props) {
    super(props)
    this.state = {
      loading: true,
      options: []
    }
    this.loadOptions = props.loadParams ? props.loadOptions(...props.loadParams) : props.loadOptions
  }

  componentDidMount () {
    this._isMounted = true
    this.load()
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  componentWillReceiveProps (nextProps) {
    let reloadOptions = false
    this.loadOptions = nextProps.loadOptions
    if (nextProps.loadParams) {
      this.loadOptions = nextProps.loadOptions(...nextProps.loadParams)
      reloadOptions = !arrayIsEqual(this.props.loadParams, nextProps.loadParams)
    }
    if (reloadOptions ||
      (this.props.reloadOptionsOnChange && this.props.input.value !== nextProps.input.value)) {
      this.load('')
    }
  }

  load = value => this.loadOptions(value).then(result => {
    this._isMounted && this.setState({ loading: false, options: result.options })
  })

  onInputChange = value => {
    if (value !== '') {
      this.load(value)
    }
    return value
  }

  onChange = option => {
    const field = this.props
    const value = option ? option.value : null
    field.input.onChange(value)
    field.onInputChange && field.onInputChange(option)
    if (value === null) {
      this.load('')
    }
  }

  render () {
    const field = this.props
    const { options, loading } = this.state
    const placeholder = field.prompt === false ? null : field.placeholder
    const popover = field.popoverContent && <Popover content={field.popoverContent} />
    return (
      <div className={field.meta.touched && field.meta.error ? 'has-error has-danger' : null}>
        {!field.hideLabel && <label className="control-label">{field.placeholder} {popover}</label>}
        <Select {...field.input} placeholder={placeholder} className={field.className}
          options={options} isLoading={loading} disabled={field.disabled}
          ref={field.refName} cache={false}
          onChange={this.onChange} onInputChange={this.onInputChange}
          onBlur={() => field.input.onBlur(field.input.value)} />
        {field.meta.touched && field.meta.error && <div className="help-block with-errors">{field.meta.error}</div>}
      </div>
    )
  }
}

export function MultiSelectField (field) {
  const labelKey = field.labelKey || 'name'
  const valueKey = field.valueKey || 'id'
  return (
    <div className={field.meta.touched && field.meta.error ? 'has-error has-danger' : null}>
      {!field.hideLabel && <label className="control-label">{field.placeholder}</label>}
      <Select {...field.input} multi placeholder={field.prompt === false ? null : field.placeholder} className={field.className}
        onChange={onMultiSelectChange(field)}
        options={field.options.map(o => ({ value: o[valueKey], label: o[labelKey], disabled: o.disabled }))}
        onBlur={() => field.input.onBlur(field.input.value)} />
      {field.meta.touched && field.meta.error && <div className="help-block with-errors">{field.meta.error}</div>}
    </div>
  )
}

export function AsyncMultiSelectField (field) {
  return (
    <div className={field.meta.touched && field.meta.error ? 'has-error has-danger' : null}>
      {!field.hideLabel && <label className="control-label">{field.placeholder}</label>}
      <MultiSelect
        onChange={onMultiSelectChange(field)}
        loadOptions={field.loadOptions}
        selectedOptions={field.selectedOptions || (typeof field.input.value === 'string' ? field.input.value.split() : field.input.value)}
        ref={field.refName}
      />
      {field.meta.touched && field.meta.error && <div className="help-block with-errors">{field.meta.error}</div>}
    </div>
  )
}

function emptyPath (path) {
  return !path || path === '' || path === 'null' || path instanceof Array
}

function download (path, label) {
  if (emptyPath(path)) {
    return null
  }
  const token = getToken()
  const url = `${config.api}/documents/s3?token=${token}&file=${path}`
  return (
    <a className="btn-download" href={url} target="_blank" rel="noopener noreferrer">{label || 'Download file'}</a>
  )
}

function preview (path) {
  if (emptyPath(path)) {
    return null
  }
  const token = getToken()
  return <img className="img-responsive" alt="" src={`${config.api}/documents/images/show/${path}?token=${token}`} />
}

export function DropzoneInput (field) {
  const files = field.input.value
  const formControlLabel = files && Array.isArray(files)
    ? files.map(file => file.name).join(', ') : ''
  const wrapperClass = field.meta.touched && field.meta.error
    ? 'has-error has-danger dropzone-wrapper' : 'dropzone-wrapper'
  const label = field.uploading ? <Loading label="Uploading..." /> : <span>Upload</span>
  return (
    <div className={wrapperClass}>
      <Dropzone
        multiple={field.multiple || false}
        name={field.name}
        onDrop={acceptedFiles => {
          console.log(acceptedFiles)
          field.input.onChange(acceptedFiles)
        }}>
        {({getRootProps, getInputProps}) => (
          <div>
            {!field.hideLabel && <label className="control-label">{field.placeholder}</label>}
            <div className="input-group input-group-file" {...getRootProps()}>
              <span className="fileUpload btn btn-info">
                {label}
              </span>
              <input readOnly type="text" className="form-control" value={formControlLabel} />
              <input className="form-control" readOnly {...getInputProps()} />
            </div>
          </div>
        )}
      </Dropzone>
      {field.meta.touched && field.meta.error && <span className="error">{field.meta.error}</span>}
      {field.downloadButton && download(files, field.downloadLabel)}
      {field.preview && preview(files)}
    </div>
  )
}

export function ColorPickerField (field) {
  return (
    <div className={field.meta.touched && field.meta.error ? 'has-error has-danger' : null}>
      {!field.hideLabel && <label className="control-label">{field.placeholder}</label>}
      <ColorPicker value={field.input.value} onChange={onColorPickerChange(field)} />
      {field.helpBlock && <p className="help-block">{field.helpBlock}</p>}
      {field.meta.touched && field.meta.error && <div className="help-block with-errors">{field.meta.error}</div>}
    </div>
  )
}

export function largerThan (min, message) {
  return value => min > number(value) ? message : undefined
}

export function lessThan (max, message) {
  return value => number(value) > max ? message || `Maximum value is ${max}` : undefined
}

export function requiredIf (isRequired) {
  return value => isRequired ? (value ? undefined : 'Required') : undefined
}

export function maxLength (max) {
  return value => value && value.length > max ? `Must be ${max} characters` : undefined
}

export function minLength (min) {
  return value => value && value.length < min ? `Must be ${min} characters` : undefined
}

export function email (value) {
  return value && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value)
    ? 'Invalid email address'
    : undefined
}

export function numbers (value) {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  return onlyNums
}

export function phone (value) {
  if (!value) {
    return value
  }
  const onlyNums = value.replace(/[^\d]/g, '')
  if (onlyNums.length <= 3) {
    return onlyNums
  }
  if (onlyNums.length <= 7) {
    return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`
  }
  return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`
}
