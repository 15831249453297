import config from '../config'
import HttpClient from '../helpers/httpclient'

export const TYPES_INVALID = 'TYPES_INVALID'
export const TYPES_FETCHING = 'TYPES_FETCHING'
export const TYPES_FETCHED = 'TYPES_FETCHED'
export const TYPES_FETCH_FAILED = 'TYPES_FETCH_FAILED'

export function fetchTypes (type) {
  return (dispatch) => {
    dispatch({ type: TYPES_FETCHING })

    return HttpClient.get(`${config.api}/types/${type}`)
      .then((response) => {
        return response.data
      })
      .then(
        result => dispatch({ type: TYPES_FETCHED, result }),
        error => dispatch({ type: TYPES_FETCH_FAILED, error })
      )
  }
}

export function fetchTypesOptions (type) {
  const getOption = t => ({ value: t.id, label: t.name })
  const getOptions = types => ({ options: types.map(getOption) })
  return (input) => {
    return HttpClient.get(`${config.api}/types/${type}`).then((r) => r.data).then(getOptions)
  }
}
