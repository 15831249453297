import React, { Component } from 'react'
import { getUser } from '../../actions/auth'
import browserHistory from '../../helpers/browserHistory'

export default function Authorize (WrappedComponent, authorize, action) {
  return class WithAuthorization extends Component {

    state = {
      authorized: false
    }

    componentWillMount () {
      const user = getUser()
      if (!user) {
        browserHistory.push('/signin')
        return
      }

      const rr = { authorize, action }
      const permissions = user.Role.permissions || {}
      const result = permissions[rr.authorize] && permissions[rr.authorize][rr.action]

      if (!result) {
        browserHistory.push('/not-authorized')
        this.setState({ authorized: false })
      }
      this.setState({ authorized: true })
    }

    render () {
      if (this.state.authorized) {
        return <WrappedComponent {...this.props} />
      }
      return null
    }
  }
}
