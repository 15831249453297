import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import {
  OBJECT_SAVE_ALL_PENDING,
  OBJECT_SAVE_ALL_FULFILLED,
  OBJECT_SAVE_ALL_REJECTED
} from '../actions/proposalInventories'

const initialState = getInitialState('PROPOSAL_INVENTORIES')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('PROPOSAL_INVENTORIES'),
  [OBJECT_SAVE_ALL_PENDING]: (state, action) => {
    return { ...state, readyState: OBJECT_SAVE_ALL_PENDING }
  },
  [OBJECT_SAVE_ALL_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: OBJECT_SAVE_ALL_REJECTED, error: action.error, message }
  },
  [OBJECT_SAVE_ALL_FULFILLED]: (state, action) => {
    return { ...state, readyState: OBJECT_SAVE_ALL_FULFILLED, list: action.payload.data }
  },
}

export default function proposalInventories (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
