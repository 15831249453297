import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import actions, { TOGGLE_HAVERSTING_LOSS_FULFILLED, TOGGLE_AUTO_MMF_FULFILLED } from '../actions/accountArs'

const initialState = getInitialState('ACCOUNT_ARS')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('ACCOUNT_ARS'),
  [TOGGLE_HAVERSTING_LOSS_FULFILLED]: (state, action) => {
    const list = { ...state.list }
    const object = action.payload.data
    if (action.meta.updateList) {
      const index = list.objects.findIndex(o => o.id === object.id)
      list.objects[index] = object
    }
    return { ...state, readyState: TOGGLE_HAVERSTING_LOSS_FULFILLED, list, object }
  },
  [actions.OBJECT_TOGGLE_STATUS_FULFILLED]: (state, action) => {
    const list = { ...state.list };
    const object = action.payload.data;
    if (action.meta.updateList) {
      const index = list.objects.findIndex(o => o.id === object.id);
      list.objects[index] = object;
    }
    setImmediate(() => {
      const detail = { id: object.account_id, automated_investing: object.automated_investing };
      const event = new CustomEvent(`asset:update-account-${object.account_id}`, { detail });
      document.body.dispatchEvent(event);
    });
    return { ...state, readyState: actions.OBJECT_TOGGLE_STATUS_FULFILLED, error: false, list, object };
  },
  [TOGGLE_AUTO_MMF_FULFILLED]: (state, action) => {
    const list = { ...state.list };
    const object = action.payload.data;
    const index = list.objects.findIndex(o => o.id === object.id);
    object.model_name_count = list.objects[index].model_name_count
    object.harvestable_losses = list.objects[index].harvestable_losses
    if (action.meta.updateList) {
      list.objects[index] = object
    }
    setImmediate(() => {
      const detail = { id: object.account_id, automated_mmf: object.automated_mmf }
      const event = new CustomEvent(`asset:update-account-${object.account_id}`, { detail })
      document.body.dispatchEvent(event);
    })
    return { ...state, readyState: TOGGLE_AUTO_MMF_FULFILLED, error: false, list, object }
  }
}

export default function accountArs (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
