import {
  AUTH_AUTHENTICATING,
  AUTH_SUCCESS,
  AUTH_FAILED,
  AUTH_SIGNING_OUT,
  AUTH_SIGNING_OUT_SUCCESS,
  AUTH_CHANGING_PASSWORD,
  AUTH_CHANGE_PASSWORD_SUCCESS,
  AUTH_CHANGE_PASSWORD_FAILED,
  AUTH_RESETTING_PASSWORD,
  AUTH_RESET_PASSWORD_SUCCESS,
  AUTH_RESET_PASSWORD_FAILED,
  AUTH_IMPERSONATING,
  AUTH_IMPERSONATE_SUCCESS,
  AUTH_IMPERSONATE_FAILED,
  AUTH_ENDIMPERSONATING,
  AUTH_ENDIMPERSONATING_SUCCESS,
  AUTH_ENDIMPERSONATING_FAILED
} from '../actions/auth'

export default function auth (state = {}, action) {
  switch (action.type) {
    case AUTH_AUTHENTICATING:
      return Object.assign({}, state, {
        readyState: AUTH_AUTHENTICATING
      })
    case AUTH_FAILED:
      return Object.assign({}, state, {
        readyState: AUTH_FAILED,
        error: action.error
      })
    case AUTH_SUCCESS:
      return Object.assign({}, state, {
        readyState: AUTH_SUCCESS,
        auth: action.result
      })
    case AUTH_SIGNING_OUT:
      return Object.assign({}, state, {
        readyState: AUTH_SIGNING_OUT
      })
    case AUTH_SIGNING_OUT_SUCCESS:
      return Object.assign({}, state, {
        readyState: AUTH_SIGNING_OUT_SUCCESS
      })
    case AUTH_CHANGING_PASSWORD:
      return Object.assign({}, state, {
        readyState: AUTH_CHANGING_PASSWORD
      })
    case AUTH_CHANGE_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        readyState: AUTH_CHANGE_PASSWORD_SUCCESS,
        auth: action.result
      })
    case AUTH_CHANGE_PASSWORD_FAILED:
      return Object.assign({}, state, {
        readyState: AUTH_CHANGE_PASSWORD_FAILED,
        error: action.error
      })
    case AUTH_RESETTING_PASSWORD:
      return Object.assign({}, state, {
        readyState: AUTH_RESETTING_PASSWORD
      })
    case AUTH_RESET_PASSWORD_SUCCESS:
      return Object.assign({}, state, {
        readyState: AUTH_RESET_PASSWORD_SUCCESS
      })
    case AUTH_RESET_PASSWORD_FAILED:
      return Object.assign({}, state, {
        readyState: AUTH_RESET_PASSWORD_FAILED,
        error: action.error
      })
    case AUTH_IMPERSONATING:
      return Object.assign({}, state, {
        readyState: AUTH_IMPERSONATING
      })
    case AUTH_IMPERSONATE_SUCCESS:
      return Object.assign({}, state, {
        readyState: AUTH_IMPERSONATE_SUCCESS
      })
    case AUTH_IMPERSONATE_FAILED:
      return Object.assign({}, state, {
        readyState: AUTH_IMPERSONATE_FAILED,
        error: action.error
      })
    case AUTH_ENDIMPERSONATING:
      return Object.assign({}, state, {
        readyState: AUTH_ENDIMPERSONATING
      })
    case AUTH_ENDIMPERSONATING_SUCCESS:
      return Object.assign({}, state, {
        readyState: AUTH_ENDIMPERSONATING_SUCCESS
      })
    case AUTH_ENDIMPERSONATING_FAILED:
      return Object.assign({}, state, {
        readyState: AUTH_ENDIMPERSONATING_FAILED,
        error: action.error
      })
    default:
      return state
  }
}