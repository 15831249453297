import {
  ALERTS_INVALID,
  ALERTS_FETCHING,
  ALERTS_FETCHED,
  ALERTS_FETCH_FAILED
} from '../actions/alerts'

export default function alerts (state = {
  readyState: ALERTS_INVALID,
  list: {
    alerts: [],
    pageSize: 50,
    currentPage: 1,
    count: 0
  }
}, action) {
  switch (action.type) {
    case ALERTS_FETCHING:
      return Object.assign({}, state, {
        readyState: ALERTS_FETCHING
      })
    case ALERTS_FETCH_FAILED:
      return Object.assign({}, state, {
        readyState: ALERTS_FETCH_FAILED,
        error: action.error
      })
    case ALERTS_FETCHED:
      return Object.assign({}, state, {
        readyState: ALERTS_FETCHED,
        list: action.result
      })
    default:
      return state
  }
}
