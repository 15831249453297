import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import DefaultMenu from './DefaultMenu'
import LinkWithRole from './LinkWithRole'
import WithRole from './WithRole'

const renderMenu = (impersonated, user) => {
  return (
    <div className="utmenucontainer">
      <div className="overlapblackbg" />
      <a id="wsnavtoggle" className="animated-arrow" href="# "><span /></a>
      <nav className="utmenu">
        <DefaultMenu user={user} />
      </nav>
    </div>
  )
}

const renderUserMenu = (impersonated, user) => {
  const company = user.Company
  return (
    <ul className="nav navbar-nav navbar-toolbar navbar-right">
      <li className="dropdown">
        <a className="navbar-avatar dropdown-toggle waves-effect waves-light" data-toggle="dropdown" href="# " data-animation="scale-up" role="button">
          <span className="avatar">
            <img src="/images/global/default-avatar-80.png" alt="Profile" />
          </span>
        </a>
        <ul className="dropdown-menu position-absolute" role="menu">
          <li role="presentation">
            <Link to={`/user/${user.id}/edit`} role="menuitem"
              className="waves-effect waves-light"><i className="icon icon_profile" aria-hidden="true" /> Profile</Link>
          </li>
          <li role="presentation">
            <LinkWithRole authorize="companies" action="edit" to={`/company/${company.id}/edit`} role="menuitem"
              className="waves-effect waves-light">
              <i className="icon fa fa-building-o" aria-hidden="true" /> Company</LinkWithRole>
          </li>
          {/*
            <li role="presentation">
              <a href="https://assetdedication.zendesk.com/hc/en-us" target="_blank" rel="noopener noreferrer" role="menuitem"><i className="icon icon_question_alt" aria-hidden="true" /> Support</a>
            </li>
          */}
          {!impersonated && (
            <li role="presentation">
              <WithRole authorize="users" action="impersonate">
                <a role="menuitem" data-target="#ImpersonateModal" data-toggle="modal"
                  className="waves-effect waves-light" href="# ">
                  <i className="icon fa fa-user" aria-hidden="true" /> Impersonate</a>
              </WithRole>
            </li>
          )}
          <li className="divider" role="presentation" />
          <li role="presentation">
            <Link to="/signout" role="menuitem" className="waves-effect waves-light">
              <i className="icon icon_lock_alt" aria-hidden="true" /> {impersonated ? 'End Impersonate' : 'Logout'}</Link>
          </li>
        </ul>
      </li>
    </ul>
  )
}

const renderHeader = (impersonated, user) => {
  return (
    <div className="navbar-container container-fluid">
      <div className="collapse navbar-collapse navbar-block navbar-collapse-toolbar" id="site-navbar-collapse">
        {renderMenu(impersonated, user)}
        {renderUserMenu(impersonated, user)}
      </div>
    </div>
  )
}

const Header = ({ show, impersonated, user }) => {
  if (!show) {
    return null
  }
  return (
    <div className="main-header navbar navbar-default navbar-fixed-top navbar-inverse navbar-mega">
      <div className="navbar-header">
        <Link className="navbar-brand" to="/">
          <img className="navbar-brand-logo navbar-brand-logo-normal" src="/images/logo-header.png" title="Asset Dedication" alt="logo" />
        </Link>
      </div>
      {renderHeader(impersonated, user)}
      {impersonated && (
        <div className="impersonate-alert">
          <div>
            You are impersonating {user.first_name} {user.last_name}. To stop impersonating the user, <Link to="/signout" >click here</Link>.
          </div>
        </div>
      )}
    </div>
  )
}

Header.propTypes = {
  show: PropTypes.bool,
  user: PropTypes.object,
  impersonated: PropTypes.bool
}

export default Header
