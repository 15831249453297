import moment from 'moment'
import {
  SAVE_HOUSEHOLD_SAVING,
  SAVE_HOUSEHOLD_SUCCESS,
  SAVE_HOUSEHOLD_FAILED,
  HOUSEHOLD_FETCHING,
  HOUSEHOLD_FETCHED,
  HOUSEHOLD_FETCH_FAILED,
  HOUSEHOLD_ADD_CLIENT,
  HOUSEHOLD_REMOVE_CLIENT_FULFILLED,
  HOUSEHOLD_SAVE_CLIENT_FULFILLED,
  HOUSEHOLD_SAVE_CLIENT_PENDING,
  HOUSEHOLD_ADD_ACCOUNT,
  HOUSEHOLD_REMOVE_ACCOUNT_FULFILLED,
  HOUSEHOLD_SAVE_ACCOUNT_FULFILLED,
  HOUSEHOLD_TOGGLE_STATUS_SUCCESS
} from '../actions/household'

export default function household (state = {
  household: {},
  readyState: null,
  addingClient: false,
  addingAccount: false
}, action) {
  let idx
  let household
  let isAdding
  let data
  switch (action.type) {
    case SAVE_HOUSEHOLD_SAVING:
      return { ...state, readyState: SAVE_HOUSEHOLD_SAVING }
    case SAVE_HOUSEHOLD_SUCCESS:
      return { ...state, household: action.result, readyState: SAVE_HOUSEHOLD_SUCCESS }
    case SAVE_HOUSEHOLD_FAILED:
      return { ...state, readyState: SAVE_HOUSEHOLD_FAILED, error: action.error }
    case HOUSEHOLD_FETCHING:
      return { ...state, readyState: HOUSEHOLD_FETCHING }
    case HOUSEHOLD_FETCH_FAILED:
      return { ...state, readyState: HOUSEHOLD_FETCH_FAILED, error: action.error }
    case HOUSEHOLD_FETCHED:
      if (action.result.Clients) {
        action.result.Clients.forEach(c => {
          if (c.date_of_birth) {
            c.date_of_birth = moment.utc(c.date_of_birth)
          }
        })
      }
      return { ...state, readyState: HOUSEHOLD_FETCHED, household: action.result }
    case HOUSEHOLD_REMOVE_CLIENT_FULFILLED:
      household = { ...state.household, updated_at: new Date().toISOString() }
      data = action.payload.data
      household.Clients = (household.Clients || []).filter(cli => !data.id ? cli._id !== data._id : Number(cli.id) !== Number(data.id))
      return { ...state, household, readyState: HOUSEHOLD_REMOVE_CLIENT_FULFILLED }
    case HOUSEHOLD_ADD_CLIENT:
      household = { ...state.household, updated_at: new Date().toISOString() }
      household.Clients = household.Clients || []
      household.Clients.push({ household_id: action.payload, _id: Date.now() })
      return { ...state, household, readyState: HOUSEHOLD_ADD_CLIENT, addingClient: true }
    case HOUSEHOLD_SAVE_CLIENT_PENDING:
      return { ...state, readyState: HOUSEHOLD_SAVE_CLIENT_PENDING }
    case HOUSEHOLD_SAVE_CLIENT_FULFILLED:
      isAdding = state.addingClient
      household = { ...state.household, updated_at: new Date().toISOString() }
      household.Clients = household.Clients || []
      idx = household.Clients.findIndex(c => c.id === action.payload.data.id)
      if (idx < 0 && state.addingClient) {
        isAdding = false
        idx = household.Clients.findIndex(c => c.id === undefined)
      }
      if (idx < 0) {
        idx = household.Clients.length
      }
      household.Clients.splice(idx, 1, action.payload.data)
      return { ...state, household, readyState: HOUSEHOLD_SAVE_CLIENT_FULFILLED, addingClient: isAdding }
    case HOUSEHOLD_REMOVE_ACCOUNT_FULFILLED:
      household = { ...state.household, updated_at: new Date().toISOString() }
      data = action.payload.data
      household.Accounts = (household.Accounts || []).filter(acc => !data.id ? acc.id !== undefined : Number(acc.id) !== Number(data.id))
      return { ...state, household, readyState: HOUSEHOLD_REMOVE_ACCOUNT_FULFILLED }
    case HOUSEHOLD_ADD_ACCOUNT:
      household = { ...state.household, updated_at: new Date().toISOString() }
      household.Accounts = household.Accounts || []
      household.Accounts.push({ household: action.payload, origin: 'self' })
      return { ...state, household, readyState: HOUSEHOLD_ADD_ACCOUNT, addingAccount: true }
    case HOUSEHOLD_SAVE_ACCOUNT_FULFILLED:
      isAdding = state.addingAccount
      household = { ...state.household, updated_at: new Date().toISOString() }
      household.Accounts = household.Accounts || []
      idx = household.Accounts.findIndex(acc => acc.id === action.payload.data.id)
      if (idx < 0 && state.addingAccount) {
        isAdding = false
        idx = household.Accounts.findIndex(acc => acc.id === undefined)
      }
      if (idx < 0) {
        idx = household.Accounts.length
      }
      household.Accounts.splice(idx, 1, action.payload.data)
      return { ...state, household, readyState: HOUSEHOLD_SAVE_ACCOUNT_FULFILLED, addingAccount: isAdding }
    case HOUSEHOLD_TOGGLE_STATUS_SUCCESS:
      household = { ...state.household, updated_at: new Date().toISOString() }
      household.active = action.result.active
      return { ...state, household, readyState: HOUSEHOLD_FETCHED }
    default:
      return state
  }
}
