import { SET_STATUS } from '../actions/wizard'

const initialState = {
}

const ACTION_HANDLERS = {
  [SET_STATUS]: (state, action) => {
    const statuses = state[action.panel] || {}
    return { ...state, [action.panel]: { ...statuses, [action.form]: action.status } }
  }
}

export default function wizard (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
