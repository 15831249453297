export const GRID_INVALID = 'GRID_INVALID'
export const GRID_INITITAL = 'GRID_INITITAL'
export const GRID_PAGE = 'GRID_PAGE'
export const GRID_SEARCH = 'GRID_SEARCH'
export const GRID_FILTER = 'GRID_FILTER'
export const GRID_SORT = 'GRID_SORT'
export const GRID_STATUS = 'GRID_STATUS'

export function page (grid, page = 1) {
  return { type: GRID_PAGE, grid, page }
}

export function search (grid, search = '') {
  return { type: GRID_SEARCH, grid, search, page: 1 }
}

export function filter (grid, filter = null) {
  return { type: GRID_FILTER, grid, filter, page: 1 }
}

export function sort (grid, sort = {}) {
  return { type: GRID_SORT, grid, sort }
}

export function status (grid, status = 'active') {
  return { type: GRID_STATUS, grid, status }
}

export function invalidate (grid) {
  return { type: GRID_INVALID, grid }
}

export function initial (grid, page = 1, search = '', sort = {}, status = 'active', filter = null) {
  return { type: GRID_INITITAL, grid, page, search, sort, status, filter }
}
