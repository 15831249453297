import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Field, reduxForm } from 'redux-form'
import get from 'lodash/get'
import { fetchAccountsOptions, createReportingGroup } from '../../actions/accounts'
import { fetchReportingGroupOptions, fetchReportingGroupAccounts } from '../../actions/reportingGroups'
import { fetchHouseholdsOptions } from '../../actions/households'
import { getUser } from '../../actions/auth'
import { descriptiveName } from '../../actions/household'
import Loading from '../app/Loading'
import * as Inputs from '../form/inputs'

const FormComponent = ({ handleSubmit, forms, change, blur, userId, accounts, disabled, invalid, onSelectHousehold }) => {
  const [ reportingGroupOptions, setReportingGroupOptions ] = useState([]);
  const formValues = get(forms, [ 'ReportingGroupModal', 'values' ], {});
  const handleHouseholdChange = async option => {
    onSelectHousehold(option);
    const options = await fetchReportingGroupOptions(option.value);
    setReportingGroupOptions(options);
  };
  const handleSelectAccounts = async option => {
    const reportingGroup = await fetchReportingGroupAccounts(option.value);
    const accounts = reportingGroup.accounts
    const selectedAccounts = get(forms, [ 'ReportingGroupModal', 'values', 'accounts' ], {});
    for (const id of Object.keys(selectedAccounts)) {
      selectedAccounts[id] = accounts.find(a => a.account_id === Number(id)) !== undefined
    }
    blur('accounts', {});
    setImmediate(() => {
      blur('accounts', selectedAccounts);
      change('reporting_group', reportingGroup.name);
    });
  }
  return (
    <form autoComplete="off" onSubmit={handleSubmit}>
      <div className="form-group form-material row">
        <div className="col-sm-12">
          <Field component={Inputs.AsyncSelectField} prompt={false}
            name="household_id"
            placeholder="Household" onInputChange={handleHouseholdChange}
            loadOptions={fetchHouseholdsOptions(userId, formValues.household_id)} />
        </div>
      </div>
      {formValues.household_id && (
        <>
          {reportingGroupOptions.length > 0 && (
            <div className="row">
              <div className="col-sm-12">
                <Field component={Inputs.Checkbox} name="_edit" className="form-control" placeholder="Edit existing reporting group" />
              </div>
            </div>
          )}
          {formValues._edit && (
            <div className="form-group form-material row">
              <div className="col-sm-12">
                <Field
                  component={Inputs.SelectField}
                  name="reporting_group_id"
                  className="form-control"
                  placeholder="Existing reporting group"
                  options={reportingGroupOptions}
                  onInputChange={handleSelectAccounts}
                />
              </div>
            </div>
          )}
          <div className="form-group form-material row">
            <div className="col-sm-12">
              <Field component={Inputs.MultiCheckbox} name="accounts" options={accounts} placeholder="Accounts" />
            </div>
          </div>
          <div className="form-group form-material row">
            <div className="col-sm-12">
              <Field component={Inputs.InputField} name="reporting_group" type="text"
                className="form-control" placeholder="Reporting group name" inputPlaceholder=" " />
            </div>
          </div>
        </>
      )}
      <button className="btn btn-round btn-info waves-effect waves-light"
        disabled={disabled || invalid} type="submit" >{disabled ? <Loading label="Saving..." /> : 'Submit'}</button>
    </form>
  )
}

function validate (values) {
  const errors = {}

  if (!values.household_id) {
    errors.household_id = 'Required'
  }
  if (!values.accounts || !Object.values(values.accounts).some(s => s)) {
    errors.accounts = 'Required'
  } else {
    const selectedAccountValues = Object.entries(values.accounts).filter(([id, selected]) => selected)
    const selectedAccounts = selectedAccountValues.map(([ id ]) => values._account_options.find(o => o.value.id === Number(id)))
    if (selectedAccounts.every(o => o.value.custodian === 'ByAllAccounts' || o.value.custodian === 'Manual')) {
      errors.accounts = 'The reporting group cannot be created because Tamarac requires at least one account to be included in performance. Please add a custodial account to the reporting group.'
    }
  }
  if (!values.reporting_group) {
    errors.reporting_group = 'Required'
  }

  return errors
}

const Form = reduxForm({
  form: 'ReportingGroupModal',
  enableReinitialize: true,
  validate
})(FormComponent)

class ReportingGroupModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor (props) {
    super(props)
    this.state = {
      user: getUser(),
      submitting: false,
      accounts: [],
      initialValues: {
        default_pdf: false
      }
    }
  }

  fetchAccountsOptions = id => {
    return Promise.all([
      fetchAccountsOptions(id, false, true),
      descriptiveName(id)
    ]).then(([ options, hh ]) => {
      const initialValues = {
        household_id: id,
        accounts: options.options.reduce((h, c) => {
          h[c.value.id] = false
          return h
        }, {}),
        reporting_group: hh.name,
        _account_options: options.options
      }
      this.setState({ initialValues, accounts: options.options })
    })
  }

  handleHousehold = option => {
    if (!option) {
      this.setState({ accounts: [] })
      return
    }

    this.fetchAccountsOptions(option.value)
  }

  handleSubmit = values => {
    this.setState({ submitting: true })
    createReportingGroup(values).then(() => {
      window.$('#ReportingGroupModal').modal('hide')
      this.setState({ initialValues: { default_pdf: false }, accounts: [], submitting: false })
    })
  }

  render () {
    const forms = this.props.forms
    const { accounts, user, initialValues, submitting } = this.state
    return (
      <div id="ReportingGroupModal" data-backdrop="static" className="modal fade modal-fade-in-scale-up" aria-hidden="true" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">Reporting Group Creation</h4>
            </div>
            <div className="modal-body">
              <Form initialValues={initialValues} forms={forms} userId={user.id} disabled={submitting}
                accounts={accounts} onSelectHousehold={this.handleHousehold} onSubmit={this.handleSubmit} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    forms: state.form
  }
}

export default connect(mapStateToProps)(ReportingGroupModal)
