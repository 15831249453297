import { Component } from 'react'
import PropTypes from 'prop-types'
import config from '../../config'
import HttpClient, { resetToken } from '../../helpers/httpclient'
import browserHistory from '../../helpers/browserHistory'

function goToSignin (pathname) {
  localStorage.setItem('_pathname', pathname)
  resetToken()
  browserHistory.push('/signin')
}

export default class Authenticated extends Component {
  static propTypes = {
    location: PropTypes.object,
    children: PropTypes.node
  }

  state = {
    checkedToken: false
  }

  componentDidMount () {
    const location = this.props.location
    if (!localStorage.getItem('token')) {
      goToSignin(`${location.pathname}${location.search}`)
    } else {
      this.checkToken()
    }
  }

  async checkToken () {
    const location = this.props.location
    try {
      await HttpClient.get(`${config.api}/auth/check-token`)
      this.setState({ checkedToken: true })
    } catch (err) {
      console.error(err)
      goToSignin(`${location.pathname}${location.search}`)
    }
  }

  render () {
    if (this.state.checkedToken) {
      return this.props.children
    }
    return null
  }
}
