import defaultConfig from './default'

const config = {
  env: 'production',
  api: '/api',
  zendesk: {
    subdomain: 'assetdedication'
  }
}

export default Object.assign({}, defaultConfig, config)
