import { getActions, getTypes, getType } from '../helpers/crudActions'
import config from '../config'
import HttpClient from '../helpers/httpclient'

const OBJECT_EXCLUDE_ACCOUNT = getType('OBJECT_EXCLUDE_ACCOUNT', 'PROPOSAL_VERSIONS')
export const OBJECT_EXCLUDE_ACCOUNT_PENDING = getType('OBJECT_EXCLUDE_ACCOUNT_PENDING', 'PROPOSAL_VERSIONS')
export const OBJECT_EXCLUDE_ACCOUNT_REJECTED = getType('OBJECT_EXCLUDE_ACCOUNT_REJECTED', 'PROPOSAL_VERSIONS')
export const OBJECT_EXCLUDE_ACCOUNT_FULFILLED = getType('OBJECT_EXCLUDE_ACCOUNT_FULFILLED', 'PROPOSAL_VERSIONS')

const OBJECT_REPLACE_ACCOUNT = getType('OBJECT_REPLACE_ACCOUNT', 'PROPOSAL_VERSIONS')
export const OBJECT_REPLACE_ACCOUNT_PENDING = getType('OBJECT_REPLACE_ACCOUNT_PENDING', 'PROPOSAL_VERSIONS')
export const OBJECT_REPLACE_ACCOUNT_REJECTED = getType('OBJECT_REPLACE_ACCOUNT_REJECTED', 'PROPOSAL_VERSIONS')
export const OBJECT_REPLACE_ACCOUNT_FULFILLED = getType('OBJECT_REPLACE_ACCOUNT_FULFILLED', 'PROPOSAL_VERSIONS')

export default {
  ...getActions('PROPOSAL_VERSIONS', 'proposal-versions'),
  ...getTypes('PROPOSAL_VERSIONS'),
  OBJECT_EXCLUDE_ACCOUNT_PENDING,
  OBJECT_EXCLUDE_ACCOUNT_REJECTED,
  OBJECT_EXCLUDE_ACCOUNT_FULFILLED,
  OBJECT_REPLACE_ACCOUNT_PENDING,
  OBJECT_REPLACE_ACCOUNT_REJECTED,
  OBJECT_REPLACE_ACCOUNT_FULFILLED,
  excludeAccount (id) {
    const url = `${config.api}/proposal-accounts/${id}/exclude`
    return {
      type: OBJECT_EXCLUDE_ACCOUNT,
      payload: HttpClient.post(url)
    }
  },
  replaceAccount (id, account) {
    const params = { account_id: account, external_account_handling: null }
    const url = `${config.api}/proposal-accounts/${id}/replace`
    return {
      type: OBJECT_REPLACE_ACCOUNT,
      payload: HttpClient.post(url, params)
    }
  },
  fetch (id) {
    return {
      type: getType('OBJECT_FETCH', 'PROPOSAL_VERSIONS'),
      payload: HttpClient.get(`${config.api}/proposal-versions/${id}`).then(sortByAccount)
    }
  },
  fetchPublic (id) {
    return {
      type: getType('OBJECT_FETCH', 'PROPOSAL_VERSIONS'),
      payload: HttpClient.get(`${config.api}/proposal-versions/${id}/public`).then(sortByAccount)
    }
  }
}

function sortByAccount(r) {
  r.data.ProposalAccounts.sort((a, b) => {
    if (!a.Account.active || a.Account.custodian === 'ByAllAccounts' || a.Account.custodian === 'Manual') {
      return 1
    }
    if (!b.Account.active || b.Account.custodian === 'ByAllAccounts' || b.Account.custodian === 'Manual') {
      return -1
    }
    return `${a.Account.account_name} (${a.Account.account_number})`.localeCompare(`${b.Account.account_name} (${b.Account.account_number})`)
  })
  return r
}

export function fetchCapitalGains (id) {
  const url = `${config.api}/proposal-versions/${id}/capital-gains`
  return HttpClient.get(url)
}

export function fetchHeaderStatus (id) {
  const url = `${config.api}/proposal-versions/${id}/header-status`
  return HttpClient.get(url).then(r => r.data)
}

export function loadPositions (id) {
  const url = `${config.api}/proposal-versions/${id}/missing-cost-basis`
  return HttpClient.get(url).then(r => r.data)
}

export function fetchAccounts (id) {
  const url = `${config.api}/proposal-versions/${id}/accounts`
  return HttpClient.get(url).then(r => r.data)
}

export function implement (id) {
  const url = `${config.api}/proposal-versions/${id}/implement`
  return HttpClient.post(url).then(r => r.data)
}

export function fetchImplementStatus (id) {
  const url = `${config.api}/proposal-versions/${id}/implement-status`
  return HttpClient.get(url).then(r => r.data)
}

export function fetchCashReserves (id, accounts) {
  const url = `${config.api}/proposal-versions/${id}/cash-reserves`
  const params = { params: { accounts } }
  return HttpClient.get(url, params).then(r => r.data)
}

export function updateTargetIncome (id, accountId, values) {
  return HttpClient.put(`${config.api}/proposal-versions/${id}/target-income/${accountId}`, values).then(r => r.data)
}

export function saveName (object) {
  const url = `${config.api}/proposal-versions/${object.id}/name`
  return HttpClient.put(url, object)
}

export function fetchCanUpdateAccount (id) {
  const url = `${config.api}/proposal-versions/${id}/can-update-account`
  return HttpClient.get(url).then(r => r.data)
}

export function updateAccountValues (id) {
  const url = `${config.api}/proposal-versions/${id}/update-account-values`
  return HttpClient.put(url).then(r => r.data)
}

export function fetchManagedAccounts (id) {
  const url = `${config.api}/proposal-versions/${id}/managed-accounts`
  return HttpClient.get(url).then(r => r.data)
}
