import React, { useState } from 'react'
import ModalContext from '../../../context/modal'

const ModalProvider = ({ children }) => {
  const hideModal = () => setState(s => ({ ...s, title: null, body: null, backdrop: true, visible: false, large: false }))
  const showModal = (title, body) => setState(s => ({ ...s, title, body, backdrop: true, visible: true, large: false, timestamp: Date.now() }))
  const showLargeModal = (title, body) => setState(s => ({ ...s, title, body, backdrop: true, visible: true, large: true, timestamp: Date.now() }))
  const showStaticModal = (title, body) => setState(s => ({ ...s, title, body, backdrop: 'static', visible: true, large: false, timestamp: Date.now() }))
  const showLargeStaticModal = (title, body) => setState(s => ({ ...s, title, body, backdrop: 'static', visible: true, large: true }))
  const updateModalTitle = title => setState(s => ({ ...s, title }))
  const updateModalBody = body => setState(s => ({ ...s, body, timestamp: Date.now() }))

  const modalState = {
    visible: false,
    title: null,
    body: null,
    backdrop: true,
    large: false,
    timestamp: Date.now(),
    hideModal,
    showModal,
    showLargeModal,
    showStaticModal,
    showLargeStaticModal,
    updateModalTitle,
    updateModalBody
  }

  const [ state, setState ] = useState(modalState)

  return (
    <ModalContext.Provider value={state}>
      {children}
    </ModalContext.Provider>
  )
}

export default ModalProvider
