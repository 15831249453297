import React, { Component } from 'react'
import { getUser } from '../../actions/auth'

export default class Partners extends Component {

  render () {
    const user = getUser()
    return (
      <li className=""><span className="utmenu-click"><i className="utmenu-arrow fa fa-angle-right" /></span>
        <span className="wsmenu-click" /><a className="waves-effect waves-light" href="# "> Partners <span className="arrow_carrot-down" aria-hidden="true" /></a>
        <ul className="utmenu-submenu" style={{ display: 'none' }}>
          {this.renderTamarac()}

          {user.Company.byallaccounts && this.renderByAllAccounts()}
          {user.Company.dst_fanmail && this.renderDSTFanmail()}
          {user.Company.schwab && this.renderSchwab()}
          {user.Company.fidelity && this.renderFidelity()}
          {user.Company.dimensional && this.renderDimensional()}
        </ul>
      </li>
    )
  }

  renderTamarac () {
    return (
      <li>
        <a href="https://portal.tamaracinc.com" target="_blank" rel="noopener noreferrer"
          className="waves-effect waves-light">Tamarac</a>
      </li>
    )
  }

  // renderRebalancer () {
  //   return (
  //     <li>
  //       <a href="https://advisor.tamaracinc.com" target="_blank" rel="noopener noreferrer"
  //         className="waves-effect waves-light">Rebalancer</a>
  //     </li>
  //   )
  // }

  // renderCRM () {
  //   return (
  //     <li>
  //       <a href="https://assetdedication.crm.tamaracinc.com/" target="_blank" rel="noopener noreferrer"
  //         className="waves-effect waves-light">CRM</a>
  //     </li>
  //   )
  // }

  renderByAllAccounts () {
    return (
      <li>
        <a href="https://www.byallaccounts.net/BAAWebApp/BAALogin.html" target="_blank" rel="noopener noreferrer"
          className="waves-effect waves-light">ByAllAccounts</a>
      </li>
    )
  }

  renderDSTFanmail () {
    return (
      <li>
        <a href="https://filetransfer.financialtrans.com/tf/FANMail?tx=FANMailStartup&cz=415171403" target="_blank" rel="noopener noreferrer"
          className="waves-effect waves-light">DST Fanmail</a>
      </li>
    )
  }

  renderSchwab () {
    return (
      <li>
        <a href="https://si2.schwabinstitutional.com/SI2/SecAdmin/Logon.aspx" target="_blank" rel="noopener noreferrer"
          className="waves-effect waves-light">Schwab</a>
      </li>
    )
  }

  renderFidelity () {
    return (
      <li>
        <a href="https://www.wealthscape.com/" target="_blank" rel="noopener noreferrer"
          className="waves-effect waves-light">Fidelity</a>
      </li>
    )
  }

  // renderTDAmeritrade () {
  //   return (
  //     <li>
  //       <a href="https://www.advisorservices.com" target="_blank" rel="noopener noreferrer"
  //         className="waves-effect waves-light">TD Ameritrade</a>
  //     </li>
  //   )
  // }

  renderDimensional () {
    return (
      <li>
        <a href="https://www.mydimensional.com/" target="_blank" rel="noopener noreferrer"
          className="waves-effect waves-light">Dimensional</a>
      </li>
    )
  }
}
