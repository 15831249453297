import React from 'react'
import RoleAwareComponent from '../auth/RoleAwareComponent'

export default class LiWithRole extends RoleAwareComponent {
  render () {
    const { authorize, action, ...liProps } = this.props

    const jsx = (
      <li {...liProps} />
    )

    return this.shouldBeVisible() ? jsx : null
  }
}
