import React, { useEffect, Fragment } from 'react'
import { matchPath } from 'react-router-dom'
import { authenticatedRoutes } from '../../helpers/routeDefinitions'
import { fetchEducationalContent, saveOptOutEducationalContent } from '../../actions/educationalMaterials'
import useModal from '../../hooks/useModal'

const buttonsStyle = { margin: '10px 0 5px' }

function EducationalContent ({ id, html, onOptOut, onHide }) {
  return (
    <Fragment>
      <div dangerouslySetInnerHTML={{ __html: html }} />
      <div>
        <p>If you choose to hide this video, you can always re-watch it by visiting the Educational Materials section under the Services menu.</p>
        <p style={buttonsStyle}>
          <button type="button" className="btn btn-primary btn-round waves-effect waves-light"
            onClick={onOptOut} data-id={id}>Hide this video</button>
          {' '}
          <button type="button" className="btn btn-default btn-round waves-effect waves-light"
            onClick={onHide}>Remind me later</button>
        </p>
      </div>
    </Fragment>
  )
}

export default function EducationalModal ({ path }) {
  const { showModal, hideModal } = useModal()
  useEffect(() => {
    const paths = getMatchedPaths(path)
    const optOut = e => {
      saveOptOutEducationalContent(e.target.dataset.id).then(() => hideModal())
    }
    fetchEducationalContent(paths).then(object => {
      if (!object) {
        return
      }
      showModal(object.name, <EducationalContent id={object.id} html={object.html} onOptOut={optOut} onHide={hideModal} />)
    }).catch(err => {
      console.error(err)
    })
  }, [ path, showModal, hideModal ])
  return null
}

function getMatchedPaths (path) {
  const paths = []
  for (const option of authenticatedRoutes) {
    if (!option.path) {
      continue
    }
    if (matchPath(path, option)) {
      paths.push(option.path)
    }
  }
  return paths
}
