import config from '../config'
import defaultConfig from '../config/default'
import { getUser } from '../actions/auth'

export const ZENDESK_SUBDOMAIN = (window.location.href.match(/staging|localhost/) !== null ? defaultConfig : config).zendesk.subdomain

export function percentage (num) {
  return num ? Number(('' + num).replace('%', '')) / 100.0 : num
}

export function number (num) {
  return num ? Number(('' + num).replace(/[^0-9.-]+/g, '')) : num
}

export function formatDate (days) {
  const date = new Date(Date.now() + (days * 24 * 60 * 60 * 1000))
  return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`
}

export function formatPercentage (n, precision, defaultLabel = '0.00%') {
  if (!n) {
    return defaultLabel
  }
  let decPlaces = precision !== undefined ? precision : 2
  let symbol = '%'
  let thouSeparator = ','
  let decSeparator = '.'
  let sign = n < 0 ? '-' : ''
  let i = parseInt(n = Math.abs((+n || 0) * 100).toFixed(decPlaces)) + ''
  let j = i.length > 3 ? i.length % 3 : 0

  return sign + (j ? i.slice(0, j) + thouSeparator : '') +
    i.slice(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) +
    (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : '') +
    symbol
}

export function roundAndFormatCurrency (n) {
  return formatCurrency(round(n))
}

export function formatCurrency (n, defaultLabel = '$ 0.00') {
  if (!n) {
    return defaultLabel
  }
  let decPlaces = 2
  let currency = '$ '
  let thouSeparator = ','
  let decSeparator = '.'
  let sign = n < 0 ? '-' : ''
  let i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + ''
  let j = i.length > 3 ? i.length % 3 : 0

  return currency + sign + (j ? i.slice(0, j) + thouSeparator : '') +
    i.slice(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) +
    (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : '')
}

export function formatNumber (n, precision, defaultLabel = '0.00') {
  if (!n || n === '0.0') {
    return defaultLabel
  }
  if (n.toString().match(/,/)) {
    return n
  }
  let decPlaces = precision !== undefined ? precision : 2
  let thouSeparator = ','
  let decSeparator = '.'
  let sign = n < 0 ? '-' : ''
  let i = parseInt(n = Math.abs(+n || 0).toFixed(decPlaces)) + ''
  let j = i.length > 3 ? i.length % 3 : 0

  return sign + (j ? i.slice(0, j) + thouSeparator : '') +
    i.slice(j).replace(/(\d{3})(?=\d)/g, '$1' + thouSeparator) +
    (decPlaces ? decSeparator + Math.abs(n - i).toFixed(decPlaces).slice(2) : '')
}

export function getParameterByName (name, url) {
  if (!url) {
    url = window.location.href
  }
  name = name.replace(/[[]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const parseUrlParams = function (params, pairs) {
  const pair = pairs[0]
  const parts = pair.split('=')
  const key = decodeURIComponent(parts[0])
  const value = decodeURIComponent(parts.slice(1).join('='))
  params[key] = typeof params[key] === 'undefined' ? value : [].concat(params[key], value)
  return pairs.length === 1 ? params : parseUrlParams(params, pairs.slice(1))
}

export function getUrlParams (search) {
  const query = search || window.location.search
  return query.length === 0 ? {} : parseUrlParams({}, query.slice(1).split('&'))
}

export function shouldValidade (authorize, action) {
  const user = getUser()
  if (user) {
    const permissions = user.Role.permissions || {}
    return permissions[authorize] && permissions[authorize][action]
  }

  return false
}

export function validateRole (roles = []) {
  const user = getUser()
  if (user) {
    const role = user.Role.id || -1
    return roles.findIndex(r => r === role) !== -1
  }

  return false
}

export function round (num) {
  return +(Math.round(num + 'e+2') + 'e-2')
}

export function round2 (num) {
  const cleaned = number(num)
  return Math.round(cleaned * 100) / 100
}

export function toDataUrl (url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.withCredentials = true
    xhr.onload = () => {
      const reader = new FileReader()
      reader.onloadend = () => { resolve(reader.result) }
      reader.readAsDataURL(xhr.response)
    }
    xhr.onerror = () => { reject() }
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.send()
  })
}

export function popover () {
  const settings = {
    trigger: 'click',
    multi: true,
    closeable: false,
    padding: true
  }
  window.$('[data-target="webuiPopover"]').each((i, el) => {
    const $el = window.$(el)
    if (!$el.data('webuiPopover')) {
      settings.placement = $el.data('placement') || 'auto'
      settings.width = $el.data('width') || 'auto'
      settings.trigger = $el.data('trigger') || 'click'
      $el.webuiPopover('destroy').webuiPopover({ ...settings, url: $el.data('content') })
      $el.data('webuiPopover', true)
    }
  })
}

export function tooltip () {
  window.$('[data-toggle="tooltip"]').tooltip()
}

const requestAnimFrame = (function () {
  return window.requestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    function (callback) {
      window.setTimeout(callback, 1000 / 60)
    }
})()

export function scrollToY (scrollTargetY = 0, speed = 2000, easing = 'easeOutSine') {
  const scrollY = window.scrollY || document.documentElement.scrollTop
  let currentTime = 0

  const time = Math.max(0.1, Math.min(Math.abs(scrollY - scrollTargetY) / speed, 0.8))

  const easingEquations = {
    easeOutSine: function (pos) {
      return Math.sin(pos * (Math.PI / 2))
    },
    easeInOutSine: function (pos) {
      return (-0.5 * (Math.cos(Math.PI * pos) - 1))
    },
    easeInOutQuint: function (pos) {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5)
      }
      return 0.5 * (Math.pow((pos - 2), 5) + 2)
    }
  }

  function tick () {
    currentTime += 1 / 60

    var p = currentTime / time
    var t = easingEquations[easing](p)

    if (p < 1) {
      requestAnimFrame(tick)

      window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t))
    } else {
      window.scrollTo(0, scrollTargetY)
    }
  }

  tick()
}

export function getNested (obj, path, sep = '.') {
  try {
    return path.replace('[', sep).replace(']', '').split(sep).reduce((obj, prop) => obj[prop], obj)
  } catch (err) {
    return undefined
  }
}

export function isNullOrUndefined (val) {
  return val === null || val === undefined
}

export function getBody (object, files) {
  const body = new FormData()
  Object.keys(object).forEach(key => {
    const val = files.indexOf(key) !== -1 ? getFile(key, object) : object[key]
    val !== null && val !== undefined && body.append(key, val)
  })

  return body
}

export function getFile (key, object) {
  if (object[key]) {
    return typeof object[key] === 'string' ? object[key] : object[key][0]
  }
  return object[key]
}

export function arrayIsEqual (a, b) {
  if (a === b) return true
  if (!a || !b) return false
  if (a.length !== b.length) return false

  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

export function sum (l, f) {
  return l.reduce((t, o) => t + number(o[f]), 0)
}

export function formatNumberWithCommas(n) {
  if (!n) {
    return null
  }
  return '$ ' + n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function cancelFetchRequests (list) {
  for (const cancel of list) {
    if (cancel) {
      cancel()
    }
  }
}

