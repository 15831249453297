import config from '../config'
import HttpClient from '../helpers/httpclient'
import browserHistory from '../helpers/browserHistory'

const path = 'educational-materials'

export function fetch (id) {
  return HttpClient.get(`${config.api}/${path}/${id}`)
}

export function fetchBySlug (slug) {
  return HttpClient.get(`${config.api}/${path}/slug/${slug}`)
}

export function list (page = 1, status = 'all', term = null, sort = null, filter = null) {
  const params = {
    params: { page, status, filter }
  }
  if (term) {
    params.params.q = term
  }
  if (sort) {
    params.params.order = sort.order
    params.params.direction = sort.direction
  }
  return HttpClient.get(`${config.api}/${path}`, params)
}

export function save (object, redirect = true) {
  const method = object.id ? 'put' : 'post'
  const url = `${config.api}/${path}/${object.id || ''}`
  return HttpClient[method](url, object).then(response => {
    if (redirect) {
      browserHistory.push(`/${path}`)
    }
    return response
  })
}

export function fetchEducationalContent (urls) {
  const params = {
    params: { urls }
  }
  const url = `${config.api}/${path}/educational-content`
  return HttpClient.get(url, params).then(response => response.data)
}

export function saveOptOutEducationalContent (id) {
  const url = `${config.api}/${path}/${id}/opt-out`
  return HttpClient.post(url).then(response => response.data)
}
