import {
//   USER_SERVICE_TEAMS_INVALID,
  USER_SERVICE_TEAMS_FETCHING,
  USER_SERVICE_TEAMS_FETCHED,
  USER_SERVICE_TEAMS_FETCH_FAILED
} from '../actions/serviceTeams'
import { getActionHandler, getInitialState } from '../helpers/crudReducers'

// const state = {
//   readyState: USER_SERVICE_TEAMS_INVALID,
//   list: []
// }

const initialState = getInitialState('SERVICE_TEAMS')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('SERVICE_TEAMS'),
  [USER_SERVICE_TEAMS_FETCHING]: (state, action) => {
    return { ...state, readyState: USER_SERVICE_TEAMS_FETCHING }
  },
  [USER_SERVICE_TEAMS_FETCH_FAILED]: (state, action) => {
    return { ...state, readyState: USER_SERVICE_TEAMS_FETCH_FAILED, error: action.error }
  },
  [USER_SERVICE_TEAMS_FETCHED]: (state, action) => {
    return { ...state, readyState: USER_SERVICE_TEAMS_FETCHED, list: action.result }
  }
}

export default function serviceTeams (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
