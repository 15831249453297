let historyObject

const object = {
  set: history => {
    historyObject = history
  },

  push: path => {
    historyObject.push(path)
  }
}

export default object
