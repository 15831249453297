import {
  ACCOUNTS_INVALID,
  ACCOUNTS_FETCHING,
  ACCOUNTS_FETCHED,
  ACCOUNTS_FETCH_FAILED,
  ACCOUNT_FETCHED,
  ACCOUNT_TOGGLE_STATUS_SUCCESS,
  CLOSE_ACCOUNT_FULFILLED,
  CLOSE_ACCOUNT_PENDING,
  REOPEN_ACCOUNT_FULFILLED,
  REOPEN_ACCOUNT_PENDING,
  ACCOUNT_FETCHING,
  ACCOUNT_SAVING,
  SAVE_ACCOUNT_FAILED,
  SAVE_ACCOUNT_SUCCESS,
  CONVERT_TO_UC_ACCOUNT_FULFILLED,
  CONVERT_MANAGED_TO_ADMIN_FULFILLED,
  AD_WILL_NOT_MANAGE_FULFILLED
} from '../actions/accounts'

export default function accounts (state = {
  readyState: ACCOUNTS_INVALID,
  list: {
    accounts: [],
    pageSize: 50,
    currentPage: 1,
    count: 0
  },
  account: {}
}, action) {
  let accounts = null
  let account = null
  switch (action.type) {
    case ACCOUNTS_FETCHING:
      return { ...state, readyState: ACCOUNTS_FETCHING }
    case ACCOUNTS_FETCH_FAILED:
      return { ...state, readyState: ACCOUNTS_FETCH_FAILED, error: action.error }
    case ACCOUNTS_FETCHED:
      return { ...state, readyState: ACCOUNTS_FETCHED, list: action.result }
    case ACCOUNT_FETCHED:
      return { ...state, readyState: ACCOUNT_FETCHED, account: action.result }
    case ACCOUNT_TOGGLE_STATUS_SUCCESS:
      accounts = state.list.accounts.map(acc => acc.id === action.result.id ? action.result : acc)
      account = state.account.id === action.result.id ? action.result : state.account
      return { ...state, readyState: ACCOUNT_FETCHED, list: { ...state.list, accounts, account }, account }
    case CLOSE_ACCOUNT_PENDING:
      return { ...state, readyState: CLOSE_ACCOUNT_PENDING }
    case CLOSE_ACCOUNT_FULFILLED:
      return { ...state, readyState: ACCOUNT_FETCHED, account: action.payload.data }
    case REOPEN_ACCOUNT_PENDING:
      return { ...state, readyState: REOPEN_ACCOUNT_PENDING }
    case REOPEN_ACCOUNT_FULFILLED:
      return { ...state, readyState: ACCOUNT_FETCHED, account: action.payload.data }
    case ACCOUNT_FETCHING:
      return { ...state, readyState: ACCOUNT_FETCHING }
    case ACCOUNT_SAVING:
      return { ...state, readyState: ACCOUNT_SAVING }
    case SAVE_ACCOUNT_FAILED:
      return { ...state, readyState: SAVE_ACCOUNT_FAILED, error: action.error }
    case SAVE_ACCOUNT_SUCCESS:
      return { ...state, account: action.result, readyState: SAVE_ACCOUNT_SUCCESS }
    case CONVERT_TO_UC_ACCOUNT_FULFILLED:
      accounts = state.list.accounts.map(acc => acc.id === action.payload.data.id ? action.payload.data : acc)
      account = state.account.id === action.payload.data.id ? action.payload.data : state.account
      return { ...state, readyState: ACCOUNT_FETCHED, list: { ...state.list, accounts, account }, account }
    case CONVERT_MANAGED_TO_ADMIN_FULFILLED:
      accounts = state.list.accounts.map(acc => acc.id === action.payload.data.id ? action.payload.data : acc)
      account = state.account.id === action.payload.data.id ? action.payload.data : state.account
      return { ...state, readyState: ACCOUNT_FETCHED, list: { ...state.list, accounts, account }, account }
    case AD_WILL_NOT_MANAGE_FULFILLED:
      accounts = state.list.accounts.map(acc => acc.id === action.payload.data.id ? action.payload.data : acc)
      account = state.account.id === action.payload.data.id ? action.payload.data : state.account
      return { ...state, readyState: ACCOUNT_FETCHED, list: { ...state.list, accounts, account }, account }
    default:
      return state
  }
}
