import React, { Component } from 'react';
import moment from 'moment';

export default class DatePickerInput extends Component {

  constructor(props) {
    super(props)
    const value = formatDate(props.value);
    this.state = {
      value,
      isValid: moment.utc(value, 'MM/DD/YYYY', true).isValid(),
      error: null
    };
  }

  componentDidUpdate (prevProps) {
    const value = formatDate(this.props.value);
    const prevValue = formatDate(prevProps.value);
    if (value !== prevValue) {
      const isValid = moment.utc(value, 'MM/DD/YYYY', true).isValid();
      this.setState({ value, isValid, error: null });
    }
  }

  handleChange = e => {
    e.persist();
    e.target.value = formatDate(e.target.value);
    const { onChange, minDate, maxDate } = this.props;
    const { isValid, value, error } = validateDate(e.target.value, minDate, maxDate);
    e.target.value = value;
    onChange(e);
    setImmediate(() => this.setState({ value, isValid, error }));
  }

  handleBlur = e => {
    if (e.target.value === '') {
      return;
    }
    const { onChange, minDate, maxDate } = this.props;
    const { isValid, error } = validateDate(e.target.value, minDate, maxDate);
    if (isValid && !error) {
      return;
    }
    const value = '';
    onChange({ target: { value }, isDefaultPrevented: () => false });
    setImmediate(() => this.setState({ value, isValid, error }));
  }

  render () {
    const { onChange, maxDate, minDate, ...inputProps } = this.props;
    const value = this.state.value;
    return (
      <>
        <input {...inputProps} value={value} onChange={this.handleChange} onBlur={this.handleBlur} />
        {this.state.error && (
          <div className="has-error has-danger">
            <div className="help-block with-errors">{this.state.error}</div>
          </div>
        )}
      </>
    );
  }
}

function validateDate (value, minDate, maxDate) {
  const date = moment.utc(value, 'MM/DD/YYYY', true);
  const isValid = date.isValid();
  if (isValid) {
    if (minDate && date.isBefore(minDate.utc())) {
      return { isValid, value: '', error: `Date cannot be before ${minDate.utc().format('MM/DD/YYYY')}` };
    }
    if (maxDate && date.isAfter(maxDate.utc())) {
      return { isValid, value: '', error: `Date cannot be after ${maxDate.utc().format('MM/DD/YYYY')}` };
    }
  }
  if (value.length === 10 && !isValid) {
    return { isValid, value: '', error: 'Date is invalid' };
  }
  return { isValid, value, error: null };
}

function formatDate (value) {
  if (!value || moment.isMoment(value)) {
    return value;
  }
  const onlyNums = value.replace(/[^\d]/g, '');
  const month = onlyNums.slice(0, 2);
  const day = onlyNums.slice(2, 4);
  const year = onlyNums.slice(4, 8);
  if (onlyNums.length <= 2) {
    return month;
  }
  if (onlyNums.length <= 4) {
    return `${month}/${day}`;
  }
  return `${month}/${day}/${year}`;
}

export function selectedDate (date) {
  if (moment.isMoment(date)) {
    return date;
  }
  const utc = moment.utc(date);
  if (utc.isValid()) {
    return utc;
  }
  return null;
}
