import config from '../config'
import HttpClient from '../helpers/httpclient'
import { getActions, getTypes } from '../helpers/crudActions'

export default {
  ...getActions('INCOME_BUILDER_INVENTORIES', 'income-builder-inventories'),
  ...getTypes('INCOME_BUILDER_INVENTORIES')
}

export const INITIALIZE_FIELDS = 'INITIALIZE_FIELDS'
export const INITIALIZED_FIELDS = 'INITIALIZED_FIELDS'

export function initializeFields (values) {
  return {
    type: INITIALIZE_FIELDS,
    values
  }
}

export function initializedFields () {
  return {
    type: INITIALIZED_FIELDS
  }
}

export function fetchSuggested (symbol) {
  const params = { params: { symbol } }
  return HttpClient.get(`${config.api}/income-builder-inventories/suggested`, params).then(r => r.data)
}
