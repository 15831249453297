import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import users from './users'
import households from './households'
import household from './household'
import accounts from './accounts'
import types from './types'
import companies from './companies'
import grid from './grid'
import alerts from './alerts'
import serviceTeams from './serviceTeams'
import models from './models'
import auth from './auth'
import roles from './roles'
import proposals from './proposals'
import proposalAccounts from './proposalAccounts'
import proposalVersions from './proposalVersions'
import proposalInventories from './proposalInventories'
import sharedObjects from './sharedObjects'
import criticalpaths from './criticalpaths'
import criticalpathVersions from './criticalpathVersions'
import agreements from './agreements'
import securityRestrictions from './securityRestrictions'
import billingDefinitions from './billingDefinitions'
import billingGroups from './billingGroups'
import accountArs from './accountArs'
import cashReserves from './cashReserves'
import householdTickets from './householdTickets'
import clients from './clients'
import custodialRequests from './custodialRequests'
import custodialForms from './custodialForms'
import custodialFormFunctions from './custodialFormFunctions'
import custodialFormFields from './custodialFormFields'
import layout from './layout'
import tamaracTransactions from './tamaracTransactions'
import wizard from './wizard'
import monitors from './monitors'
import tradeRequests from './tradeRequests'
import watchers from './watchers'
import leads from './leads'
import incomeBuilders from './incomeBuilders'
import incomeBuilderInventories from './incomeBuilderInventories'
import tamaracReports from './tamaracReports'
import tamaracReportFields from './tamaracReportFields'

export const makeRootReducer = (asyncReducers) => {
  return combineReducers({
    ...asyncReducers,
    users,
    auth,
    households,
    household,
    types,
    accounts,
    companies,
    grid,
    alerts,
    serviceTeams,
    models,
    roles,
    proposals,
    proposalAccounts,
    proposalVersions,
    proposalInventories,
    sharedObjects,
    criticalpaths,
    criticalpathVersions,
    agreements,
    securityRestrictions,
    billingDefinitions,
    billingGroups,
    accountArs,
    cashReserves,
    householdTickets,
    clients,
    custodialRequests,
    custodialForms,
    custodialFormFunctions,
    custodialFormFields,
    layout,
    tamaracTransactions,
    wizard,
    monitors,
    tradeRequests,
    watchers,
    leads,
    incomeBuilders,
    incomeBuilderInventories,
    tamaracReports,
    tamaracReportFields,
    form: formReducer
  })
}

export default makeRootReducer
