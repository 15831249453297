import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ReactTable from 'react-table'

const defaultValue = { symbol: '', shares: 'ALL', handling: '', account_number: '' }
const handlingOptions = [ 'In Kind', 'Liquidate' ]

function getValue (value) {
  if (!value) {
    return [{ ...defaultValue }]
  }
  try {
    return JSON.parse(value)
  } catch (err) {
    return [{ ...defaultValue }]
  }
}

export default class DataTableInput extends Component {
  static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    mutualFunds: PropTypes.bool,
    hideShares: PropTypes.bool
  }

  constructor (props) {
    super(props)
    const columns = [
      { accessor: 'symbol', Header: 'Symbol or fund name', Cell: this.renderEditable }
    ]
    if (!props.hideShares) {
      columns.push({ accessor: 'shares', Header: 'Shares', Cell: this.renderEditable })
    }
    if (props.mutualFunds) {
      columns.push({ accessor: 'handling', Header: 'Handling', Cell: this.renderSelect })
      columns.push({ accessor: 'account_number', Header: 'Fund account number', Cell: this.renderEditable })
    }
    columns.push({ Cell: this.renderActions, maxWidth: 75 })
    const data = getValue(props.value)
    this.state = {
      columns,
      data
    }
  }

  componentWillReceiveProps (props) {
    if (this.props.mutualFunds !== props.mutualFunds || this.props.hideShares !== props.hideShares) {
      const columns = [
        { accessor: 'symbol', Header: 'Symbol or fund name', Cell: this.renderEditable }
      ]
      if (!props.hideShares) {
        columns.push({ accessor: 'shares', Header: 'Shares', Cell: this.renderEditable })
      }
      if (props.mutualFunds) {
        columns.push({ accessor: 'handling', Header: 'Handling', Cell: this.renderSelect })
        columns.push({ accessor: 'account_number', Header: 'Fund account number', Cell: this.renderEditable })
      }
      columns.push({ Cell: this.renderActions, maxWidth: 75 })
      this.setState({ columns })
    }
  }

  renderActions = cell => {
    return (
      <button type="button"
        className="btn btn-default btn-xs waves-effect waves-light pull-right"
        onClick={e => {
          const data = [...this.state.data]
          data.splice(cell.index, 1)
          this.setState({ data }, () => {
            this.props.onChange(JSON.stringify(this.state.data))
          })
        }}>× Remove</button>
    )
  }

  renderSelect = cell => {
    const value = this.state.data[cell.index][cell.column.id]
    return (
      <select style={{ width: '100%' }} value={value} onChange={e => {
        const data = [...this.state.data]
        data[cell.index][cell.column.id] = e.target.value
        this.setState({ data }, () => {
          this.props.onChange(JSON.stringify(this.state.data))
        })
      }}>
        {handlingOptions.map(name => <option key={name} value={name}>{name}</option>)}
      </select>
    )
  }

  renderEditable = cell => {
    return (
      <div
        style={{ backgroundColor: '#fafafa', padding: '2px 5px' }}
        contentEditable
        suppressContentEditableWarning
        onBlur={e => {
          const data = [...this.state.data]
          data[cell.index][cell.column.id] = e.target.textContent
          this.setState({ data }, () => {
            this.props.onChange(JSON.stringify(this.state.data))
          })
        }}
        dangerouslySetInnerHTML={{
          __html: this.state.data[cell.index][cell.column.id]
        }}
      />
    )
  }

  addRow = e => {
    const data = this.state.data
    data.push({ ...defaultValue })
    this.setState({ data })
  }

  render () {
    const { data, columns } = this.state
    return (
      <div>
        <ReactTable key={data.length} data={data} columns={columns}
          showPagination={false} sortable={false} filterable={false}
          pageSize={data.length + 1} />
        <div className="row">
          <div className="col-sm-2 col-sm-offset-10">
            <button type="button" className="btn btn-primary btn-sm waves-effect waves-light pull-right margin-top-10"
              onClick={this.addRow}>+ Add</button>
          </div>
        </div>
      </div>
    )
  }
}
