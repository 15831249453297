import config from '../config'
import HttpClient, { CancelToken, isCancel } from '../helpers/httpclient'
import browserHistory from '../helpers/browserHistory'
import { cancelFetchRequests } from '../helpers/util'

export const MODELS_INVALID = 'MODELS_INVALID'
export const MODELS_FETCHING = 'MODELS_FETCHING'
export const MODELS_FETCHED = 'MODELS_FETCHED'
export const MODELS_FETCH_FAILED = 'MODELS_FETCH_FAILED'

export const MODEL_SAVING = 'MODEL_SAVING'
export const SAVE_MODEL_SUCCESS = 'SAVE_MODEL_SUCCESS'
export const SAVE_MODEL_FAILED = 'SAVE_MODEL_FAILED'

export const MODEL_INVALID = 'MODEL_INVALID'
export const MODEL_FETCHING = 'MODEL_FETCHING'
export const MODEL_FETCHED = 'MODEL_FETCHED'
export const MODEL_FETCH_FAILED = 'MODEL_FETCH_FAILED'

export const MODEL_TOGGLING_STATUS = 'MODEL_TOGGLING_STATUS'
export const MODEL_TOGGLE_STATUS_SUCCESS = 'MODEL_TOGGLE_STATUS_SUCCESS'
export const MODEL_TOGGLE_STATUS_FAILED = 'MODEL_TOGGLE_STATUS_FAILED'

export function saveModel (model) {
  return (dispatch) => {
    dispatch({ type: MODEL_SAVING, model: model })

    return HttpClient.post(`${config.api}/model`, model)
    .then((response) => {
      return response.data
    })
    .then(
      result => {
        dispatch({ type: SAVE_MODEL_SUCCESS, result })
        browserHistory.push('/models')
      },
      error => dispatch({ type: SAVE_MODEL_FAILED, error })
    )
  }
}

export function updateModel (id, model) {
  return (dispatch) => {
    dispatch({ type: MODEL_SAVING, model: model })

    return HttpClient.put(`${config.api}/model/${id}`, model)
    .then((response) => {
      return response.data
    })
    .then(
      result => {
        dispatch({ type: SAVE_MODEL_SUCCESS, result })
        browserHistory.push('/models')
      },
      error => dispatch({ type: SAVE_MODEL_FAILED, error })
    )
  }
}

export function fetchModel (id) {
  return (dispatch) => {
    dispatch({ type: MODEL_FETCHING })

    let url = `${config.api}/model/${id}`
    if (!/^\d+$/.test(id)) {
      url = `${url}/public`
    }

    return HttpClient.get(url)
      .then((response) => {
        return response.data
      })
      .then(
        result => dispatch({ type: MODEL_FETCHED, result }),
        error => dispatch({ type: MODEL_FETCH_FAILED, error })
      )
  }
}

let cancel = []

export function fetchModels (page = 1, term = undefined, sort = undefined, status = 'active', options = {}) {
  return (dispatch) => {
    if (cancel.length !== 0) {
      cancelFetchRequests(cancel)
    }
    dispatch({ type: MODELS_FETCHING })

    const params = {
      cancelToken: new CancelToken(function executor(c) {
        cancel.push(c)
      }),
      params: {
        page,
        status,
        ...options
      }
    }

    if (term) {
      params.params.q = term
    }

    if (sort) {
      params.params.order = sort.order
      params.params.direction = sort.direction
    }

    return HttpClient.get(`${config.api}/models`, params)
      .then((response) => {
        return response.data
      })
      .then(
        result => {
          cancel = []
          dispatch({ type: MODELS_FETCHED, result })
        },
        error => {
          cancel = cancel.filter(c => c !== null)
          if (!isCancel(error)) {
            dispatch({ type: MODELS_FETCH_FAILED, error })
          }
        }
      )
  }
}

export function toggleModelActive (id) {
  return (dispatch) => {
    dispatch({ type: MODEL_TOGGLING_STATUS })
    return HttpClient.put(`${config.api}/model/${id}/toggle-active`)
    .then((response) => {
      return response.data
    })
    .then(
      result => dispatch({ type: MODEL_TOGGLE_STATUS_SUCCESS, result }),
      error => dispatch({ type: MODEL_TOGGLE_STATUS_FAILED, error })
    )
  }
}

export function getModels (q) {
  const params = { params: {} }
  if (q) {
    params.params.q = q
  }
  return HttpClient.get(`${config.api}/models/all`, params)
}

export function fetchModelsOptions () {
  const getOption = m => ({ value: m.id, label: m.name, object: m })
  const getOptions = ms => ({ options: ms.models.map(getOption) })
  return (input) => {
    return getModels(input).then(r => r.data).then(getOptions)
  }
}

export function getModelsAccessible (q, include, cashFlow) {
  const params = { params: {} }
  if (q) {
    params.params.q = q
  }
  if (include) {
    params.params.include = include
  }
  if (cashFlow) {
    params.params.cashFlow = cashFlow
  }
  return HttpClient.get(`${config.api}/models/all-accessbile`, params)
}

export function fetchModelsAccessibleOptions (include, cashFlow) {
  const getOption = m => ({ value: m.id, label: m.name, object: m })
  const getOptions = ms => ({ options: ms.models.map(getOption) })
  return (input) => {
    return getModelsAccessible(input, include, cashFlow).then(r => r.data).then(getOptions)
  }
}

export function updateSecurities (id) {
  return HttpClient.put(`${config.api}/model/securities`, { id }).then(r => r.data)
}

export function fetchCoreModelOptions (input) {
  const params = { params: {} }
  if (input) {
    params.params.q = input
  }
  return HttpClient.get(`${config.api}/models/core-model-options`, params).then(r => r.data)
}
