import config from '../config'
import HttpClient from '../helpers/httpclient'
import browserHistory from '../helpers/browserHistory'

const path = 'banners'

export function fetch (id) {
  return HttpClient.get(`${config.api}/${path}/${id}`)
}

export function list (page = 1, status = 'all', term = null, sort = null, filter = null) {
  const params = {
    params: { page, status, filter }
  }
  if (term) {
    params.params.q = term
  }
  if (sort) {
    params.params.order = sort.order
    params.params.direction = sort.direction
  }
  return HttpClient.get(`${config.api}/${path}`, params)
}

export function listExternal () {
  return HttpClient.get(`${config.api}/${path}/external`)
}

export function save (object, redirect = true) {
  const method = object.id ? 'put' : 'post'
  const url = `${config.api}/${path}/${object.id || ''}`
  return HttpClient[method](url, object).then(response => {
    if (redirect) {
      browserHistory.push(`/${path}`)
    }
    return response
  })
}
