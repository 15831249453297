import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import UserActions from '../actions/users'
import { getUser, saveUser } from '../actions/auth'

const initialState = getInitialState('USERS')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('USERS'),
  [UserActions.OBJECT_SAVE_SIGNATURE_REJECTED]: (state, action) => {
    return { ...state, readyState: UserActions.OBJECT_SAVE_SIGNATURE_REJECTED, error: action.error }
  },
  [UserActions.OBJECT_SAVE_SIGNATURE_FULFILLED]: (state, action) => {
    return { ...state, readyState: UserActions.OBJECT_SAVE_SIGNATURE_FULFILLED, object: action.payload.data }
  },
  [UserActions.OBJECT_SAVE_FULFILLED]: (state, action) => {
    const user = action.payload.data
    updateUser(user)
    return { ...state, readyState: UserActions.OBJECT_SAVE_FULFILLED, object: user }
  },
  [UserActions.DASHBOARD_COMPLETED_REJECTED]: (state, action) => {
    return { ...state, readyState: UserActions.DASHBOARD_COMPLETED_REJECTED, error: action.error }
  },
  [UserActions.DASHBOARD_COMPLETED_FULFILLED]: (state, action) => {
    const user = action.payload.data
    updateUser(user)
    return { ...state, readyState: UserActions.DASHBOARD_COMPLETED_FULFILLED, object: user }
  },
  [UserActions.OBJECT_SAVE_ADVISOR_FULFILLED]: (state, action) => {
    return { ...state, readyState: UserActions.OBJECT_SAVE_ADVISOR_FULFILLED, error: false }
  },
  [UserActions.OBJECT_SAVE_ADVISOR_PENDING]: (state, action) => {
    return { ...state, readyState: UserActions.OBJECT_SAVE_ADVISOR_PENDING, error: false }
  },
  [UserActions.OBJECT_SAVE_ADVISOR_REJECTED]: (state, action) => {
    return { ...state, readyState: UserActions.OBJECT_SAVE_ADVISOR_REJECTED, error: action.error, status: action.payload.response.status, message: action.payload.response.data }
  }
}

export default function users (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}

function updateUser (user) {
  if (user.id === getUser().id) {
    saveUser(user)
  }
}
