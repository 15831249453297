import React, { useState, useEffect } from 'react'
import { list, listExternal } from '../../actions/banners'
import { signedIn } from '../../actions/auth'

function getHiddenBanners () {
  try {
    return JSON.parse(localStorage.getItem('__banners')) || {}
  } catch {
    return []
  }
}

function saveHiddenBanners (list) {
  try {
    localStorage.setItem('__banners', JSON.stringify(list))
  } catch {}
}

function addHiddenBanner (id, timestamp) {
  const hidden = getHiddenBanners()
  hidden[id] = timestamp
  saveHiddenBanners(hidden)
}

function Banner ({ object }) {
  const [ visible, setVisible ] = useState(true)
  const handleClose = e => {
    setVisible(false)
    addHiddenBanner(object.id, object.updated_at)
  }
  if (!visible) {
    return null
  }
  return (
    <div className={`banner banner-${object.background}`}>
      <button type="button" className="close" onClick={handleClose}>
        <span>×</span>
      </button>
      <div dangerouslySetInnerHTML={{ __html: object.html }} />
    </div>
  )
}

export default function Banners () {
  const [ banners, setBanners ] = useState([])
  useEffect(() => {
    const listFn = signedIn() ? list : listExternal;
    listFn(1, 'active').then(response => {
      const hiddenIds = getHiddenBanners()
      const banners = response.data.objects.filter(o => !hiddenIds[o.id] || hiddenIds[o.id] !== o.updated_at)
      setBanners(banners)
    })
  }, [])
  if (banners.length === 0) {
    return null
  }
  return (
    <div className="banners">
      {banners.map(b => <Banner key={b.id} object={b} />)}
    </div>
  )
}
