import config from '../config'
import HttpClient from '../helpers/httpclient'
import { getActions, getTypes } from '../helpers/crudActions'

export const USER_SERVICE_TEAMS_INVALID = 'USER_SERVICE_TEAMS_INVALID'
export const USER_SERVICE_TEAMS_FETCHING = 'USER_SERVICE_TEAMS_FETCHING'
export const USER_SERVICE_TEAMS_FETCHED = 'USER_SERVICE_TEAMS_FETCHED'
export const USER_SERVICE_TEAMS_FETCH_FAILED = 'USER_SERVICE_TEAMS_FETCH_FAILED'

export function getServiceTeams (id, all, q) {
  const params = { params: {} }
  params.params.q = q
  if (all) {
    params.params.all = true
  }
  return HttpClient.get(`${config.api}/users/${id}/service-teams`, params)
}

export function fetchServiceTeam (id) {
  return HttpClient.get(`${config.api}/service-teams/${id}`).then(r => r.data)
}

export function fetchServiceTeams (id) {
  return (dispatch) => {
    dispatch({ type: USER_SERVICE_TEAMS_FETCHING })

    return getServiceTeams(id)
      .then((response) => response.data)
      .then(
        result => dispatch({ type: USER_SERVICE_TEAMS_FETCHED, result }),
        error => dispatch({ type: USER_SERVICE_TEAMS_FETCH_FAILED, error })
      )
  }
}

export function fetchServiceTeamsOptions (id, all) {
  const getOption = s => ({ value: s.service_team, label: `${s.name} (${s.service_team})`, object: s })
  const getOptions = serviceTeams => ({ options: serviceTeams.objects.map(getOption) })
  return (input) => {
    return getServiceTeams(id, all, input).then((r) => r.data).then(getOptions)
  }
}

export function searchField (search, id, q) {
  const params = { params: {} }
  if (id) {
    params.params.id = id
  }
  if (q) {
    params.params.q = q
  }
  return HttpClient.get(`${config.api}/service-teams/${search}`, params)
}

const getOptions = (options, field) => ({ options: options.map(o => ({ value: o[field], label: o[field] })) })

export function fetchDefaultTemplates (id) {
  return input => searchField('default-templates', id, input).then(r => getOptions(r.data, 'default_template'))
}

export function fetchPortalViews (id) {
  return input => searchField('portal-views', id, input).then(r => getOptions(r.data, 'portal_view'))
}

export default {
  ...getActions('SERVICE_TEAMS', 'service-teams'),
  ...getTypes('SERVICE_TEAMS'),
  getServiceTeams,
  fetchServiceTeams,
  fetchServiceTeamsOptions
}
