import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Select from 'react-select'
import { fetchImpersonateOptions } from '../../actions/users'
import { doImpersonate } from '../../actions/auth'

class ImpersonateModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  }

  state = {
    user: null
  }

  selectUser = user => {
    this.setState({ user })
  }

  impersonate = () => {
    const user = { id: this.state.user.value }
    this.props.dispatch(doImpersonate(user, this.props.location.pathname))
    this.setState({ user: null })
  }

  render () {
    return (
      <div id="ImpersonateModal" className="modal fade modal-fade-in-scale-up" aria-hidden="true" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">Select user to impersonate</h4>
            </div>
            <div className="modal-body">
              <div className="form-group form-material row">
                <div className="col-sm-12">
                  <Select.Async placeholder="User" className="form-control"
                    loadOptions={fetchImpersonateOptions} value={this.state.user}
                    onChange={this.selectUser} />
                </div>
              </div>
              {this.state.user && (
                <div className="form-group form-material row">
                  <div className="col-sm-12">
                    <button onClick={this.impersonate}
                      className="btn btn-round btn-info waves-effect waves-light">Impersonate</button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(ImpersonateModal)
