import config from '../config'
import HttpClient from '../helpers/httpclient'
import browserHistory from '../helpers/browserHistory'

export const AUTH_INVALID = 'AUTH_INVALID'
export const AUTH_AUTHENTICATING = 'AUTH_AUTHENTICATING'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_FAILED = 'AUTH_FAILED'
export const AUTH_SIGNING_OUT = 'AUTH_SIGNING_OUT'
export const AUTH_SIGNING_OUT_SUCCESS = 'AUTH_SIGNING_OUT_SUCCESS'
export const AUTH_CHANGING_PASSWORD = 'AUTH_CHANGING_PASSWORD'
export const AUTH_CHANGE_PASSWORD_SUCCESS = 'AUTH_CHANGE_PASSWORD_SUCCESS'
export const AUTH_CHANGE_PASSWORD_FAILED = 'AUTH_CHANGE_PASSWORD_FAILED'
export const AUTH_RESETTING_PASSWORD = 'AUTH_RESETTING_PASSWORD'
export const AUTH_RESET_PASSWORD_SUCCESS = 'AUTH_RESET_PASSWORD_SUCCESS'
export const AUTH_RESET_PASSWORD_FAILED = 'AUTH_RESET_PASSWORD_FAILED'

export const AUTH_IMPERSONATING = 'AUTH_IMPERSONATING'
export const AUTH_IMPERSONATE_SUCCESS = 'AUTH_IMPERSONATE_SUCCESS'
export const AUTH_IMPERSONATE_FAILED = 'AUTH_IMPERSONATE_FAILED'

export const AUTH_ENDIMPERSONATING = 'AUTH_ENDIMPERSONATING'
export const AUTH_ENDIMPERSONATING_SUCCESS = 'AUTH_ENDIMPERSONATE_SUCCESS'
export const AUTH_ENDIMPERSONATING_FAILED = 'AUTH_ENDIMPERSONATING_FAILED'

export function identifyFS (user, impersonated) {
  const FS = window.FS
  if (!FS || !FS.identify || !user) {
    console.log('FS not found. Unable to identify user.')
    return
  }
  if (impersonated) {
    FS.identify(`${user.id}-${impersonated.id}`, {
      displayName: `${user.first_name} ${user.last_name} as ${impersonated.first_name} ${impersonated.last_name}`,
      email: `${user.email} as ${impersonated.email}`
    })
    return
  }
  FS.identify(`${user.id}`, {
    displayName: `${user.first_name} ${user.last_name}`,
    email: user.email
  })
}

export function anonymizeFS () {
  const FS = window.FS
  if (!FS || !FS.identify) {
    console.log('FS not found. Unable to anonymize user.')
    return
  }
  FS.identify(false)
}

function authenticate (user) {
  return (dispatch) => {
    dispatch({ type: AUTH_AUTHENTICATING })
    return HttpClient.post(`${config.api}/auth`, user)
      .then(r => r.data)
      .then(result => {
        dispatch({ type: AUTH_SUCCESS, result })
        saveToken(result.token)
        saveUser(result.user)
        identifyFS(result.user)
        const path = localStorage.getItem('_pathname') || '/'
        localStorage.removeItem('_pathname')
        browserHistory.push(path)
      },
      error => dispatch({ type: AUTH_FAILED, error }))
  }
}

function doChangePassword (id, password) {
  return (dispatch) => {
    dispatch({ type: AUTH_CHANGING_PASSWORD })
    return HttpClient.post(`${config.api}/change-password/${id}`, { password })
      .then(r => r.data)
      .then(result => {
        dispatch({ type: AUTH_CHANGE_PASSWORD_SUCCESS, result })
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        localStorage.removeItem('impersonated')
        HttpClient.defaults.headers.common['x-access-token'] = ''
        browserHistory.push('/signin?r=true')
      },
      error => dispatch({ type: AUTH_CHANGE_PASSWORD_FAILED, error }))
  }
}

function doResetPassword (email, redirect = true) {
  return (dispatch) => {
    dispatch({ type: AUTH_RESETTING_PASSWORD })
    return HttpClient.post(`${config.api}/reset-password`, { email })
      .then(r => r.data)
      .then(result => {
        dispatch({ type: AUTH_RESET_PASSWORD_SUCCESS, result })
        if (redirect) {
          browserHistory.push('/signin')
        }
      },
      error => dispatch({ type: AUTH_RESET_PASSWORD_FAILED, error }))
  }
}

function doSignout () {
  return (dispatch) => {
    dispatch({ type: AUTH_SIGNING_OUT })

    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('impersonated')
    HttpClient.defaults.headers.common['x-access-token'] = ''

    dispatch({ type: AUTH_SIGNING_OUT_SUCCESS, user: {} })
  }
}

export function doImpersonate (user, path) {
  return (dispatch) => {
    browserHistory.push('/impersonating')
    dispatch({ type: AUTH_IMPERSONATING })
    anonymizeFS()
    const authenticated = getUser()
    return HttpClient.post(`${config.api}/users/impersonate/${user.id}`)
      .then(r => r.data)
      .then(result => {
        dispatch({ type: AUTH_IMPERSONATE_SUCCESS, result })
        saveToken(result.token)
        saveUser(result.user)
        identifyFS(authenticated, result.user)
        saveImpersonated(result.impersonated)
        window.$('#ImpersonateModal, #ImpersonateAlertModal').modal('hide')
        browserHistory.push(path || '/')
      },
      error => dispatch({ type: AUTH_IMPERSONATE_FAILED, error }))
  }
}

export function endImpersonate () {
  return (dispatch) => {
    dispatch({ type: AUTH_ENDIMPERSONATING })
    anonymizeFS()
    return HttpClient.delete(`${config.api}/users/end-impersonate`)
      .then(r => r.data)
      .then(result => {
        dispatch({ type: AUTH_ENDIMPERSONATING_SUCCESS, result })
        saveToken(result.token)
        saveUser(result.user)
        identifyFS(result.user)
        localStorage.removeItem('impersonated')
        browserHistory.push('/')
      },
      error => dispatch({ type: AUTH_ENDIMPERSONATING_FAILED, error }))
  }
}

export function signin (user) {
  return (dispatch, getState) => { // eslint-disable-line
    return dispatch(authenticate(user))
  }
}

export function saveToken (token) {
  localStorage.setItem('token', token)
  HttpClient.defaults.headers.common['x-access-token'] = token
}

export function saveUser (user) {
  localStorage.setItem('user', JSON.stringify(user))
  document.dispatchEvent(new Event('asset:update-user'))
}

export function saveImpersonated (impersonated) {
  localStorage.setItem('impersonated', impersonated)
}

export function signout () {
  return (dispatch, getState) => { // eslint-disable-line
    return dispatch(doSignout())
  }
}

export function signedIn () {
  return localStorage.getItem('token') != null
}

export function isImpersonated () {
  return localStorage.getItem('impersonated') === 'true'
}

export function getUser () {
  return JSON.parse(localStorage.getItem('user'))
}

export function getToken () {
  return localStorage.getItem('token')
}

export function changePassword (id, password) {
  return (dispatch, getState) => { // eslint-disable-line
    return dispatch(doChangePassword(id, password))
  }
}

export function resetPassword (email, redirect = true) {
  return (dispatch, getState) => { // eslint-disable-line
    return dispatch(doResetPassword(email, redirect))
  }
}

export function activateUser (user) {
  return dispatch => {
    dispatch({ type: AUTH_CHANGING_PASSWORD })
    return HttpClient.post(`${config.api}/activate/${user.activation_token}`, user)
      .then(response => response.data)
      .then(result => {
        dispatch({ type: AUTH_CHANGE_PASSWORD_SUCCESS, result })
        saveToken(result.token)
        saveUser(result.user)
        browserHistory.push('/')
      }, error => dispatch({ type: AUTH_CHANGE_PASSWORD_FAILED, error }))
  }
}
