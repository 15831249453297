import axios from 'axios'
import isEqual from 'lodash/isEqual'
import browserHistory from './browserHistory'
import config from '../config'
import { getUser, saveUser } from '../actions/auth'

export const API_HOST = ''

export const CancelToken = axios.CancelToken
export const isCancel = axios.isCancel

const HttpClient = axios.create({
  baseURL: API_HOST,
  withCredentials: true
})

function submitError (error) {
  if (config.env === 'development' || error.response.status === 404) {
    return
  }
  const payload = {
    page: window.location.href,
    url: error.config.url,
    data: error.config.data,
    params: error.config.params,
    method: error.config.method,
    headers: error.config.headers,
    status: error.response.status,
    statusText: error.response.statusText,
    responseData: error.response.data,
    requestURL: error.response.request.responseURL
  }
  axios.post(`https://68qahrfhs0.execute-api.us-east-1.amazonaws.com/dev/send`, payload)
}

export function resetToken () {
  localStorage.removeItem('token')
  localStorage.removeItem('user')
  localStorage.removeItem('impersonated')
  HttpClient.defaults.headers.common['x-access-token'] = ''
}

function updatePermissions (response) {
  if (!response.headers.permissions) {
    return
  }
  const permissions = JSON.parse(response.headers.permissions)
  const user = getUser()
  if (user && !isEqual(user.Role.permissions, permissions)) {
    user.Role.permissions = permissions
    saveUser(user)
  }
}

function updateDeployVersion (response) {
  if (!response.headers.version) {
    return
  }
  const version = localStorage.getItem('version')
  if (!version || version !== response.headers.version) {
    localStorage.setItem('version', response.headers.version)
    if (window.location.pathname === '/signin') {
      return
    }
    localStorage.setItem('_pathname', `${window.location.pathname}${window.location.search}`)
    resetToken()
    browserHistory.push('/signin')
  }
}

function handleResponseError (error) {
  if (isCancel(error)) {
    return Promise.reject(error)
  }
  if (error.response.status === 401 || error.response.status === 403) {
    resetToken()
    browserHistory.push('/signin')
  } else {
    submitError(error)
  }
  return Promise.reject(error)
}

function handleResponseSuccess (response) {
  updatePermissions(response)
  updateDeployVersion(response)
  return response
}

// Add a response interceptor
HttpClient.interceptors.response.use(handleResponseSuccess, handleResponseError)

const token = localStorage.getItem('token')
if (token) {
  HttpClient.defaults.headers.common['x-access-token'] = token
}

export default HttpClient
