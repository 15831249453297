import { createContext } from 'react'

const ModalContext = createContext({
  visible: false,
  title: null,
  body: null,
  backdrop: true,
  large: false,
  timestamp: Date.now(),
  hideModal: () => {},
  showModal: (title, body) => {},
  showLargeModal: (title, body) => {},
  showStaticModal: (title, body) => {},
  showLargeStaticModal: (title, body) => {},
  updateModalTitle: title => {},
  updateModalBody: body => {}
})

export default ModalContext
