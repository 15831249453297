import {
  TYPES_INVALID,
  TYPES_FETCHING,
  TYPES_FETCHED,
  TYPES_FETCH_FAILED
} from '../actions/type'

export default function types (state = {
  readyState: TYPES_INVALID,
  list: []
}, action) {
  switch (action.type) {
    case TYPES_FETCHING:
      return Object.assign({}, state, {
        readyState: TYPES_FETCHING
      })
    case TYPES_FETCH_FAILED:
      return Object.assign({}, state, {
        readyState: TYPES_FETCH_FAILED,
        error: action.error
      })
    case TYPES_FETCHED:
      return Object.assign({}, state, {
        readyState: TYPES_FETCHED,
        list: action.result
      })
    default:
      return state
  }
}
