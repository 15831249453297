export const DISPLAY_ALERT = 'DISPLAY_ALERT'
export const HIDE_ALERT = 'HIDE_ALERT'

export function displayAlert (title, body, callback) {
  return {
    type: DISPLAY_ALERT,
    payload: { title, callback, body }
  }
}

export function displayConfirmAlert (title, confirm, cancel, callback, body) {
  return {
    type: DISPLAY_ALERT,
    payload: { title, callback, confirm, cancel, body }
  }
}

export function hideAlert () {
  return { type: HIDE_ALERT }
}
