import React, { Suspense } from 'react'
import { Route, Switch } from 'react-router-dom'

import Authenticated from './components/auth/Authenticated'
import Loading from './components/app/Loading'
import App from './containers/App'

import { unauthenticatedRoutes, authenticatedRoutes } from './helpers/routeDefinitions'

const fallback = (
  <div className="main-content">
    <div className="page-content">
      <Loading />
    </div>
  </div>
)

const makeRoute = (route, i) => <Route key={i} {...route} />

export default (
  <App>
    <Suspense fallback={fallback}>
      <Switch>
        {unauthenticatedRoutes.map(makeRoute)}
        <Authenticated>
          <Switch>
            {authenticatedRoutes.map(makeRoute)}
          </Switch>
        </Authenticated>
      </Switch>
    </Suspense>
  </App>
)
