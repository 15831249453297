import { Component } from 'react'
import { getUser } from '../../actions/auth'

class RoleAwareComponent extends Component {
  shouldBeVisible () {
    const user = getUser()
    if (user) {
      const { authorize, action } = this.props
      const permissions = user.Role.permissions || {}
      return permissions[authorize] && permissions[authorize][action]
    }

    return false
  }
}

export default RoleAwareComponent
