import config from '../config'
import HttpClient, { CancelToken, isCancel } from '../helpers/httpclient'
import { cancelFetchRequests, formatCurrency } from '../helpers/util';

export const HOUSEHOLDS_INVALID = 'HOUSEHOLDS_INVALID'
export const HOUSEHOLDS_FETCHING = 'HOUSEHOLDS_FETCHING'
export const HOUSEHOLDS_FETCHED = 'HOUSEHOLDS_FETCHED'
export const HOUSEHOLDS_FETCH_FAILED = 'HOUSEHOLDS_FETCH_FAILED'

export const HOUSEHOLD_TOGGLING_STATUS = 'HOUSEHOLD_TOGGLING_STATUS'
export const HOUSEHOLD_TOGGLE_STATUS_SUCCESS = 'HOUSEHOLD_TOGGLE_STATUS_SUCCESS'
export const HOUSEHOLD_TOGGLE_STATUS_FAILED = 'HOUSEHOLD_TOGGLE_STATUS_FAILED'

export const MERGE_HOUSEHOLD = 'MERGE_HOUSEHOLD'
export const MERGE_HOUSEHOLD_PENDING = 'MERGE_HOUSEHOLD_PENDING'
export const MERGE_HOUSEHOLD_REJECTED = 'MERGE_HOUSEHOLD_REJECTED'
export const MERGE_HOUSEHOLD_FULFILLED = 'MERGE_HOUSEHOLD_FULFILLED'

let cancel = []

export function fetchHouseholds (page = 1, term = undefined, sort = undefined, status = 'active', serviceTeam = null, account = null) {
  return (dispatch) => {
    if (cancel.length !== 0) {
      cancelFetchRequests(cancel)
    }
    dispatch({ type: HOUSEHOLDS_FETCHING })
    const params = {
      cancelToken: new CancelToken(function executor(c) {
        cancel.push(c)
      }),
      params: {
        page,
        serviceTeam,
        status
      }
    }

    if (term) {
      params.params.q = term
    }

    if (sort) {
      params.params.order = sort.order
      params.params.direction = sort.direction
    }

    if (account) params.params.account = account

    return HttpClient.get(`${config.api}/households`, params)
      .then((response) => {
        return response.data
      })
      .then(
        result => {
          cancel = []
          dispatch({ type: HOUSEHOLDS_FETCHED, result })
        },
        error => {
          cancel = cancel.filter(c => c !== null)
          if (!isCancel(error)) {
            dispatch({ type: HOUSEHOLDS_FETCH_FAILED, error })
          }
        }
      )
  }
}

export function getHouseholds (id, include, q = null, advisors = null, excludeUnsynced = false) {
  const params = { params: {} }
  if (q) {
    params.params.q = q
  }
  if (include) {
    params.params.include = include
  }
  if (advisors) {
    params.params.advisors = advisors
  }
  if (excludeUnsynced) {
    params.params.excludeUnsynced = excludeUnsynced
  }
  return HttpClient.get(`${config.api}/users/${id}/households`, params)
}

export function fetchHouseholdsOptions (id, include, excludeUnsynced = false) {
  const getOption = h => ({ value: h.id, label: h.household_name, object: h })
  const getOptions = households => ({ options: households.map(getOption) })
  return (input) => {
    return getHouseholds(id, include, input, null, excludeUnsynced).then(r => r.data).then(getOptions)
  }
}

export function fetchHouseholdsByAdvisorsOptions (id, advisors) {
  const getOption = h => ({ value: h.id, label: h.household_name, object: h })
  const getOptions = households => ({ options: households.map(getOption) })
  return (input) => {
    return getHouseholds(id, null, input, advisors).then(r => r.data).then(getOptions)
  }
}

export function toggleHouseholdActive (id) {
  return (dispatch) => {
    dispatch({ type: HOUSEHOLD_TOGGLING_STATUS })
    return HttpClient.put(`${config.api}/household/${id}/toggle-active`)
    .then((response) => {
      return response.data
    })
    .then(
      result => dispatch({ type: HOUSEHOLD_TOGGLE_STATUS_SUCCESS, result }),
      error => dispatch({ type: HOUSEHOLD_TOGGLE_STATUS_FAILED, error })
    )
  }
}

export function mergeHouseholds (household, newHousehold) {
  const params = {
    newHousehold: newHousehold.id
  }
  return {
    type: MERGE_HOUSEHOLD,
    payload: HttpClient.post(`${config.api}/household/${household.id}/merge`, params)
  }
}

export function getTamaracHouseholds (q, id = null) {
  const params = { params: { q } }
  if (id) {
    params.params.exclude = id
  }
  return HttpClient.get(`${config.api}/households/tamarac`, params)
}

export function fetchTamaracHouseholdsOptions (id = null) {
  const getOption = m => ({ value: m, label: m.household_name })
  const getOptions = ms => ({ options: ms.households.map(getOption) })
  return (input) => {
    return getTamaracHouseholds(input, id).then(r => r.data).then(getOptions)
  }
}

export function getManualHouseholds (q, id = null) {
  const params = { params: { q } }
  if (id) {
    params.params.exclude = id
  }
  return HttpClient.get(`${config.api}/households/manual`, params)
}

export function fetchManagementHouseholds (user, include) {
  const url = `${config.api}/users/${user}/households/management`
  const getHousehold = h => ({ value: h.id, label: h.household_name })
  return input => {
    const params = { params: {} }
    if (input) {
      params.params.q = input
    }
    if (include) {
      params.params.include = include
    }
    return HttpClient.get(url, params).then(r => ({ options: r.data.map(getHousehold) }))
  }
}

export function fetchManualHouseholdsOptions (id = null) {
  const getOption = m => ({ value: m, label: m.household_name })
  const getOptions = ms => ({ options: ms.households.map(getOption) })
  return (input) => {
    return getManualHouseholds(input, id).then(r => r.data).then(getOptions)
  }
}

export function implement (id) {
  return HttpClient.get(`${config.api}/households/${id}/implement`).then(r => r.data)
}

export function paymentAccounts (id) {
  const url = `${config.api}/households/${id}/payment-accounts`
  const getAccount = acc => ({ value: acc.id, label: `${acc.account_name} (${acc.account_number})` })
  return input => HttpClient.get(url).then(r => ({ options: r.data.map(getAccount) }))
}

export function fetchBillingGroups (id) {
  const url = `${config.api}/household/${id}/billing-groups`
  return HttpClient.get(url).then(r => r.data)
}

export function fetchRebalacingGroups (id) {
  const url = `${config.api}/households/${id}/rebalancing-groups`
  return input => HttpClient.get(url).then(r => ({ options: r.data }))
}

export function fetchRebalacingGroupMembers (id) {
  const url = `${config.api}/households/${id}/rebalancing-group-members`
  return () => HttpClient.get(url).then(r => r.data)
}

export function fetchProposals (id) {
  const url = `${config.api}/households/${id}/proposals`
  return () => HttpClient.get(url).then(r => ({ options: r.data }))
}

export function fetchAccountsInProposals (id) {
  const url = `${config.api}/households/${id}/account-in-proposal`
  return () => HttpClient.get(url).then(r => ({ options: r.data }))
}

export function fetchUCAccountsInProposals (id) {
  const url = `${config.api}/households/${id}/uc-accounts-in-proposals`
  return () => HttpClient.get(url).then(r => ({ options: r.data.map(a => ({ value: a.value, label: `${a.label} ${formatCurrency(a.current_value)}` })) }))
}

export function fetchCDBalances (id, incomeBuilderId) {
  const url = `${config.api}/households/${id}/cd-balances/${incomeBuilderId}`
  return HttpClient.get(url).then(r => r.data)
}

export function fetchClients (id) {
  const url = `${config.api}/households/${id}/clients`
  const params = { params: { household: id, order: 'id', direction: 'asc' } }
  return HttpClient.get(url, params).then(r => r.data)
}

export function fetchAccounts (id) {
  const url = `${config.api}/households/${id}/accounts`
  const params = { params: { household: id, order: 'id', direction: 'asc', all: true } }
  return HttpClient.get(url, params).then(r => r.data)
}

export function updateClients (id, clients) {
  const url = `${config.api}/households/${id}/rename`
  return HttpClient.put(url, { clients }).then(r => r.data)
}

export function split(id, clients, accounts) {
  const url = `${config.api}/households/${id}/split`
  const params = { clients, accounts }
  return HttpClient.put(url, params)
}
