import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom'
import { Provider } from 'react-redux'
import createStore from './createStore'
import routes from './routes'
import './styles'

const initialState = window.___INITIAL_STATE__
const store = createStore(initialState)

class App extends Component {
  render () {
    return (
      <Provider store={store}>
        <Router>{routes}</Router>
      </Provider>
    );
  }
}

export default App;
