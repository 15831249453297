import {
  HOUSEHOLDS_INVALID,
  HOUSEHOLDS_FETCHING,
  HOUSEHOLDS_FETCHED,
  HOUSEHOLDS_FETCH_FAILED,
  HOUSEHOLD_TOGGLE_STATUS_SUCCESS,
  MERGE_HOUSEHOLD_FULFILLED
} from '../actions/households'

const initialState = {
  readyState: HOUSEHOLDS_INVALID,
  list: {
    households: [],
    pageSize: 50,
    currentPage: 1,
    count: 0
  }
}

const handlers = {
  [HOUSEHOLDS_FETCHING]: (state, action) => {
    return { ...state, readyState: HOUSEHOLDS_FETCHING }
  },
  [HOUSEHOLDS_FETCH_FAILED]: (state, action) => {
    return { ...state, readyState: HOUSEHOLDS_FETCH_FAILED, error: action.error }
  },
  [HOUSEHOLDS_FETCHED]: (state, action) => {
    return { ...state, readyState: HOUSEHOLDS_FETCHED, list: action.result }
  },
  [HOUSEHOLD_TOGGLE_STATUS_SUCCESS]: (state, action) => {
    const households = state.list.households.map(household => {
      if (household.id === action.result.id) {
        household = action.result
      }
      return household
    })
    return { ...state, readyState: HOUSEHOLD_TOGGLE_STATUS_SUCCESS, list: { ...state.list, households } }
  },
  [MERGE_HOUSEHOLD_FULFILLED]: (state, action) => {
    const households = [ ...state.list.households ]
    const index = state.list.households.findIndex(h => h.id === action.payload.data.id)
    const count = state.list.count - 1
    households.splice(index, 1)
    return { ...state, readyState: MERGE_HOUSEHOLD_FULFILLED, list: { ...state.list, households, count } }
  }
}

export default function households (state = initialState, action) {
  const handler = handlers[action.type]
  return handler ? handler(state, action) : state
}
