import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import Header from '../components/app/Header'
import Footer from '../components/app/Footer'
import Alert from '../components/app/Alert'
import ImpersonateAlertModal from '../components/user/ImpersonateAlertModal'
import ImpersonateModal from '../components/user/ImpersonateModal'
import ReportingGroupModal from '../components/account/ReportingGroupModal'
import Banners from '../components/banner/Banners'
import EducationalModal from '../components/user/EducationalModal'
import * as AuthActions from '../actions/auth'
import browserHistory from '../helpers/browserHistory'
import ModalProvider from '../components/app/modal/ModalProvider'
import Modal from '../components/app/modal/Modal'

class App extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object
  }

  componentWillMount () {
    browserHistory.set(this.props.history)
  }

  componentDidMount () {
    if (AuthActions.signedIn()) {
      const user = AuthActions.getUser()
      AuthActions.identifyFS(user)
    }
    document.addEventListener('asset:update-user', () => {
      this.forceUpdate()
    })
  }

  componentDidUpdate (prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0)
    }
  }

  render () {
    const { children } = this.props
    const user = AuthActions.getUser()
    const signedIn = AuthActions.signedIn()
    return (
      <div>
        <Helmet
          title="Asset Dedication, LLC"
          titleTemplate="%s | Asset Dedication, LLC"
          meta={[
            { 'char-set': 'utf-8' },
            { name: 'description', content: 'Asset Dedication, LLC' }
          ]}
        />
        <ModalProvider>
          <Banners />
          <Header show={signedIn} impersonated={AuthActions.isImpersonated()} user={user} />
          {children}
          <Footer show={signedIn} />
          <Alert />
          {signedIn && <ImpersonateModal location={this.props.location} />}
          {signedIn && <ImpersonateAlertModal location={this.props.location} />}
          {signedIn && <ReportingGroupModal />}
          {signedIn && <EducationalModal path={this.props.location.pathname} />}
          <Modal />
        </ModalProvider>
      </div>
    )
  }
}

export default withRouter(App)
