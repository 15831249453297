import {
  CLIENTS_INVALID,
  CLIENTS_FETCHING,
  CLIENTS_FETCHED,
  CLIENTS_FETCH_FAILED,
  CLIENT_FETCHED,
  CLIENT_TOGGLE_STATUS_SUCCESS,
  CLIENT_FETCHING,
  CLIENT_SAVING,
  SAVE_CLIENT_SUCCESS,
  ADD_ACCOUNT_HOLDER_FULFILLED,
  CREATE_PORTAL_FULFILLED,
  RESET_PORTAL_PASSWORD_FULFILLED,
  DEACTIVATE_PORTAL_FULFILLED,
  UPDATE_PORTAL_FULFILLED,
  ACTIVATE_PORTAL_FULFILLED,
  CREATE_PORTAL_PENDING,
  RESET_PORTAL_PASSWORD_PENDING,
  DEACTIVATE_PORTAL_PENDING,
  UPDATE_PORTAL_PENDING,
  ACTIVATE_PORTAL_PENDING
} from '../actions/clients'

export default function clients (state = {
  readyState: CLIENTS_INVALID,
  list: {
    clients: [],
    pageSize: 50,
    currentPage: 1,
    count: 0
  },
  client: {}
}, action) {
  switch (action.type) {
    case CLIENTS_FETCHING:
      return { ...state, readyState: CLIENTS_FETCHING }
    case CLIENTS_FETCH_FAILED:
      return { ...state, readyState: CLIENTS_FETCH_FAILED, error: action.error }
    case CLIENTS_FETCHED:
      return { ...state, readyState: CLIENTS_FETCHED, list: action.result }
    case CLIENT_FETCHED:
      return { ...state, readyState: CLIENT_FETCHED, client: action.result }
    case CLIENT_TOGGLE_STATUS_SUCCESS:
      const clients = state.list.clients.map(acc => {
        if (acc.id === action.result.id) {
          acc = action.result
        }
        return acc
      })
      return { ...state, readyState: CLIENT_FETCHED, list: { ...state.list, clients } }
    case CLIENT_FETCHING:
      return { ...state, readyState: CLIENT_FETCHING }
    case CLIENT_SAVING:
      return { ...state, readyState: CLIENT_SAVING }
    case SAVE_CLIENT_SUCCESS:
      return { ...state, readyState: SAVE_CLIENT_SUCCESS, client: action.result }
    case ADD_ACCOUNT_HOLDER_FULFILLED:
      return { ...state, readyState: ADD_ACCOUNT_HOLDER_FULFILLED, client: action.payload.data }
    case CREATE_PORTAL_PENDING:
      return { ...state, readyState: CREATE_PORTAL_PENDING }
    case RESET_PORTAL_PASSWORD_PENDING:
      return { ...state, readyState: RESET_PORTAL_PASSWORD_PENDING }
    case DEACTIVATE_PORTAL_PENDING:
      return { ...state, readyState: DEACTIVATE_PORTAL_PENDING }
    case UPDATE_PORTAL_PENDING:
      return { ...state, readyState: UPDATE_PORTAL_PENDING }
    case ACTIVATE_PORTAL_PENDING:
      return { ...state, readyState: ACTIVATE_PORTAL_PENDING }
    case CREATE_PORTAL_FULFILLED:
      return { ...state, readyState: CREATE_PORTAL_FULFILLED }
    case RESET_PORTAL_PASSWORD_FULFILLED:
      return { ...state, readyState: RESET_PORTAL_PASSWORD_FULFILLED }
    case DEACTIVATE_PORTAL_FULFILLED:
      return { ...state, readyState: DEACTIVATE_PORTAL_FULFILLED }
    case UPDATE_PORTAL_FULFILLED:
      return { ...state, readyState: UPDATE_PORTAL_FULFILLED }
    case ACTIVATE_PORTAL_FULFILLED:
      return { ...state, readyState: ACTIVATE_PORTAL_FULFILLED }
    default:
      return state
  }
}
