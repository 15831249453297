import React from 'react'
import { Link } from 'react-router-dom'
import RoleAwareComponent from '../auth/RoleAwareComponent'

export default class LinkWithRole extends RoleAwareComponent {
  render () {
    const { authorize, action, showText, ...linkProps } = this.props

    const jsx = (
      <Link {...linkProps} />
    )

    return this.shouldBeVisible() ? jsx : showText ? <span children={linkProps.children} /> : null
  }
}
