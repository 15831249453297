import React, { Component } from 'react'
import NavLinkWithRole from './NavLinkWithRole'

export default class HouseHoldMenu extends Component {

  render () {
    return (
      <li className=""><span className="utmenu-click"><i className="utmenu-arrow fa fa-angle-right" /></span>
        <span className="wsmenu-click" />
        <a className="waves-effect waves-light" href="# ">
          Households <span className="arrow_carrot-down" aria-hidden="true" /></a>
        <ul className="utmenu-submenu" style={{ display: 'none' }}>
          <li className="">
            <NavLinkWithRole authorize="households" action="list" to="/households"
              className="waves-effect waves-light">Household list</NavLinkWithRole>
          </li>
          <li className="">
            <NavLinkWithRole authorize="households" action="list" to="/clients"
              className="waves-effect waves-light">Clients</NavLinkWithRole>
          </li>
          <li className="">
            <NavLinkWithRole authorize="accounts" action="list" to="/accounts"
              className="waves-effect waves-light">Accounts</NavLinkWithRole>
          </li>
          <li className="">
            <NavLinkWithRole authorize="proposals" action="list" to="/proposals"
              className="waves-effect waves-light"> Proposals</NavLinkWithRole>
          </li>
          <li className="">
            <NavLinkWithRole authorize="proposals" action="new" to="/critical-paths"
              className="waves-effect waves-light"> Critical Paths</NavLinkWithRole>
          </li>
        </ul>
      </li>
    )
  }

}
