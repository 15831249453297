import config from '../config'
import HttpClient from '../helpers/httpclient'
import { getActions, getTypes } from '../helpers/crudActions'

export const INCOME_BUILDERS_UPDATE_CASHFLOW = 'INCOME_BUILDERS_UPDATE_CASHFLOW'
export const INCOME_BUILDERS_UPDATE_CASHFLOW_PENDING = 'INCOME_BUILDERS_UPDATE_CASHFLOW_PENDING'
export const INCOME_BUILDERS_UPDATE_CASHFLOW_REJECTED = 'INCOME_BUILDERS_UPDATE_CASHFLOW_REJECTED'
export const INCOME_BUILDERS_UPDATE_CASHFLOW_FULFILLED = 'INCOME_BUILDERS_UPDATE_CASHFLOW_FULFILLED'

export default {
  ...getActions('INCOME_BUILDERS', 'income-builders'),
  ...getTypes('INCOME_BUILDERS'),
  updateCashFlow,
  INCOME_BUILDERS_UPDATE_CASHFLOW,
  INCOME_BUILDERS_UPDATE_CASHFLOW_PENDING,
  INCOME_BUILDERS_UPDATE_CASHFLOW_REJECTED,
  INCOME_BUILDERS_UPDATE_CASHFLOW_FULFILLED
}

export function fetchAssignee (q, include, limit) {
  const params = { params: { q, include, limit } }
  const getOption = u => ({ value: u.id, label: `${u.first_name} ${u.last_name}` })
  const getOptions = r => ({ options: r.data.map(getOption) })
  return HttpClient.get(`${config.api}/income-builders/assignees`, params).then(getOptions)
}

export function updateCashFlow (id, accountId, values) {
  return {
    type: INCOME_BUILDERS_UPDATE_CASHFLOW,
    payload: HttpClient.put(`${config.api}/income-builders/${id}/cash-flow/${accountId}`, values)
  }
}

export function fetchProjectedIncome (id, tradeRequest) {
  const url = `${config.api}/income-builders/${id}/projected-income/${tradeRequest}`
  return HttpClient.get(url).then(r => r.data)
}

export function fetchUnreconciled (id) {
  const url = `${config.api}/income-builders/${id}/unreconciled`
  return HttpClient.get(url).then(r => r.data)
}

export function fetchBondsPurchasedToday (id, account) {
  const params = { params: { account } }
  const url = `${config.api}/income-builders/${id}/bonds-purchased-today`
  return HttpClient.get(url, params).then(r => r.data)
}

