import config from '../config'
import HttpClient from '../helpers/httpclient'
import { getActions, getTypes } from '../helpers/crudActions'

export const OBJECT_SAVE_ALL = 'OBJECT_SAVE_ALL'
export const OBJECT_SAVE_ALL_PENDING = 'OBJECT_SAVE_ALL_PENDING'
export const OBJECT_SAVE_ALL_REJECTED = 'OBJECT_SAVE_ALL_REJECTED'
export const OBJECT_SAVE_ALL_FULFILLED = 'OBJECT_SAVE_ALL_FULFILLED'

export default {
  ...getActions('PROPOSAL_INVENTORIES', 'proposal-inventories'),
  ...getTypes('PROPOSAL_INVENTORIES'),
  OBJECT_SAVE_ALL,
  OBJECT_SAVE_ALL_PENDING,
  OBJECT_SAVE_ALL_REJECTED,
  OBJECT_SAVE_ALL_FULFILLED,
  saveAll (objects) {
    const url = `${config.api}/proposal-inventories/all`
    return {
      type: OBJECT_SAVE_ALL,
      payload: HttpClient.put(url, { objects })
    }
  },
  uploadInventory (formData) {
    const url = `${config.api}/proposal-inventories/all/import`
    return HttpClient.post(url, formData)
  }
}

export function fetchProposalInvetories (id) {
  return HttpClient.get(`${config.api}/proposal-inventories/all`).then(r => r.data)
}

export function fetchImportValues (id) {
  return HttpClient.get(`${config.api}/proposal-inventories/${id}/import`).then(r => r.data)
}

export function fetchCustomSecurities () {
  return HttpClient.get(`${config.api}/proposal-inventories/custom-securities`).then(r => r.data)
}
