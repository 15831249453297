import React from 'react'
import { Link } from 'react-router-dom'
import NavLinkWithRole from './NavLinkWithRole'
import LiWithRole from './LiWithRole'
import Partners from './Partners'
import HouseHoldMenu from './HouseHoldMenu'
import { ZENDESK_SUBDOMAIN } from '../../helpers/util'

const displayNone = {
  display: 'none'
}

export default ({ user }) => {
  const company = user.Company

  return (
    <ul className="mobile-sub utmenu-list">
      <li>
        <div className="navbar-header mobile-res-logo">
          <Link className="navbar-brand" to="/">
            <img className="navbar-brand-logo navbar-brand-logo-normal"
              src="/images/logo-header.png" title="Asset Dedication" alt="logo" />
          </Link>
        </div>
      </li>
      <HouseHoldMenu />
      {/* <li>
        <NavLinkWithRole authorize="alerts" action="list" to="/alerts"
          className="waves-effect waves-light">Alerts</NavLinkWithRole>
      </li> */}
      <li>
        <Link to="/monitoring" role="menuitem" className="waves-effect waves-light"> Monitoring</Link>
      </li>
      <LiWithRole authorize="trading" action="visible" className="">
        <span className="utmenu-click"><i className="utmenu-arrow fa fa-angle-right" /></span>
        <span className="wsmenu-click" />
        <a className="waves-effect waves-light" href="# ">
          Trading <span className="arrow_carrot-down" aria-hidden="true" />
        </a>
        <ul className="utmenu-submenu" style={displayNone}>
          <li role="presentation">
            <NavLinkWithRole authorize="households" action="list" to="/trading-requests"
              className="waves-effect waves-light"> Trading requests</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="income builders" action="list" to="/income-builders"
              className="waves-effect waves-light">Income Builders</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="proposal inventory" action="list" to="/proposal-inventory"
              className="waves-effect waves-light">Proposal Inventory</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="rebalancing" action="run" to="/rebalancing"
              className="waves-effect waves-light">Rebalancing</NavLinkWithRole>
          </li>
        </ul>
      </LiWithRole>
      <LiWithRole authorize="settings" action="visible" className="">
        <span className="utmenu-click"><i className="utmenu-arrow fa fa-angle-right" /></span>
        <span className="wsmenu-click" />
        <a className="waves-effect waves-light" href="# ">
          Settings <span className="arrow_carrot-down" aria-hidden="true" />
        </a>
        <ul className="utmenu-submenu" style={displayNone}>
          <li>
            <NavLinkWithRole authorize="automated-investing" action="list" to="/automated-investing"
              className="waves-effect waves-light"> Automated Investing</NavLinkWithRole>
          </li>
          {company.fees_advisor_calcs && (
            <li>
              <NavLinkWithRole authorize="billing-definitions" action="list" to="/billing-definitions"
                className="waves-effect waves-light">Billing Definitions</NavLinkWithRole>
            </li>
          )}
          {company.fees_advisor_calcs && (
            <li>
              <NavLinkWithRole authorize="billing-groups" action="list" to="/billing-groups"
                className="waves-effect waves-light">Billing Groups</NavLinkWithRole>
            </li>
          )}
          <li>
            <NavLinkWithRole authorize="cash-reserves" action="list" to="/cash-reserves"
              className="waves-effect waves-light"> Cash Reserves</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="models" action="list" to="/models"
              className="waves-effect waves-light">Models</NavLinkWithRole>
          </li>
          <li>
            <a role="menuitem" data-target="#ReportingGroupModal" data-toggle="modal"
              className="waves-effect waves-light" href="# ">Rep Group Creation</a>
          </li>
          <li>
            <NavLinkWithRole authorize="households" action="list" to="/security-restrictions"
              className="waves-effect waves-light"> Security Restrictions</NavLinkWithRole>
          </li>
        </ul>
      </LiWithRole>
      <li>
        <span className="utmenu-click"><i className="utmenu-arrow fa fa-angle-right" /></span>
        <span className="wsmenu-click" />
        <a className="waves-effect waves-light" href="# ">
          Service <span className="arrow_carrot-down" aria-hidden="true" />
        </a>
        <ul className="utmenu-submenu" style={displayNone}>
          <li>
            <a className="waves-effect waves-light" target="_blank" rel="noopener noreferrer" href={`https://${ZENDESK_SUBDOMAIN}.zendesk.com/hc/en-us/requests`}>Messages</a>
          </li>
          { company.custodial_forms && (
            <li role="presentation">
              <NavLinkWithRole authorize="custodial requests" action="list" to="/custodial-requests"
                className="waves-effect waves-light"> Custodial requests</NavLinkWithRole>
            </li>) }
          {/* <li>
            <a className="waves-effect waves-light" target="_blank" rel="noopener noreferrer" href={`https://${ZENDESK_SUBDOMAIN}.zendesk.com/hc/en-us/requests/new`}>New request</a>
          </li> */}
          <li role="presentation">
            <a href="https://assetdedication.zendesk.com/hc/en-us/categories/202587447-Educational-Materials" target="_blank" rel="noopener noreferrer" role="menuitem"
              className="waves-effect waves-light"> Educational materials</a>
          </li>
          <li role="presentation">
            <Link to="/contact-us" role="menuitem" className="waves-effect waves-light"> Contact list</Link>
          </li>
          <li>
            <NavLinkWithRole authorize="adv reports" action="list" to="/adv-reports"
              className="waves-effect waves-light">ADV Report</NavLinkWithRole>
          </li>
        </ul>
      </li>
      <Partners />
      <LiWithRole authorize="internal" action="visible" className="">
        <span className="utmenu-click"><i className="utmenu-arrow fa fa-angle-right" /></span>
        <span className="wsmenu-click" />
        <a className="waves-effect waves-light" href="# ">
          Data <span className="arrow_carrot-down" aria-hidden="true" />
        </a>
        <ul className="utmenu-submenu" style={displayNone}>
          <li>
            <Link to="/account-types" className="waves-effect waves-light">Account Types</Link>
          </li>
          <li>
            <Link to="/account/new-accounts" className="waves-effect waves-light">New Accounts</Link>
          </li>
          <li>
            <NavLinkWithRole authorize="companies" action="list" to="/companies"
              className="waves-effect waves-light">Companies</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="roles" action="list" to="/roles"
              className="waves-effect waves-light">Roles</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="service teams" action="list" to="/service-teams"
              className="waves-effect waves-light">Service Teams</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="users" action="list" to="/users"
              className="waves-effect waves-light">Users</NavLinkWithRole>
          </li>
        </ul>
      </LiWithRole>
      <LiWithRole authorize="internal" action="visible" className="">
        <span className="utmenu-click"><i className="utmenu-arrow fa fa-angle-right" /></span>
        <span className="wsmenu-click" />
        <a className="waves-effect waves-light" href="# ">
          Internal <span className="arrow_carrot-down" aria-hidden="true" />
        </a>
        <ul className="utmenu-submenu" style={displayNone}>
          <li>
            <NavLinkWithRole authorize="banners" action="list" to="/banners"
              className="waves-effect waves-light">Banners</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="billing fee files" action="list" to="/billing-calculations"
              className="waves-effect waves-light">Billing calculations</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="banners" action="list" to="/educational-materials"
              className="waves-effect waves-light">Educational Materials</NavLinkWithRole>
          </li>
          <li>
            <Link to="/leads" className="waves-effect waves-light">Leads</Link>
          </li>
          {/* <li>
            <NavLinkWithRole authorize="custodial forms" action="list" to="/form-manager"
              className="waves-effect waves-light">Form Manager</NavLinkWithRole>
          </li> */}
          <li>
            <NavLinkWithRole authorize="tamarac uploads" action="list" to="/tamarac-uploads"
              className="waves-effect waves-light">Tamarac Uploads</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="tamarac imports" action="list" to="/tamarac-imports"
              className="waves-effect waves-light">Tamarac Imports</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="tamarac imports" action="unified reports" to="/unified-reports"
              className="waves-effect waves-light">Unified Reports</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="watchers" action="list" to="/watchers"
              className="waves-effect waves-light">Watchers</NavLinkWithRole>
          </li>
          <li>
            <NavLinkWithRole authorize="workers" action="new" to="/worker/new"
              className="waves-effect waves-light">Worker</NavLinkWithRole>
          </li>
        </ul>
      </LiWithRole>
    </ul>
  )
}
