export const SET_STATUS = 'SET_STATUS'

export function setStatus (panel, form, status) {
  return {
    type: SET_STATUS,
    panel,
    form,
    status
  }
}
