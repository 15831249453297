import config from '../config';
import HttpClient from '../helpers/httpclient';

const path = 'reporting-groups';

export async function fetchReportingGroupOptions (household) {
  const r = await HttpClient.get(`${config.api}/${path}`, { params: { household } });
  return r.data;
}

export async function fetchReportingGroupAccounts (id) {
  const r = await HttpClient.get(`${config.api}/${path}/${id}/accounts`);
  return r.data;
}
