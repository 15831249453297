import config from '../config'
import HttpClient from '../helpers/httpclient'
// import { getActions, getTypes, getType } from '../helpers/crudActions'
import { getType } from '../helpers/crudActions'

const OBJECT_GENERATE_TOKEN = getType('OBJECT_GENERATE_TOKEN', 'SHARED_OBJECTS')
export const OBJECT_GENERATE_TOKEN_PENDING = getType('OBJECT_GENERATE_TOKEN_PENDING', 'SHARED_OBJECTS')
export const OBJECT_GENERATE_TOKEN_REJECTED = getType('OBJECT_GENERATE_TOKEN_REJECTED', 'SHARED_OBJECTS')
export const OBJECT_GENERATE_TOKEN_FULFILLED = getType('OBJECT_GENERATE_TOKEN_FULFILLED', 'SHARED_OBJECTS')

export default {
  OBJECT_GENERATE_TOKEN,
  OBJECT_GENERATE_TOKEN_PENDING,
  OBJECT_GENERATE_TOKEN_REJECTED,
  OBJECT_GENERATE_TOKEN_FULFILLED,
  generateToken (id, type, user) {
    return {
      type: OBJECT_GENERATE_TOKEN,
      payload: HttpClient.post(`${config.api}/shared-objects/generate`, { id, type, user })
    }
  }
}
