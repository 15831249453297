import browserHistory from '../helpers/browserHistory'
import config from '../config'
import HttpClient from '../helpers/httpclient'
import { getActions, getTypes } from '../helpers/crudActions'
import { getParameterByName } from '../helpers/util'

export function getCompanies (id, q, inactive) {
  const params = { params: {} }
  if (id) {
    params.params.user_id = id
  }
  if (q) {
    params.params.q = q
  }
  if (inactive) {
    return HttpClient.get(`${config.api}/companies/all`, params)
  }
  return HttpClient.get(`${config.api}/companies/all?status=active`, params)
}

export function fetchCompaniesOptions (id, inactive) {
  const getOption = c => ({ value: c.id, label: c.name, company: c })
  const getOptions = companies => ({ options: companies.objects.map(getOption) })
  return input => {
    return getCompanies(id, input, inactive).then(r => r.data).then(getOptions)
  }
}

export function WithFeesAdvisorCalcs (q) {
  const params = { params: {} }
  if (q) {
    params.params.q = q
  }
  return HttpClient.get(`${config.api}/companies/with-fees-advisor-calcs`, params)
}

export function fetchCompaniesWithFeesAdvisorCalcsOptions () {
  const getOption = c => ({ value: c.id, label: c.name, company: c })
  const getOptions = companies => ({ options: companies.objects.map(getOption) })
  return input => {
    return WithFeesAdvisorCalcs(input).then(r => r.data).then(getOptions)
  }
}

export default {
  ...getActions('COMPANIES', 'companies'),
  ...getTypes('COMPANIES'),
  save (object, redirect = true) {
    return this.saveWithUpload(object, [ 'logo', 'advisor_consulting_agreement_pdf' ], redirect)
  },
  redirect (response) {
    if (getParameterByName('return') === 'user') {
      const userId = getParameterByName('id')
      if (userId) {
        browserHistory.push(`/user/${userId}/edit?company=${response.data.id}`)
      } else {
        browserHistory.push(`/user/new?company=${response.data.id}`)
      }
    } else {
      const path = `/company/${response.data.id}/edit`
      if (window.location.pathname !== path) {
        browserHistory.push(path)
      }
    }
    return response
  }
}

export function fetchUsers (id) {
  return HttpClient.get(`${config.api}/companies/${id}/users`).then(r => r.data)
}

export function fetchDefaultTemplate (id) {
  return HttpClient.get(`${config.api}/companies/${id}/default-template`).then(r => r.data)
}
