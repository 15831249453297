import config from '../config'
import HttpClient from '../helpers/httpclient'
import { getActions, getTypes } from '../helpers/crudActions'

export function getSecurityRestrictions (household, q) {
  const params = { params: { household, q } }
  return HttpClient.get(`${config.api}/security-restrictions`, params)
}

export function fetchSecurityRestrictionOptions (household) {
  const getOption = sr => ({ value: sr.id, label: sr.type })
  const getOptions = srs => ({ options: srs.objects.map(getOption) })
  return input => getSecurityRestrictions(household, input).then(r => r.data).then(getOptions)
}

export default {
  ...getActions('SECURITY_RESTRICTIONS', 'security-restrictions'),
  ...getTypes('SECURITY_RESTRICTIONS'),
  redirect (response) {
    return response
  }
}

export const SECURITIES_AFTER_SAVE = 'SECURITIES_AFTER_SAVE'
export const SECURITIES_REMOVE_AFTER_SAVE = 'SECURITIES_REMOVE_AFTER_SAVE'

export function afterSave (callback) {
  return { type: SECURITIES_AFTER_SAVE, callback }
}

export function removeAfterSave () {
  return { type: SECURITIES_REMOVE_AFTER_SAVE }
}

export async function fetchNotes (id) {
  const r = await HttpClient.get(`${config.api}/security-restrictions/${id}/notes`)
  return r.data
}

export async function fetchExists (account, security) {
  const params = { params: { account, security } }
  const r = await HttpClient.get(`${config.api}/security-restrictions/exists`, params)
  return r.data
}
