import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { fetchUserInfo } from '../../actions/users'
import { doImpersonate, getUser } from '../../actions/auth'
import { getParameterByName } from '../../helpers/util'

class ImpersonateAlertModal extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired
  }

  state = {
    user: null
  }

  componentDidMount () {
    this.checkImpersonate()
  }

  componentWillReceiveProps (props) {
    this.checkImpersonate()
  }

  canImpersonate (user) {
    if (user) {
      const permissions = user.Role.permissions || {}
      return permissions.users && permissions.users.impersonate
    }
    return false
  }

  checkImpersonate () {
    const user = getUser()
    if (!this.canImpersonate(user)) {
      return
    }
    const impersonate = getParameterByName('impersonate')
    if (impersonate !== null && impersonate !== '' && !this.state.user && user.id !== Number(impersonate)) {
      fetchUserInfo(impersonate).then(user => {
        this.setState({ user }, () => {
          window.$('#ImpersonateAlertModal').modal('show')
        })
      })
    }
  }

  impersonate = () => {
    this.props.dispatch(doImpersonate(this.state.user, this.props.location.pathname))
    this.setState({ user: null })
  }

  render () {
    const user = this.state.user || {}
    return (
      <div id="ImpersonateAlertModal" className="modal fade modal-fade-in-scale-up" aria-hidden="true" role="dialog">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
              <h4 className="modal-title">Impersonate {user.first_name} {user.last_name}</h4>
            </div>
            <div className="modal-body">
              <h5>We noticed that you may want to impersonate {user.first_name} {user.last_name} to continue your actions.</h5>
              <button onClick={this.impersonate}
                className="btn btn-round btn-info waves-effect waves-light">Impersonate</button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect()(ImpersonateAlertModal)
