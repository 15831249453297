import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formatNumber } from '../../helpers/util'

export default class Currency extends Component {
  static propTypes = {
    value: PropTypes.any,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    allowBlank: PropTypes.bool,
    allowNegative: PropTypes.bool
  }

  static defaultProps = {
    value: '',
    allowBlank: false,
    allowNegative: false
  }

  constructor (props) {
    super(props)
    const value = getValue(props.value)
    this.state = {
      value,
      pasted: false,
      focused: false
    }
  }

  componentWillReceiveProps (props) {
    const number = Number(removeFormat(`${props.value}`, props.allowNegative))
    if (this.state.value !== number && !this.state.focused) {
      const value = props.value === '' && this.props.allowBlank ? '' : formatNumber(number)
      this.setState({ value })
    }
  }

  onFocus = e => {
    e.target.value = removeFormat(e.target.value, this.props.allowNegative)
    this.setState({ value: e.target.value, focused: true })
    this.props.onChange && this.props.onChange(e)
  }

  onBlur = e => {
    const value = removeFormat(e.target.value, this.props.allowNegative)
    const number = Number(value)
    e.target.value = e.target.value === '' && this.props.allowBlank ? '' : formatNumber(number)
    this.setState({ value: e.target.value, pasted: false, focused: false })
    this.props.onChange && this.props.onChange(e)
    this.props.onBlur && this.props.onBlur(e)
  }

  onChange = e => {
    if (this.state.pasted) {
      this.setState({ pasted: false })
    }
    this.setState({ value: e.target.value })
    this.props.onChange && this.props.onChange(e)
  }

  onPaste = e => {
    const fmt = str => formatNumber(removeFormat(str, this.props.allowNegative))
    if (this.props.onPaste) {
      this.setState({ focused: false })
      return this.props.onPaste(e, fmt)
    }
    e.clipboardData.items[0].getAsString(str => {
      this.setState({ value: fmt(str), pasted: true })
    })
  }

  onKeyPress = e => {
    const which = e.which
    if (which > 57) {
      e.preventDefault()
      e.stopPropagation()
    }
  }

  focusInput = e => {
    this.input.focus()
  }

  render () {
    const ref = r => { this.input = r }
    const { value: val, focused } = this.state
    const { value, onChange, allowBlank, allowNegative, ...props } = this.props
    return (
      <div className="input-group">
        <span
          onClick={this.focusInput}
          className={`input-group-addon dollar-sign ${focused ? 'focused' : ''}`}>
          $
        </span>
        <input
          {...props}
          ref={ref}
          value={val}
          onFocus={this.onFocus}
          onChange={this.onChange}
          onKeyPress={this.onKeyPress}
          onBlur={this.onBlur}
          onPaste={this.onPaste} />
      </div>
    )
  }
}

function getValue (value) {
  return !value || value === '' ? '' : formatNumber(value)
}

function removeFormat (value, allowNegative) {
  if (!value) {
    return ''
  }
  const regex = allowNegative ? /[^0-9.\n-]+/g : /[^0-9.\n]+/g
  return value.replace(regex, '').replace(/\./, '#').replace(/\./g, '').replace(/#/, '.')
}
