import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import {
  OBJECT_GENERATE_TOKEN_FULFILLED,
  OBJECT_GENERATE_TOKEN_PENDING,
  OBJECT_GENERATE_TOKEN_REJECTED
} from '../actions/sharedObjects'

const initialState = getInitialState('SHARED_OBJECTS')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('SHARED_OBJECTS'),
  [OBJECT_GENERATE_TOKEN_PENDING]: (state, action) => {
    return { ...state, readyState: OBJECT_GENERATE_TOKEN_PENDING }
  },
  [OBJECT_GENERATE_TOKEN_FULFILLED]: (state, action) => {
    return { ...state, readyState: OBJECT_GENERATE_TOKEN_FULFILLED, object: action.payload.data }
  },
  [OBJECT_GENERATE_TOKEN_REJECTED] : (state, action) => {
    return { ...state, readyState: OBJECT_GENERATE_TOKEN_REJECTED, error: action.error }
  }
}

export default function sharedObjects (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
