import config from '../config'
import { getActions, getTypes, getType } from '../helpers/crudActions'
import HttpClient from '../helpers/httpclient'

const TOGGLE_HAVERSTING_LOSS = getType('TOGGLE_HAVERSTING_LOSS', 'SHARED_OBJECTS')
export const TOGGLE_HAVERSTING_LOSS_PENDING = getType('TOGGLE_HAVERSTING_LOSS_PENDING', 'SHARED_OBJECTS')
export const TOGGLE_HAVERSTING_LOSS_REJECTED = getType('TOGGLE_HAVERSTING_LOSS_REJECTED', 'SHARED_OBJECTS')
export const TOGGLE_HAVERSTING_LOSS_FULFILLED = getType('TOGGLE_HAVERSTING_LOSS_FULFILLED', 'SHARED_OBJECTS')
const TOGGLE_AUTO_MMF = getType('TOGGLE_AUTO_MMF', 'ACCOUNT')
export const TOGGLE_AUTO_MMF_PENDING = getType('TOGGLE_AUTO_MMF_PENDING', 'ACCOUNT')
export const TOGGLE_AUTO_MMF_REJECTED = getType('TOGGLE_AUTO_MMF_REJECTED', 'ACCOUNT')
export const TOGGLE_AUTO_MMF_FULFILLED = getType('TOGGLE_AUTO_MMF_FULFILLED', 'ACCOUNT')

export default {
  ...getActions('ACCOUNT_ARS', 'account-ars'),
  ...getTypes('ACCOUNT_ARS'),
  listImplement (page = 1, status = 'active', term = null, sort = null, proposalVersion) {
    return this.listWith(page, status, term, sort, { proposalVersion, implement: true })
  },
  updateHarvestingLoss (id, value, updateList = false) {
    return {
      type: TOGGLE_HAVERSTING_LOSS,
      payload: HttpClient.put(`${config.api}/account-ars/${id}/toggle-harvesting-loss`, { tax_loss: value }),
      meta: { updateList }
    }
  },
  toggleAutoMmf (id, updateList = false) {
    return {
      type: TOGGLE_AUTO_MMF,
      payload: HttpClient.put(`${config.api}/account-ars/${id}/toggle-auto-mmf`),
      meta: { updateList }
    }
  }
}

export function fetchHarvestableLosses (id) {
  return HttpClient.get(`${config.api}/account-ars/${id}/harvestable-losses`).then(r => r.data)
}

export function fetchSocialSustainable (id) {
  return HttpClient.get(`${config.api}/account-ars/${id}/social-sustainable`).then(r => r.data)
}
