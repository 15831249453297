import browserHistory from '../helpers/browserHistory'
import config from '../config'
import HttpClient from '../helpers/httpclient'
import { getActions, getTypes, getType, OBJECT_FETCH, OBJECT_SAVE } from '../helpers/crudActions'

const OBJECT_SEND_ACTIVATION_EMAIL = getType('OBJECT_SEND_ACTIVATION_EMAIL', 'USERS')
const OBJECT_SEND_ACTIVATION_EMAIL_PENDING = getType('OBJECT_SEND_ACTIVATION_EMAIL_PENDING', 'USERS')
const OBJECT_SEND_ACTIVATION_EMAIL_REJECTED = getType('OBJECT_SEND_ACTIVATION_EMAIL_REJECTED', 'USERS')
const OBJECT_SEND_ACTIVATION_EMAIL_FULFILLED = getType('OBJECT_SEND_ACTIVATION_EMAIL_FULFILLED', 'USERS')

const OBJECT_SAVE_ADVISOR = getType('OBJECT_SAVE_ADVISOR', 'USERS')
const OBJECT_SAVE_ADVISOR_PENDING = getType('OBJECT_SAVE_ADVISOR_PENDING', 'USERS')
const OBJECT_SAVE_ADVISOR_REJECTED = getType('OBJECT_SAVE_ADVISOR_REJECTED', 'USERS')
const OBJECT_SAVE_ADVISOR_FULFILLED = getType('OBJECT_SAVE_ADVISOR_FULFILLED', 'USERS')

const OBJECT_SAVE_SIGNATURE = getType('OBJECT_SAVE_SIGNATURE', 'USERS')
const OBJECT_SAVE_SIGNATURE_PENDING = getType('OBJECT_SAVE_SIGNATURE_PENDING', 'USERS')
const OBJECT_SAVE_SIGNATURE_REJECTED = getType('OBJECT_SAVE_SIGNATURE_REJECTED', 'USERS')
const OBJECT_SAVE_SIGNATURE_FULFILLED = getType('OBJECT_SAVE_SIGNATURE_FULFILLED', 'USERS')

const DASHBOARD_COMPLETED = getType('DASHBOARD_COMPLETED', 'USERS')
const DASHBOARD_COMPLETED_PENDING = getType('DASHBOARD_COMPLETED_PENDING', 'USERS')
const DASHBOARD_COMPLETED_REJECTED = getType('DASHBOARD_COMPLETED_REJECTED', 'USERS')
const DASHBOARD_COMPLETED_FULFILLED = getType('DASHBOARD_COMPLETED_FULFILLED', 'USERS')

export function getUsers (id, q) {
  const params = { params: { include: id, q } }
  return HttpClient.get(`${config.api}/users`, params)
}

export function fetchUserOptions (id) {
  const getOption = user => ({ value: user.id, label: `${user.first_name} ${user.last_name}` })
  const getOptions = users => ({ options: users.objects.map(getOption) })
  return input => getUsers(id, input).then(r => r.data).then(getOptions)
}

export function fetchImpersonateOptions (input) {
  const getOption = user => ({ value: user.id, label: `${user.last_name}, ${user.first_name}` })
  const getOptions = users => ({ options: users.objects.map(getOption) })
  return HttpClient.get(`${config.api}/users/options`, { params: { q: input } }).then(r => getOptions(r.data))
}

export function fetchAccountsToImplement (id) {
  return HttpClient.get(`${config.api}/users/${id}/accounts-to-implement`).then(r => r.data)
}

export function fetchPortfolioAlerts (id) {
  return HttpClient.get(`${config.api}/users/${id}/portfolio-alerts`).then(r => r.data)
}

export function fetchBillingSetupRequired (id) {
  return HttpClient.get(`${config.api}/users/${id}/billing-setup-required`).then(r => r.data)
}

export function fetchOpenRequests (id) {
  return HttpClient.get(`${config.api}/users/${id}/open-requests`).then(r => r.data)
}

export function fetchUserInfo (id) {
  return HttpClient.get(`${config.api}/users/${id}/info`).then(r => r.data)
}

export function fetchProspectInformation (id) {
  return HttpClient.get(`${config.api}/users/${id}/prospect-information`).then(r => r.data)
}

export default {
  ...getActions('USERS', 'users'),
  ...getTypes('USERS'),
  OBJECT_SEND_ACTIVATION_EMAIL,
  OBJECT_SEND_ACTIVATION_EMAIL_PENDING,
  OBJECT_SEND_ACTIVATION_EMAIL_REJECTED,
  OBJECT_SEND_ACTIVATION_EMAIL_FULFILLED,
  OBJECT_SAVE_ADVISOR,
  OBJECT_SAVE_ADVISOR_PENDING,
  OBJECT_SAVE_ADVISOR_REJECTED,
  OBJECT_SAVE_ADVISOR_FULFILLED,
  OBJECT_SAVE_SIGNATURE,
  OBJECT_SAVE_SIGNATURE_PENDING,
  OBJECT_SAVE_SIGNATURE_REJECTED,
  OBJECT_SAVE_SIGNATURE_FULFILLED,
  DASHBOARD_COMPLETED,
  DASHBOARD_COMPLETED_PENDING,
  DASHBOARD_COMPLETED_REJECTED,
  DASHBOARD_COMPLETED_FULFILLED,
  redirect (response) {
    const path = response.data.redirect_path || `/user/${response.data.id}/edit`
    if (window.location.pathname !== path) {
      browserHistory.push(path)
    }
    return response
  },
  save (object, redirect = true) {
    const method = object.id ? 'put' : 'post'
    return {
      type: getType(OBJECT_SAVE, 'USERS'),
      payload: HttpClient[method](`${config.api}/users/${object.id || ''}`, object).then(response => {
        if (redirect) {
          return this.redirect(response)
        }
        return response
      })
    }
  },
  sendActivationEmail (user) {
    const url = `${config.api}/users/${user.id}/activation-email`
    return {
      type: OBJECT_SEND_ACTIVATION_EMAIL,
      payload: HttpClient.post(url)
    }
  },
  public (id) {
    return {
      type: getType(OBJECT_FETCH, 'USERS'),
      payload: HttpClient.get(`${config.api}/users/${id}/public`)
    }
  },
  saveAdvisor (object, callback) {
    return {
      type: OBJECT_SAVE_ADVISOR,
      payload: HttpClient.post(`${config.api}/service-teams`, object).then(response => {
        window.$('[data-dismiss="modal"]').click()
        callback && callback(response.data)
        return response
      })
    }
  },
  saveSignature (id, signature) {
    return {
      type: OBJECT_SAVE_SIGNATURE,
      payload: HttpClient.put(`${config.api}/users/${id}/signature`, { signature })
    }
  },
  sendDashboardCompleted (user) {
    const url = `${config.api}/users/${user.id}/dashboard-completed`
    return {
      type: DASHBOARD_COMPLETED,
      payload: HttpClient.post(url)
    }
  }
}
