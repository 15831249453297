import { getActionHandler, getInitialState } from '../helpers/crudReducers'
import {
  INCOME_BUILDERS_UPDATE_CASHFLOW_PENDING,
  INCOME_BUILDERS_UPDATE_CASHFLOW_REJECTED,
  INCOME_BUILDERS_UPDATE_CASHFLOW_FULFILLED
} from '../actions/incomeBuilders'

const initialState = getInitialState('INCOME_BUILDERS')
const CRUD_ACTION_HANDLERS = {
  ...getActionHandler('INCOME_BUILDERS'),
  [INCOME_BUILDERS_UPDATE_CASHFLOW_PENDING]: (state, action) => {
    return { ...state, readyState: INCOME_BUILDERS_UPDATE_CASHFLOW_PENDING }
  },
  [INCOME_BUILDERS_UPDATE_CASHFLOW_REJECTED]: (state, action) => {
    const message = action.payload.response.status === 422 ? action.payload.response.data : null
    return { ...state, readyState: INCOME_BUILDERS_UPDATE_CASHFLOW_REJECTED, error: action.error, message }
  },
  [INCOME_BUILDERS_UPDATE_CASHFLOW_FULFILLED]: (state, action) => {
    return { ...state, readyState: INCOME_BUILDERS_UPDATE_CASHFLOW_FULFILLED, object: action.payload.data }
  }
}

export default function incomeBuilder (state = initialState, action) {
  const handler = CRUD_ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
