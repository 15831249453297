import React from 'react'
// import { Link } from 'react-router-dom'

const Footer = ({ show }) => {
  if (!show) {
    return null
  }

  return (
    <footer className="main-footer">
      <div className="footer-copyright">
        <p>AdvisorSpace by <a href="https://www.assetdedication.com/" target="_blank" rel="noopener noreferrer">Asset Dedication, LLC</a></p>
      </div>
      <div className="footer-right-text">
        All Rights Reserved

      </div>
      <p><br /></p>
      <p>
        This site is intended for investment adviser and investment professional use only. The data used in this site was obtained from third-party information providers, and is believed to be reliable; but its accuracy or completeness is not guaranteed. For complete disclosures and our privacy policy, <a href="https://www.assetdedication.com/disclosure-privacy-policy/" target="_blank" rel="noopener noreferrer">click here.</a>
      </p>
    </footer>
  )
}

export default Footer
