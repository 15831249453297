import {
  MODELS_INVALID,
  MODELS_FETCHING,
  MODELS_FETCHED,
  MODELS_FETCH_FAILED,
  MODEL_SAVING,
  SAVE_MODEL_SUCCESS,
  SAVE_MODEL_FAILED,
  MODEL_FETCHING,
  MODEL_FETCH_FAILED,
  MODEL_FETCHED,
  MODEL_TOGGLE_STATUS_SUCCESS
} from '../actions/models'

const initialState = {
  readyState: MODELS_INVALID,
  list: {
    models: [],
    pageSize: 50,
    currentPage: 1,
    count: 0
  },
  object: {
    ModelSecurities: []
  }
}

const ACTION_HANDLERS = {
  [MODELS_FETCHING] : (state, action) => {
    return { ...state, readyState: MODELS_FETCHING }
  },
  [MODELS_FETCH_FAILED] : (state, action) => {
    return { ...state, readyState: MODELS_FETCH_FAILED, error: action.error }
  },
  [MODELS_FETCHED] : (state, action) => {
    return { ...state, readyState: MODELS_FETCHED, list: action.result }
  },
  [MODEL_SAVING]: (state, action) => {
    return { ...state, readyState: MODEL_SAVING }
  },
  [SAVE_MODEL_SUCCESS]: (state, action) => {
    return { ...state, readyState: SAVE_MODEL_SUCCESS, info: action.result }
  },
  [SAVE_MODEL_FAILED]: (state, action) => {
    return { ...state, readyState: SAVE_MODEL_FAILED, error: action.error }
  },
  [MODEL_FETCHING]: (state, action) => {
    return { ...state, readyState: MODEL_FETCHING }
  },
  [MODEL_FETCH_FAILED]: (state, action) => {
    return { ...state, readyState: MODEL_FETCH_FAILED, error: action.error }
  },
  [MODEL_FETCHED]: (state, action) => {
    action.result.ModelSecurities.forEach(ms => {
      ms.weight *= 100
      if (ms.Security) {
        ms.Security.expense_ratio *= 100
      }
    })
    return { ...state, readyState: MODEL_FETCHED, object: action.result }
  },
  [MODEL_TOGGLE_STATUS_SUCCESS]: (state, action) => {
    const models = state.list.models.map((model) => {
      if (model.id === action.result.id) {
        model = action.result
      }
      return model
    })
    return { ...state, readyState: MODEL_TOGGLE_STATUS_SUCCESS, list: { ...state.list, models } }
  }
}

export default function models (state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
