import React, { useEffect } from 'react'
import useModal from '../../../hooks/useModal'

export default function Modal () {
  const { title, body, visible, backdrop, large, hideModal, timestamp } = useModal()
  useEffect(() => {
    const modal = window.$('#Modal')
    modal.modal(visible ? 'show' : 'hide')
    modal.data('bs.modal').options.backdrop = backdrop
    window.$('#Modal').on('hidden.bs.modal', () => hideModal())
    return () => window.$('#Modal').off('hidden.bs.modal')
  }, [ visible, backdrop, hideModal ])
  return (
    <div id="Modal" className="modal fade modal-fade-in-scale-up" aria-hidden="true" role="dialog">
      <div className={`modal-dialog ${large ? 'modal-lg' : ''}`}>
        <div className="modal-content">
          <div className="modal-header">
            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
            <h4 className="modal-title">{title}</h4>
          </div>
          <div className="modal-body" key={timestamp}>
            {body}
          </div>
        </div>
      </div>
    </div>
  )
}
